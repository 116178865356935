import { makeSlug } from "../helpers/appHelper";

export default {
  computed: {
    getStepEntityRelationshipUrl() {
      return (reationship) => {
        let slug = reationship.relationship_slug;
        let id = reationship.relationship_id;

        return `/entityData-Edit/${
          this.$route.params.entity_id
        }/${slug}?dataId=${this.entityDataId || ""}&relationship_id=${id}`;
      };
    },
  },
  methods: {
    prepareRelationshipData(
      fieldsData,
      currentEntityRelationships,
      entityDataId,
      loginUserid = null,
      formData = null
    ) {
      let form = formData ? formData : this.form;
      let relationshipsForFields = currentEntityRelationships.filter(
        (e) => e.owner_type == "PARENT" && e.representation == "FIELD"
      );
      let fieldsHasRelationships = fieldsData.filter((field) => {
        let relationship = relationshipsForFields.find(
          (x) => x.field_id == field._id && x.child_entity_id == field.entity_id
        );

        if (relationship) {
          field.relationshipDetails = { ...relationship };
          return true;
        } else {
          return false;
        }
      });

      if (fieldsHasRelationships.length) {
        return fieldsHasRelationships.map((field) => {
          let relatinshipValue = form[field.key];
          if(loginUserid){
            relatinshipValue = loginUserid
          }
          let previousValue = null;
          if (this.oldForm) {
            previousValue = this.oldForm[field.key];
          }

          let child_entity_data_ids = [];
          let type = "NEW";

          if (
            previousValue &&
            previousValue.length &&
            relatinshipValue &&
            relatinshipValue.length
          ) {
            type = "REPLACE";
            if (typeof relatinshipValue !== "object") {
              relatinshipValue = [relatinshipValue];
            }
            child_entity_data_ids = [...relatinshipValue];
          } else if (
            (!previousValue || !previousValue.length) &&
            relatinshipValue &&
            relatinshipValue.length
          ) {
            if (typeof relatinshipValue !== "object") {
              relatinshipValue = [relatinshipValue];
            }
            child_entity_data_ids = [...relatinshipValue];
            type = "NEW";
          } else if (
            (!relatinshipValue || !relatinshipValue.length) &&
            previousValue &&
            previousValue.length
          ) {
            type = "REMOVE";
            child_entity_data_ids = [];
          }

          return {
            parent_entity_id: field.relationshipDetails.parent_entity_id,
            child_entity_id: field.relationshipDetails.child_entity_id,
            parent_entity_data_id: entityDataId,
            child_entity_data_ids,
            type,
          };
        });
      } else {
        return [];
      }
    },

    async fetchEntityRelationships(
      entity,
      fromDocuments = false,
      userFields = false,
      parentKey = "",
      selectedEntity,
      value
    ) {
      let entityId = entity && entity._id ? entity._id : entity;
      this.loading = true;
      this.loadingText = "Fetching Entity relationships..";
      await this.$store.dispatch(
        "entityRelationships/fetchEntityRelationships",
        { entity_id: entityId }
      );
      if (this.getEntityRelationships && this.getEntityRelationships.length) {
        this.relationshipsData = [...(this.getEntityRelationships || [])];
        // we have to add type owner of relationship
        this.relationshipsData = this.relationshipsData.map((e) => {
          if (e.parent_entity_id == entityId) {
            e.owner_type = "PARENT";
            e.relationship_title = e.child_relation_title;
            e.relationship_slug = makeSlug(e.relationship_title);
            e.relationship_id = e.child_entity_id;
          } else {
            e.owner_type = "CHILD";
            e.relationship_title = e.parent_relation_title;
            e.relationship_slug = makeSlug(e.relationship_title);
            e.relationship_id = e.parent_entity_id;
          }
          e.parent_entity_data_id = this.entityDataId;
          return e;
        });

        this.relationshipsData = this.relationshipsData.filter((e) => {
          if (e.owner_type == "CHILD" && !e.is_bidirectional) {
            return false;
          } else {
            return true;
          }
        });
        this.$store.dispatch(
          "entityRelationships/updateCurrentEntityRelationships",
          this.relationshipsData
        );

        this.relationshipsData = this.relationshipsData.filter(
          (e) => e.owner_type == "CHILD" || e.representation == "TAB"
        );
        if (fromDocuments && this.getEntityRelationships.length) {
          this.relationshipsData.forEach((e) => {
            let field = {
              action_button_repeating_template_relationships: [],
              label: selectedEntity.name + "->" + e.relationship_title,
              name: selectedEntity.name + "->" + e.relationship_title,
              key: e.relationship_id + "~" + selectedEntity.slug,
              template_id: e.relationship_id + "~" + selectedEntity.slug,
              input_type: "ENTITY",
              inputType: "ENTITY",
              is_relational: true,
              content: "",
              selected_fields: [],
              formula: "",
              function_type: "",
              validations: {
                required: false,
                type: "OBJECT_ID",
                data_type: "OBJECT_ID",
              },
              defaultCountry: "US",
              countryCodeSelector: true,
              countryDisable: false,
              styles: {
                font: {
                  name: "Helvetica",
                  size: 16,
                  style: 0,
                  color: "#000000",
                },
              },
              properties: {
                hideLabel: false,
                prefix: false,
                _id: "634d6ed8e51da443f3a2b7f9",
                is_unique: false,
                personal_data: false,
                personal_data_type: "",
                is_encrypted: false,
              },
              decimals: null,
              date_result_type: "",
              height: 90,
              width: 200,
              filled_by: "SENDER",
              type: "FIELD",
              x: 235,
              y: 833.8046875,
              is_global_variable: false,
              relation_entity_id: e.relationship_id,
              self_entity_id: selectedEntity._id,
              filters: [
                {
                  field: userFields
                    ? `user#related_to/${
                        this.selectedItem.selected_user
                          ? this.selectedItem.selected_user
                          : 1
                      }`
                    : "self#related_to/1",
                  operator: "=",
                  value: value ?  value : "",
                  query_type: "AND",
                  data_type: "RELATION",
                  data_source: userFields ? "user_entity" : "self_field",
                  value_field: parentKey ? parentKey : selectedEntity.slug,
                  relation_entity_id: selectedEntity._id,
                  hide_filter: userFields ? false : true,
                },
              ],
              entity_id: selectedEntity._id,
              visibility: true,
              _id: e.relationship_id + "~" + selectedEntity.slug,
            };
            if (userFields && this.fieldTemplates) {
              this.fieldTemplates.push(field);
            } else if (this.allEntityFormTemplatesList) {
              this.allEntityFormTemplatesList.push(field);
            }
          });
        }
      }
      this.loading = false;
    },
  },
};
