<template>
  <section>
    <el-dialog
      :visible.sync="show"
      title="Upload Document"
      width="550px"
      v-on:close="closePopup"
    >
      <el-form
        :model="documentData"
        status-icon
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
        v-loading="loading"
      >
        <el-form-item class="mb-1" label="Document Title">
          <el-input
            v-model="documentData.title"
            placeholder="Please Document Name"
            readonly
          ></el-input>
          <span
            class="error"
            v-if="
              getRequestedDocumentAddingErrors &&
              getRequestedDocumentAddingErrors.title
            "
            >{{ getRequestedDocumentAddingErrors.title }}</span
          >
          <span
            class="error"
            v-if="
              getRequestedDocumentAddingErrors &&
              getRequestedDocumentAddingErrors.critical_error
            "
            >{{ getRequestedDocumentAddingErrors.critical_error }}</span
          >
        </el-form-item>
        <el-form-item class="mb-1" label="Issuing Authority">
          <el-input
            v-model="documentData.issuing_authority"
            placeholder="Please Enter Issuing Authority"
          ></el-input>
          <span
            class="error"
            v-if="
              getRequestedDocumentAddingErrors &&
              getRequestedDocumentAddingErrors.issuing_authority
            "
            >{{ getRequestedDocumentAddingErrors.issuing_authority }}</span
          >
        </el-form-item>
        <el-form-item class="mb-1" label="Document Number">
          <el-input
            v-model="documentData.document_number"
            placeholder="Please Enter Document Number"
          ></el-input>
          <span
            class="error"
            v-if="
              getRequestedDocumentAddingErrors &&
              getRequestedDocumentAddingErrors.document_number
            "
            >{{ getRequestedDocumentAddingErrors.document_number }}</span
          >
        </el-form-item>

        <el-form-item class="mb-1" label="Valid From">
          <el-date-picker
            type="date"
            placeholder="Pick a date"
            v-model="documentData.valid_from"
            style="width: 100%"
          ></el-date-picker>
          <span
            class="error"
            v-if="
              getRequestedDocumentAddingErrors &&
              getRequestedDocumentAddingErrors.valid_from
            "
            >{{ getRequestedDocumentAddingErrors.valid_from }}</span
          >
        </el-form-item>

        <el-form-item class="mb-1" label="Expiration Date">
          <el-date-picker
            type="date"
            placeholder="Pick a date"
            v-model="documentData.expires_at"
            style="width: 100%"
          ></el-date-picker>
          <span
            class="error"
            v-if="
              getRequestedDocumentAddingErrors &&
              getRequestedDocumentAddingErrors.expires_at
            "
            >{{ getRequestedDocumentAddingErrors.expires_at }}</span
          >
        </el-form-item>

        <el-form-item class="mb-1" label="Upload Document">
          <el-upload
            action
            :on-change="upload"
            class="avatar-uploader"
            :show-file-list="false"
            :auto-upload="false"
          >
            <i class="el-icon-upload2"></i>
          </el-upload>
          <div class="filename" v-if="selectedFile">
            <a class="el-upload-list__item-name">
              <i class="el-icon-document"></i>
              {{ selectedFile.name }}
            </a>
          </div>
          <span class="error" v-if="fileError">{{ fileError }}</span>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button type="primary" @click="saveDocument"
          >Save Document</el-button
        >
        <el-button @click="closePopup">Cancel</el-button>
      </template>
    </el-dialog>
  </section>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  name: "EmpDoc-UploadRequestedDocuments",
  props: ["visible", "documentData"],

  computed: {
    ...mapGetters("requestedDocuments", [
      "getRequestedDocumentAddingErrors",
      "getFileUploadURL",
      "getFileUploadRefId",
      "getDocumentUploadStatus",
      "getDocumentUploadStatusUpdated",
      "getUploadedFileData",
      "getRequestedDocumentAddStatus",
    ]),
  },
  data() {
    return {
      selectedFile: null,
      fileError: "",
      loading: false,
      show: true,
    };
  },
  mounted() {
    this.$store.commit(
      "requestedDocuments/setRequestedDocumentAddingErrors",
      {},
      { root: true }
    );
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
    upload(file) {
      this.selectedFile = file;
    },
    saveDocument() {
      this.fileError = "";
      if (!this.selectedFile) {
        this.fileError = "Please select a file to upload";
        return;
      }
      this.uploadFile();
    },

    async uploadFile() {
      this.loading = true;
      let file = this.selectedFile;
      await this.fetchFileUploadUrl(file);
      if (this.getFileUploadURL) {
        let uploaded = await this.uploadSelectedDocument(file.raw);
        this.loading = false;
        if (uploaded) {
          this.loading = true;
          this.documentData.path =
            this.getUploadedFileData.slug + "/" + this.getUploadedFileData.key;

          this.documentData.metadata = this.getUploadedFileData.meta_data;

          await this.addNewDocument();
          this.loading = false;
        } else {
          this.$message.error("Error while uploading document");
        }
      } else {
        this.$message.error("Error while uploading document");
      }
    },

    async addNewDocument() {
      let params = this.documentData;
      params.employee_document_id = this.documentData._id;
      await this.$store.dispatch(
        "requestedDocuments/uploadRequestedDocumentsData",
        params
      );

      if (this.getRequestedDocumentAddStatus) {
        this.$message.success("Document uplaoded sucesssfully");

        this.$emit("close", true);
      } else {
        console.log();
      }
    },

    async fetchFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000,
      };
      await this.$store.dispatch(
        "requestedDocuments/fetchDocumentUploadUrl",
        data
      );
    },
    async uploadSelectedDocument(file) {
      this.loading = true;
      this.loadingText = "Uploading Document...";
      // uploding to S3
      let params = {
        file: file,
        upload_url: this.getFileUploadURL,
      };

      await this.$store.dispatch(
        "requestedDocuments/uploadDocumentToGeneratedUrl",
        params
      );
      this.loading = false;

      if (this.getDocumentUploadStatus) {
        // UPDATEING STATUS
        let params = {
          file_ref_id: this.getFileUploadRefId,
        };
        await this.$store.dispatch(
          "requestedDocuments/updateDocumentUploadStatus",
          params
        );

        if (this.getDocumentUploadStatusUpdated) {
          return true;
        } else {
          this.$message("2 - Sorry ! Error while file uploading");
          return false;
        }
      } else {
        this.$message("1 -Sorry ! Error while file uploading");
        return false;
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("requestedDocuments/setRequestedDocumentAddingErrors",null,{root:true})
    this.$store.commit("requestedDocuments/setFileUploadURL",null,{root:true})
    this.$store.commit("requestedDocuments/setFileUploadRefId",null,{root:true})
    this.$store.commit("requestedDocuments/setDocumentUploadStatus",null,{root:true})
    this.$store.commit("requestedDocuments/setDocumentUploadStatusUpdated",null,{root:true})
    this.$store.commit("requestedDocuments/setUploadedFileData",null,{root:true})
    this.$store.commit("requestedDocuments/setRequestedDocumentAddStatus",null,{root:true})
  },
};
</script>

<style lang="scss" scoped>
.error {
  font-size: 14px;
  color: #df0e0e;
  margin-top: -10px !important;
}
.el-form-item {
  margin-bottom: 1.5em;
}
.avatar-uploader {
  border: 1px dashed #4a90e2;
  background-color: #f9f9f9;
}
</style>