export default {
    APP_VERSION: '1.0',
    APP_NAME: 'nimblehrms',
    S3_BUCKET_URL: process.env.VUE_APP_S3_BUCKET_URL,
    API_URL: process.env.VUE_APP_DEV_API_URL,
    APP_URL: process.env.VUE_APP_URL,
    JAVA_BASE_URL: process.env.VUE_APP_JAVA_BASE_URL,
    AUTHFAST_BASE_URL: process.env.VUE_APP_AUTH_FAST_BASE_URL,
    // AZURE_OAUTH_REDIRECT_URI: 'http://localhost:8080/contacts',
    // AZURE_APP_SECRET: 'Qzr8Q~REAp6K8U4uRaevztyWyl6NDoSGag9K9aPh',
    // AZURE_APP_ID: '243c55fd-70ba-4769-891e-dffc05ac1c3f',
    // AZURE_AUTHORITY: 'https://login.microsoftonline.com/686658ba-ae75-45fa-8ba9-7cefe64e294a/',
    AZURE_SCOPES:['user.read', 'contacts.read'],
    AZURE_OAUTH_REDIRECT_URI: 'https://nsui.esigns.io/contacts',
    AZURE_APP_SECRET: 'V_38Q~5dki7uKFZpDGKYngnqsxZpraW8ObOpnbi3',
    AZURE_APP_ID: '0a93a756-14fc-4a8a-986a-9357a8e0ea4a',
    AZURE_AUTHORITY: 'https://login.microsoftonline.com/686658ba-ae75-45fa-8ba9-7cefe64e294a/',
    PAYMENT_GATEWAY_URL: `${process.env.VUE_APP_PAYMENT_MICRO_SERVICE}/api`,
    // PAYMENT_GATEWAY_URL:"https://pay.esigns.io/api",
    PAYMENT_REDIRECTION_URL: process.env.VUE_APP_URL,
    SUBSCRIPTION_PGID:process.env.VUE_APP_SUBSCRIPTION_PGID,
    ESIGNS_PRODUCT_ID: process.env.VUE_APP_ESIGNS_PRODUCT_ID,
    WORKING_PAY_SYSTEMS : ["SWIREPAY","STRIPE"],
    // SUBSCRIPTION_PGID:"649c74d8818d071c2bd32651",
    // ESIGNS_PRODUCT_ID: "63bd50899f26da16fe4d8f7a"

    /* White label application Settings Start */
    
    APPLICATION_TYPE:process.env.VUE_APP_APPLICATION_TYPE,
    APPLICATION_NAME:process.env.VUE_APP_APPLICATION_NAME,
    APPLICATION_DESCRIPTION:process.env.VUE_APP_APPLICATION_DESCRIPTION,
    LOGO: process.env.VUE_APP_LOGO,
    CONTENT1:process.env.VUE_APP_CONTENT1,
    CONTENT2: process.env.VUE_APP_CONTENT2,
    FOOTER: process.env.VUE_APP_FOOTER,
    PRIMARY_COLOR: process.env.VUE_APP_PRIMARY_COLOR,
    SECONDARY_COLOR:process.env.VUE_APP_SECONDARY_COLOR,
    HEADING: process.env.VUE_APP_HEADING,
    SLOGAN: process.env.VUE_APP_SLOGAN,

    /* White label application Settings End */
}
