import companyDocumentsData from "@/constants/lg-en/companyDocuments";
import ConfigureDocumentsHelper from "@/mixins/ConfigureDocumentHelper";
import { mapGetters } from "vuex";
import { bus } from "../main";
var pdfjsLib = window["pdfjs-dist/build/pdf"];
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js";
//import moment from 'moment';
import * as mexp from "math-expression-evaluator";
export default {
  data() {
    return {
      fetchingCompanyDocumentDetails: false,
      configureCompanyDocumentsVisible: false,
      newlySelectedDocuments: {
        static: [],
        standard: [],
        custom: [],
        requested: [],
      },
      searchDocumentFormData: {
        type: "",
        group_id: "",
        category_id: "",
        searchedDocuments: [],
      },
      standardDocumentsMap: companyDocumentsData.STANDARD_DOCUMENTS_MAP,
      standardDocumentsList: companyDocumentsData.STANDARD_DOCUMENTS_LIST,

      documentViewComponentsMap:
        companyDocumentsData.EMP_DOC_VIEW_COMPONENT_MAP,
    };
  },
  mixins: [ConfigureDocumentsHelper],
  computed: {
    ...mapGetters("companyDocuments", ["getCompanyAllDocuments"]),
    ...mapGetters("templatesData", ["getTemplateDataByentityId"]),
    ...mapGetters("documentCategories", ["getAllCategories"]),
    ...mapGetters("documentGroups", ["getAllGroups"]),
    ...mapGetters("contactTypes", ["getAllContactTypes"]),
    ...mapGetters("company", ["getCompanyDetails"]),
    ...mapGetters("auth", ["getActiveWorkspace"]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("entities", [
      "getAllEntities",
      "getEntityDataByEntityId",
      "getEntityRecordsForTable",
    ]),
    getTotalDocuments() {
      return (documents) => {
        return (
          documents.static.length +
          documents.custom.length +
          documents.standard.length +
          documents.requested.length
        );
      };
    },
    checkForChildren() {
      return (this.elements || []).filter(
        (e) => e.parent_entity_field_key == this.selectedItem.key
      ).length
        ? true
        : false;
    },
    checkForChildrenWithActiveParent() {
      return (this.elements || []).filter(
        (e) =>
          this.elements[this.activatedItemIndex] &&
          e.parent_entity_field_key ==
            this.elements[this.activatedItemIndex].key
      ).length
        ? true
        : false;
    },
    checkForRepeatableChild() {
      return (this.elements || []).filter(
        (e) =>
          this.elements[this.activatedItemIndex] &&
          e.parent_repeatable_field_key ==
            this.elements[this.activatedItemIndex].key
      ).length
        ? true
        : false;
    },
    checkForRepeatableChildren() {
      return (this.elements || []).filter(
        (e) => e.parent_repeatable_field_key == this.selectedItem.key
      ).length
        ? true
        : false;
    },
    //standard contact type
    getStandardContactType() {
      if (this.getAllContactTypes?.length) {
        return this.getAllContactTypes.find(
          (e) => e.type == "STANDARD" && e.slug == "receiver"
        );
      }
      return "";
    },
  },
  methods: {
    async fetchAllDocuments() {
      await this.$store.commit(
        "documents/setDocumentFormFieldsData",
        {},
        {
          root: true,
        }
      );
      this.pageCount = 1;
      this.currentDoc = 0;
      let allDocs = this.files.length;
      while (this.currentDoc < allDocs) {
        let file = this.files[this.currentDoc];
        if (this.isMergeDocument) {
          await this.getAllPdfFieldsForSingleDocument(file);
        } else {
          await this.getAllPdfFields(file, this.currentDoc);
        }
      }
    },
    async getAllPdfFieldsForSingleDocument(file) {
      let i = 0;
      const self = this;
      // if (file.raw.type != "application/pdf") {
      //   this.currentDoc++;
      //   return
      // }
      let url = URL.createObjectURL(file.raw);
      await pdfjsLib.getDocument(url).then(setFieldsData);
      async function setFieldsData(pdfDoc) {
        for (let j = 1; j <= pdfDoc.numPages; j++) {
          await pdfDoc
            .getPage(j)
            .then(getPageInfo)
            .then(
              setTimeout(() => {
                if (j == pdfDoc.numPages) {
                  self.currentDoc++;
                }
              })
            );
        }
        return pdfDoc;
      }
      async function getPageInfo(page) {
        await page.getAnnotations().then((e) => {
          let obj = {};
          obj[self.pageCount] = {
            fields: e,
            docId: i,
            pageId: self.pageCount,
          };
          self.pdfFormFields[i] = {
            ...self.pdfFormFields[i],
            ...obj,
          };
          self.pageCount++;
        });
      }
    },
    async getAllPdfFields(file, id) {
      if (file.raw.type != "application/pdf") {
        this.currentDoc++;
        return;
      }
      let i = id;
      const self = this;
      let url = URL.createObjectURL(file.raw);
      await pdfjsLib.getDocument(url).then(setFieldsData);
      async function setFieldsData(pdfDoc) {
        for (let j = 1; j <= pdfDoc.numPages; j++) {
          await pdfDoc
            .getPage(j)
            .then(getPageInfo)
            .then(
              setTimeout(() => {
                if (j == pdfDoc.numPages) {
                  self.currentDoc++;
                }
              })
            );
        }
        return pdfDoc;
      }
      async function getPageInfo(page) {
        await page.getAnnotations().then((e) => {
          let obj = {};
          obj[page.pageNumber] = {
            fields: e,
            docId: i,
            pageId: page.pageNumber,
          };
          self.pdfFormFields[i] = {
            ...self.pdfFormFields[i],
            ...obj,
          };
        });
      }
    },
    //Get contact types from contact
    getContactTypesFromContact(contact) {
      let result = [];
      if (contact?.contact_types && contact.contact_types.length) {
        result = contact.contact_types.map((e) => {
          if (e?.contact_type?._id) {
            e.contact_type = e.contact_type._id;
          }
          if (e?.account_data_id?._id) {
            e.account_data_id = e.account_data_id._id;
          }
          return e;
        });
      } else if (contact.contact_type) {
        let obj = {};
        obj["contact_type"] = contact.contact_type._id
          ? contact.contact_type._id
          : contact.contact_type;
        obj["has_login"] = true;
        if (contact.account_data_id) {
          obj["account_data_id"] = contact.account_data_id;
        }
        result = [obj];
      }
      if (
        this.getStandardContactType?._id &&
        (!result.length ||
          result.findIndex(
            (e) => e.contact_type == this.getStandardContactType?._id
          ) == -1)
      ) {
        result.push({
          contact_type: this.getStandardContactType?._id,
          has_login: true,
        });
      }
      return result;
    },
    getAccountDataId(contactTypes, contact_type) {
      if (contactTypes.length) {
        return contactTypes.find((e) => e.contact_type == contact_type);
      }
      return [];
    },
    mapCoordinates(elements, document_url_paths) {
      let results = [];
      for (let i = 0; i < document_url_paths.length; i++) {
        let documentDiv = document.getElementById(`wrapper_${i}`);
        if (documentDiv) {
          let docElements = elements.filter((e) => {
            if (
              e &&
              e.y > documentDiv.offsetTop &&
              e.y < documentDiv.offsetHeight + documentDiv.offsetTop
            ) {
              return e;
            }
          });
          let allFieldsPages = JSON.parse(JSON.stringify(docElements));
          let preDocHeight = 0;
          for (let k = 0; k < i; k++) {
            let maxPreLength = document.getElementById(`wrapper_${k}`)
              .childNodes.length;
            for (let j = 0; j < maxPreLength; j++) {
              preDocHeight =
                preDocHeight +
                document.getElementById(`wrapper_${k}`).childNodes[j].height;
            }
          }
          let maxLength = document.getElementById(`wrapper_${i}`).childNodes
            .length;
          let pageBreaks = [];
          let h = 0;
          for (let o = 0; o < maxLength; o++) {
            if (o == 0) {
              h =
                h +
                preDocHeight +
                document.getElementById(`wrapper_${i}`).childNodes[o].height;
            } else {
              h =
                h +
                document.getElementById(`wrapper_${i}`).childNodes[o].height;
            }
            pageBreaks.push(h);
          }
          pageBreaks.forEach((page, index) => {
            allFieldsPages = allFieldsPages.map((element) => {
              element.docId = i;
              if (
                element.y <= page + index * 6 &&
                (index == 0 ||
                  (index > 0 &&
                    element.y >= pageBreaks[index - 1] + (index - 1) * 6))
              ) {
                element.page = index + 1;
              } else if (element.y >= pageBreaks[pageBreaks.length - 1]) {
                element.page = pageBreaks.length;
              }
              return element;
            });
          });
          allFieldsPages = allFieldsPages.map((element) => {
            let heightOffset = document.getElementById(
              `${i}_canvas_${element.page}`
            ).offsetTop;
            if (heightOffset) {
              element.print_y = element.y - heightOffset;
            } else {
              element.print_y = element.y;
            }
            let offset = document.getElementById(
              `${i}_canvas_${element.page}`
            ).offsetLeft;
            if (offset) {
              element.print_x = element.x - offset;
            } else {
              element.print_x = element.x;
            }
            return element;
          });
          results = [...results, ...allFieldsPages];
        }
      }
      return results;
    },
    isDocExisted(doc, type) {
      type = type.toLowerCase();
      if (type == "standard") {
        return this.newlySelectedDocuments[type].find(
          (e) => e.type == doc.type && e.value == doc.value
        ) || this.documentsData.find((e) => e.standard_document == doc.value)
          ? true
          : false;
      } else {
        return this.newlySelectedDocuments[type].find(
          (e) => e._id == doc._id
        ) || this.documentsData.find((e) => e.company_document_id == doc._id)
          ? true
          : false;
      }
    },

    async onAddNewDocument() {
      this.configureCompanyDocumentsVisible = true;
      this.newlySelectedDocuments.static = [];
      this.newlySelectedDocuments.standard = [];
      this.newlySelectedDocuments.custom = [];
      this.newlySelectedDocuments.requested = [];
      this.searchDocumentFormData.type = "";
      this.searchDocumentFormData.group_id = "";
      this.searchDocumentFormData.category_id = "";
      this.searchDocumentFormData.searchedDocuments = [];
      this.fetchCategories();
      this.fetchGroups();
    },
    async fetchCategories() {
      await this.$store.dispatch("documentCategories/fetchAllCategories", {
        get_all: true,
      });
    },
    async fetchGroups() {
      await this.$store.dispatch("documentGroups/fetchAllGroups", {
        get_all: true,
      });
    },

    async getCompanyDocuments() {
      this.fetchingCompanyDocumentDetails = true;
      let params = {
        type: this.searchDocumentFormData.type || undefined,
        category: this.searchDocumentFormData.category_id || undefined,
        search_string: this.searchDocumentFormData.search_string || undefined,
        groups: this.searchDocumentFormData.group_id || undefined,
        get_all: true,
      };
      await this.$store.dispatch(
        "companyDocuments/fetchCompanyAllDocuments",
        params
      );

      this.fetchingCompanyDocumentDetails = false;
    },
    // addDocumentToStep(doc, type) {
    //   if (!this.isDocExisted(doc, type)) {
    //     this.newlySelectedDocuments[type].push(doc);
    //   }
    // },

    addDocumentToStep(doc, type) {
      if (!this.isDocExisted(doc, type)) {
        if (doc.type == "CUSTOM") {
          if (doc.configurable_document_id) {
            this.newlySelectedDocuments[type].push(doc);
          } else {
            this.$message.warning({
              message: "Selected Custom Document has no configuration",
              showClose: true,
            });
          }
        } else {
          this.newlySelectedDocuments[type].push(doc);
        }
      }
    },
    async doneCompanyDocuemnts() {
      this.configureCompanyDocumentsVisible = false;
      if (this.getTotalDocuments(this.newlySelectedDocuments) > 0) {
        await this.addWorkflowEmployeeDocument();
      }
    },
    async fecthEntityData() {
      try {
        if (this.selectedEntity && this.selectedEntity.length) {
          this.allEntitytemplateData = [];
          let params = {
            templateIds: this.selectedEntity
              .filter((e) => e.template_id)
              .flatMap((e) => e.template_id),
            entityId: this.$route.params.entity_id,
          };
          await this.$store.dispatch(
            "templatesData/fetchTemplateDataByEntity",
            params
          );
          if (this.getTemplateDataByentityId) {
            this.allEntitytemplateData = this.getTemplateDataByentityId;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    doneCompanyUserDocuemnts() {
      this.configureCompanyDocumentsVisible = false;
    },
    removeComapnyDocument(type, index) {
      this.newlySelectedDocuments[type].splice(index, 1);
    },
    preareNewlyAddedDocumentData() {
      let data = {
        static: [],
        standard: [],
        custom: [],
        requested: [],
      };
      data.static = this.newlySelectedDocuments.static.map((e) => e._id);
      data.custom = this.newlySelectedDocuments.custom.map((e) => e._id);
      data.requested = this.newlySelectedDocuments.requested.map((e) => e._id);
      data.standard = this.newlySelectedDocuments.standard.map((e) => e.value);
      return data;
    },
    getStandardDocumentSlug(standardDocument) {
      return companyDocumentsData.STANDARD_DOCUMENTS_MAP[standardDocument].slug;
    },
    closeUploadedDocumentDialog() {
      this.uploadRequestedDocumentDialog = false;
    },

    getEmployeeDocumentComponentByCompanyDocument(companyDocument) {
      let type = companyDocument.type;
      if (type == "CUSTOM") {
        return this.documentViewComponentsMap[type][
          companyDocument.configure_type
        ];
      } else {
        return this.documentViewComponentsMap[type];
      }
    },
    checkIsRepeatable(selectedItem) {
      if (
        selectedItem &&
        selectedItem.entity_id &&
        this.allUsersEntityData &&
        selectedItem.template_id
      ) {
        let selectedEntity = this.allUsersEntityData.find(
          (e) => e && e.id == selectedItem.entity_id
        );
        if (selectedEntity && selectedEntity.templates) {
          let selectedTemp = selectedEntity.templates.find(
            (e) => e && e.template_id == selectedItem.template_id
          );
          if (
            selectedTemp &&
            selectedTemp.is_repeatable &&
            !selectedItem.indexKey &&
            selectedItem.type != "REPEATABLE_PARENT" &&
            !selectedItem.parent_repeatable_field_key
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      return true;
    },
    checkDisabled() {
      if (this.selectedItem.type == "PRIMARY_FIELDS") {
        if (
          !this.selectedItem.selected_tag ||
          !this.selectedItem.selected_user
        ) {
          return true;
        }
        return false;
      }

      if (this.selectedItem.field_type == "ENTITY_FIELD" && this.selectedItem.type !== 'PARAGRAPH') {
        if (
          !this.selectedItem.template_id ||
          !this.selectedItem.key ||
          this.checkIsRepeatable(this.selectedItem)
        ) {
          return true;
        }
      }
      if (this.selectedItem.type == "CURRENCY") {
        if (
          this.selectedItem.validations &&
          !this.selectedItem.validations.currency
        ) {
          return true;
        }
      }
      if (this.selectedItem.field_type == "ENTITY") {
        if (!this.selectedItem.entity_id || !this.selectedItem.key) {
          return true;
        }
      }
      if (this.selectedItem.field_type == "ENTITY_VARIABLE") {
        if (
          !this.selectedItem.entity_id ||
          !this.selectedItem.key ||
          (!this.selectedItem.template_id &&
            !this.selectedItem.key.includes("~")) ||
          (!this.selectedItem.parent_entity_field_key &&
            !this.selectedItem.parent_repeatable_field_key)
        ) {
          return true;
        }
      }
      if (this.selectedItem.type == "INITIAL" && this.selectedItem.text) {
        return false;
      }
      if (this.selectedItem.type == "IMAGE" && this.imgData) {
        return false;
      }
      // if(this.selectedItem.type == "CHECKBOX" && !)
      if (
        this.selectedItem.type == "TABLE" &&
        this.selectedItem.data &&
        this.selectedItem.headers &&
        this.selectedItem.data.length &&
        this.selectedItem.headers.length
      ) {
        return false;
      }
      if (
        this.selectedItem.type == "my-signature" &&
        !this.selectedItem.source
      ) {
        return true;
      } else if (this.selectedItem.type == "DATE_SIGNED") {
        return !this.selectedItem.signature_field;
      }

      if (
        this.selectedItem.type == "HEADING" &&
        this.selectedItem.selected_tag &&
        this.selectedItem.content &&
        this.selectedItem.content.trim()
      ) {
        return false;
      }

      if (
        (this.selectedItem.type == "PARAGRAPH" ||
          this.selectedItem.type == "SINGLE_LINE_CONTENT") &&
        this.selectedItem.content &&
        this.selectedItem.content.trim()
      ) {
        return false;
      }
      if (this.selectedItem.type == "HORIZONTAL_LINE") {
        return false;
      }

      if (this.selectedItem.type === "FORMULA") {
        return (
          !this.validExpression(this.selectedItem) || !this.selectedItem.label
        );
      }
      return this.selectedItem.label && this.selectedItem.filled_by
        ? false
        : true;
    },
    validExpression(field) {
      let exp = "";
      let lastField = "";
      let err = false;

      console.log("field.selected_fields", field.selected_fields);

      if (
        field &&
        field.selected_fields &&
        field.selected_fields[0] &&
        field.selected_fields[0].input_type === "DATE_TIME_RANGE"
      ) {
        return true;
      } else {
        let selectedFieldsLength = field.selected_fields.length;
        if (selectedFieldsLength && selectedFieldsLength > 2) {
          field.selected_fields.map((el) => {
            if (el.type == "OPERATOR") {
              exp += el.operator + " ";
              if (lastField != "operator") {
                lastField = "operator";
              } else {
                err = true;
              }
            } else if (el.type == "PARENTHESES") {
              exp += el.parentheses + " ";
              lastField = "";
            } else {
              exp += Math.floor(Math.random() * Math.floor(9)) + " ";
              if (lastField != "number") {
                lastField = "number";
              } else {
                err = true;
              }
            }
            return;
          });
          try {
            if (err) {
              return false;
            } else {
              mexp.eval(exp);
              return true;
            }
          } catch (e) {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    async save(isRepeatable = false) {
      console.log("=-=-=-=", this.checkBoxGroupArray);
      let checkUsersFromDraft = this.documentRecipientsList
        ? this.documentRecipientsList
        : this.documentUsers;
      let ccUsers = checkUsersFromDraft.find(
        (user) => user.type != "SENDER" && user.user_type === "CC"
      );
      let entityId =
        this.selectedItem.type == "ENTITY"
          ? this.selectedItem.entity_id
          : this.selectedUserEntity && this.selectedUserEntity.id
          ? this.selectedUserEntity.id
          : "";
      let exKey = this.selectedRepeatableIndex;
      let fieldsKey = this.recipientSelection;
      let pageBoundary = this.getPagesBoundaries(this.selectedItem.y);
      var newWidth = 0;

      if (this.selectedItem.type == "ENTITY") {
        bus.$emit("entity-selected");
      }
      if (this.selectedItem.type == "MY_INITIAL") {
        await this.$refs.configureField.saveCapturedInitial();
        await this.getCurrentUserInitial();
        this.elements.forEach((el) => {
          if (el.type === "MY_INITIAL") {
            el.value = this.currentUserInitial;
          }
        });
        this.selectedItem.value = this.currentUserInitial;
      }
      if (this.selectedItem.type == "CHECKBOX") {
        this.selectedItem.width = this.selectedItem.height;
        this.isAspectRatio = false;
        setTimeout(() => {
          this.isAspectRatio = true;
        }, 1000);
      }
      if (this.selectedItem.type === "my-signature") {
        await this.$refs.configureField.saveCapturedSignature();
        if (this.getAuthenticatedUser && this.getAuthenticatedUser.signature) {
          this.elements.forEach((el) => {
            if (el.type === "my-signature") {
              el.source = this.getAuthenticatedUser.signature;
            }
          });
          this.selectedItem.source = this.getAuthenticatedUser.signature;
          var dimensions = await this.getImageDimensions(
            this.selectedItem.source
          );
          this.isAspectRatio = false;
          this.elements.forEach((el) => {
            if (el.type === "my-signature") {
              el.source = this.getAuthenticatedUser.signature;
              // el.width = dimensions.w;
              el.height = (el.width * dimensions.h) / dimensions.w;
            }
          });
          this.selectedItem.height =
            (this.selectedItem.width * dimensions.h) / dimensions.w;
          setTimeout(() => {
            this.isAspectRatio = true;
          }, 1000);
        }
      }

      if (this.selectedItem.type === "PRIMARY_FIELDS") {
        
        if (
          this.selectedItem.selected_tag == "address" &&
          this.selectedItem.addressInfo == "Personal Address" &&
          this.getAuthenticatedUser && this.getAuthenticatedUser.address !== ""
        ) {
          if(this.selectedItem.content && this.selectedItem.content != this.getAuthenticatedUser.address){
            this.$set(
              this.selectedItem,
              "content",
              this.selectedItem.content
            );
          }else{
          console.log("this.selectedItem,'content',",this.selectedItem.content);
          this.$set(
            this.selectedItem,
            "content",
            this.getAuthenticatedUser && this.getAuthenticatedUser.address
          );
          }
        }
        if (
          this.selectedItem.selected_tag == "address" &&
          this.selectedItem.addressInfo == "Company Address" &&
          this.getCompanyDetails && this.getCompanyDetails.address !== ""
        ) {
          if(this.selectedItem.content && this.selectedItem.content != this.getCompanyDetails.address){
            this.$set(
              this.selectedItem,
              "content",
              this.selectedItem.content
            );
          }else{
            this.$set(
              this.selectedItem,
              "content",
              this.getCompanyDetails && this.getCompanyDetails.address
            );
          }
        }
        if (
          this.selectedItem.selected_tag === "address" &&
          (
              (this.selectedItem.addressInfo === "Company Address" && 
               this.getCompanyDetails.address && this.getCompanyDetails.address === ""
              ) ||
              (this.selectedItem.addressInfo === "Personal Address" && 
               this.getAuthenticatedUser.address && this.getAuthenticatedUser.address === ""
              )
          ) &&
          this.selectedItem.content
        ) {
            this.$set(this.selectedItem, "content", this.selectedItem.content);
        }
        if (
          (this.selectedItem.selected_tag == "full_name" ||
            this.selectedItem.selected_tag == "last_name" ||
            this.selectedItem.selected_tag == "first_name") &&
          this.selectedItem.is_use_boxes
        ) {
          if (this.selectedItem.boxNumber === 0) {
            this.selectedItem.boxNumber =
              this.selectedItem.content && this.selectedItem.content.length
                ? this.selectedItem.content.length
                : 0;
          } else {
            this.selectedItem.boxNumber = this.selectedItem.boxNumber
              ? this.selectedItem.boxNumber
              : 0;
          }
          if (!this.selectedItem.boxWidth) {
            this.$message.error("Please add box width!");
            return;
          }
          if (
            this.selectedItem.boxNumber > 0 &&
            this.selectedItem.boxes &&
            this.selectedItem.boxes.length == 0
          ) {
            if (this.selectedItem.content && this.selectedItem.content.length) {
              this.selectedItem.boxes = Array.from(this.selectedItem.content);
            }
          }
        }
        this.selectedItem.content = this.getFieldVal(
          this.selectedItem.selected_tag,
          this.selectedItem.selected_user,
          this.selectedItem.selected_tag == "address" &&
            this.selectedItem.addressInfo === "Company Address"
            ? true
            : false
        );
        let fontSize =
          this.selectedItem.font && this.selectedItem.font.size
            ? this.selectedItem.font.size
            : 16;
        this.selectedItem.height =
          this.selectedItem.selected_tag == "address"
            ? this.selectedItem.height
            : fontSize * 1.5;
        if (!this.selectedItem.content) {
          this.selectedItem.content = "";
        }
        if (this.selectedItem.template_id) {
          delete this.selectedItem.template_id;
        }
        if (this.selectedItem.value) {
          delete this.selectedItem.value;
        }
        this.selectedItem.field_type = "CUSTOM_FIELD";
        this.elements[this.selectedItemIndex] = { ...this.selectedItem };
        this.addItemDataDialogVisible = false;

        return;
      } else if (
        this.selectedItem.type != "HEADING" &&
        this.selectedItem.type != "PARAGRAPH" &&
        this.selectedItem.type != "SINGLE_LINE_CONTENT"
      ) {
        if (this.selectedItem.content) {
          delete this.selectedItem.content;
        }
      }
      if (
        this.selectedItem.type === "SINGLE_LINE_TEXT" &&
        !this.selectedItem.is_use_boxes
      ) {
        if (this.selectedItem.selected_tag) {
          delete this.selectedItem.selected_tag;
        }
        let fontSize =
          this.selectedItem.font && this.selectedItem.font.size
            ? this.selectedItem.font.size
            : 16;
        this.selectedItem.height = fontSize * 1.5;
        if (this.selectedItem.content) {
          delete this.selectedItem.content;
        }
        // let indx = this.getLastIndexOfElement("SINGLE_LINE_TEXT");
        // this.selectedItem.label = "Single Line Text " + indx + 1;
        if (
          this.selectedItem.field_type != "ENTITY_FIELD" &&
          this.selectedItem.field_type != "ENTITY" &&
          this.selectedItem.field_type != "ENTITY_VARIABLE"
        ) {
          this.selectedItem.field_type = "CUSTOM_FIELD";
        }
        this.elements[this.selectedItemIndex] = { ...this.selectedItem };
        this.addItemDataDialogVisible = false;
        return;
      }
      if (this.selectedItem.type == "TABLE") {
        let tblWidth = this.selectedItem.headers.length * 70;
        if (this.selectedItem.width < tblWidth) {
          this.selectedItem.width = tblWidth;
        }
      }
      if (
        this.selectedItem.type == "CHECKBOX_GROUP" &&
        this.selectedItem.field_type === "ENTITY_VARIABLE"
      ) {
        let selectedTemp = this.getAllCompanyTemplatesData.data.find(
          (el) => el._id == this.selectedItem.template_id
        );
        let currentField = this.selectedItem;
        let currentFormTempField = selectedTemp?.sections[0]?.fields.find(
          (el) => {
            let key = this.selectedItem.global_variable_entity_field.split("#");
            let key1 = key.length > 1 ? key[1] : "";
            if (el.input_type === this.selectedItem.type && el.key === key1) {
              return el;
            }
          }
        );
        console.log(
          "----selectedEntity---",
          selectedTemp,
          this.selectedItem,
          currentFormTempField
        );
        fieldsKey = fieldsKey + "-" + currentField.key;
        let templateValue = this.setEntityDataToField(
          fieldsKey,
          currentField.template_id,
          isRepeatable ? this.selectedRepeatableIndex : null,
          currentField.type,
          currentField.allow_multiple
        );

        let font = currentField.font;
        let indx = this.getLastIndexOfElement(currentField.type);
        this.checkBoxGroupArray.push({
          key: isRepeatable
            ? currentFormTempField.key + "/" + exKey
            : currentFormTempField.key,
          name: currentFormTempField.label,
          filled_by: this.recipientSelection,
          min_value: currentFormTempField.min_selection
            ? currentFormTempField.min_selection
            : 0,
          max_value: currentFormTempField.max_selection
            ? currentFormTempField.max_selection
            : 0,
          required: currentField.validations.required,
          show_checkbox_label: true,
          isFromFormTemplate: true,
        });
        currentField.width = 30;
        currentField.height = 30;
        let obj = {
          key: isRepeatable
            ? currentFormTempField.key + "/" + exKey
            : currentFormTempField.key,
          name: currentFormTempField.label,
          filled_by: this.recipientSelection,
          min_value: currentFormTempField.min_selection
            ? currentFormTempField.min_selection
            : 0,
          max_value: currentFormTempField.max_selection
            ? currentFormTempField.max_selection
            : 0,
          required: currentField.validations.required,
          show_checkbox_label: true,
          isFromFormTemplate: true,
        };
        this.checkBoxGrpObj = obj;
        let checkbosgroupoptions =
          currentFormTempField.options && currentFormTempField.options.length
            ? currentFormTempField.options.length
            : currentFormTempField.selected_enity_field_data &&
              currentFormTempField.selected_enity_field_data.options &&
              currentFormTempField.selected_enity_field_data.options.length;
        for (let i = 0; i < checkbosgroupoptions; i++) {
          let data = {
            type: "CHECKBOX",
            is_checkbox_group: true,
            section: "input",
            style: "background:#cbe2d8;",
            addNewField:
              this.selectedRepeatableIndex == "newData" ? true : false,
            height: currentField.height,
            width: currentField.width,
            value:
              templateValue &&
              templateValue.indexOf(currentField.options[i]) !== -1
                ? true
                : false,
            template_field_id: currentField._id,
            template_id: currentField.template_id,
            indexKey: isRepeatable ? exKey : null,
            entity_id: entityId,
            options: currentField.options || [],
            x: currentField.x + (i * currentField.width + 4),
            y: currentField.y,
            validations: currentField.validations,
            font:
              currentField.styles && currentField.styles.font
                ? currentField.styles.font
                : font,
            index: indx,
            key: fieldsKey + "/" + currentField.options[i],
            label: currentField.options[i],
            placeholder: currentField.placeholder || currentField.label,
            required:
              currentField.validations && currentField.validations.required
                ? currentField.validations.required
                : false,
            allow_to_add_new_entity_data: currentField.allow_quick_add,
            group_key: isRepeatable
              ? currentFormTempField.key + "/" + exKey
              : currentFormTempField.key,
            field_type: "ENTITY_FIELD",
            filled_by:
              ccUsers && checkUsersFromDraft && checkUsersFromDraft.length <= 2
                ? "SENDER"
                : this.recipientSelection || "SENDER_OR_RECEIVER",
            selected_user: this.recipientSelection || "SENDER_OR_RECEIVER",
          };
          console.log(data);
          let addFieldToHistory = {
            data: data,
            action: "add",
            index: this.elements.length,
          };
          this.history.push(addFieldToHistory);
          this.elements.push(data);
        }
        this.deleteElement();
        console.log(
          "thisskskslslsl",
          this.elements,
          this.selectedItem.index,
          this.selectedItemIndex
        );
      }
      if (this.selectedItem.type == "HEADING") {
        newWidth = 0;
        if (
          this.selectedItem.field_type === "ENTITY_VARIABLE" &&
          !this.selectedItem.content
        ) {
          this.selectedItem.content = this.selectedItem.label;
          this.selectedItem.selected_tag = "h1";
        }
        if (this.selectedItem.selected_tag == "h1") {
          this.selectedItem.font.size = 32;
          newWidth = this.selectedItem.content.length * 14;
          this.selectedItem.height = this.selectedItem.height + 10;
        } else if (this.selectedItem.selected_tag == "h2") {
          this.selectedItem.font.size = 24;
          newWidth = this.selectedItem.content.length * 13;
        } else if (this.selectedItem.selected_tag == "h3") {
          this.selectedItem.font.size = 18.72;
          newWidth = this.selectedItem.content.length * 13;
        } else if (this.selectedItem.selected_tag == "h4") {
          this.selectedItem.font.size = 16;
          newWidth = this.selectedItem.content.length * 13;
        } else if (this.selectedItem.selected_tag == "h5") {
          this.selectedItem.font.size = 13.28;
          newWidth = this.selectedItem.content.length * 13;
        } else if (this.selectedItem.selected_tag == "h6") {
          this.selectedItem.font.size = 10.72;
          newWidth = this.selectedItem.content.length * 13;
        }
        if (newWidth > this.selectedItem.width) {
          if (newWidth > this.pageWidth) {
            newWidth = this.pageWidth - 10;
          }
          if (
            this.selectedItem.x + newWidth >
            pageBoundary.pageOffset + pageBoundary.pageWidth
          ) {
            this.selectedItem.x =
              pageBoundary.pageOffset + pageBoundary.pageWidth - newWidth;
          }
          this.selectedItem.width = newWidth;
        }
      }
      if (this.selectedItem.type == "IMAGE") {
        if (this.imgData) {
          this.selectedItem.value = this.imgData;
          if (this.imgDataWidth > 0 && this.imgDataHeight > 0) {
            if (this.imgDataWidth > this.pageWidth) {
              this.selectedItem.width = this.pageWidth - 5;
              this.selectedItem.height =
                this.imgDataHeight * (this.pageWidth / this.imgDataWidth);
              this.selectedItem.x = 0;
              this.selectedItem.y = 0;
            } else {
              if (
                this.pageWidth <
                this.selectedItem.x + this.imgDataWidth - 5
              ) {
                this.selectedItem.x =
                  this.selectedItem.x -
                  (this.selectedItem.x + this.imgDataWidth - this.pageWidth) -
                  5;
              }
              this.selectedItem.width = this.imgDataWidth;
              this.selectedItem.height = this.imgDataHeight;
            }
          }
        }
      }

      if (this.selectedItem.type == "ATTACHMENT") {
        if (this.selectedItem.label.length > 12) {
          this.selectedItem.width = this.selectedItem.label.length * 8;
        }
        // let indx = this.getLastIndexOfElement("ATTACHMENT");
        // this.selectedItem.label = "Attachment " + indx + 1;
        if (this.selectedItem.field_type != "ENTITY_FIELD") {
          this.selectedItem.field_type = "CUSTOM_FIELD";
        }
      }
      if (this.selectedItem.type == "CHECKBOX" && this.selectedItem.group_key) {
        let gIndex = -1;
        gIndex = this.checkBoxGroupArray.findIndex(
          (g) => g.key == this.selectedItem.group_key
        );
        console.log(
          "this.checkBoxGrpObj this.checkBoxGrpObj ",
          this.checkBoxGrpObj
        );
        //this.current_group_index = gIndex;
        this.checkBoxGroupArray[gIndex] = this.checkBoxGrpObj;
      }
      if (
        this.selectedItem.type == "NUMBER" &&
        !this.selectedItem.is_use_boxes
      ) {
        let fontSize =
          this.selectedItem.font && this.selectedItem.font.size
            ? this.selectedItem.font.size
            : 16;
        this.selectedItem.height = fontSize * 1.5;
      }
      if (
        this.selectedItem.field_type != "ENTITY_FIELD" &&
        this.selectedItem.is_use_boxes &&
        (this.selectedItem.type == "SINGLE_LINE_TEXT" ||
          this.selectedItem.type == "NUMBER")
      ) {
        console.log("hihih", this.selectedItem.boxNumber);

        this.selectedItem.boxNumber = this.selectedItem.boxNumber
          ? this.selectedItem.boxNumber
          : this.selectedItem.value && this.selectedItem.value.length;
        if (!this.selectedItem.boxWidth) {
          this.$message.error("Please add box width!");
          return;
        }
        if (
          this.selectedItem.boxNumber * this.selectedItem.boxWidth >
          this.selectedItem.width
        ) {
          this.$message.error(
            "Boxes length should not be greater than the field length"
          );
        }
        if (
          this.selectedItem.boxNumber > 0 &&
          this.selectedItem.boxes.length == 0
        ) {
          console.log("hihih2");
          this.selectedItem.boxes = Array.from(this.selectedItem.value);
        }
      }
      if (
        this.selectedItem.field_type == "ENTITY_FIELD" &&
        this.selectedItem.is_use_boxes &&
        (this.selectedItem.type == "SINGLE_LINE_TEXT" ||
          this.selectedItem.type == "NUMBER")
      ) {
        console.log("hihih", this.selectedItem.value);
        console.log("hihih1", this.selectedItem.value.toString().length);
        let valueWithoutCommas;
        if (this.selectedItem.value.toString().includes(",")) {
          valueWithoutCommas = this.selectedItem.value
            .toString()
            .replace(/,/g, "");
        } else {
          valueWithoutCommas = this.selectedItem.value;
        }
        this.selectedItem.boxNumber = this.selectedItem.boxNumber
          ? this.selectedItem.boxNumber
          : valueWithoutCommas.length;
        if (!this.selectedItem.boxWidth) {
          this.$message.error("Please add box width!");
          return;
        }
        console.log(this.selectedItem.boxNumber, valueWithoutCommas);
        if (
          this.selectedItem.boxNumber > 0 &&
          this.selectedItem.boxes &&
          this.selectedItem.boxes.length == 0
        ) {
          console.log("hihih2");
          this.selectedItem.boxes = Array.from(valueWithoutCommas);
        }
      }
      if (
        (this.selectedItem.type === "DATE" ||
          (this.selectedItem.type === "DATE" &&
            this.selectedItem.field_type == "ENTITY_VARIABLE")) &&
        this.selectedItem.is_use_boxes
      ) {
        console.log(this.selectedItem.value);
        if (!this.selectedItem.boxWidth) {
          this.$message.error("Please add box width!");
          return;
        }
        let formattedDate = "";
        if (
          this.selectedItem.value &&
          this.selectedItem.value.toString() &&
          this.selectedItem.value.toString().length
        ) {
          const date = new Date(this.selectedItem.value);
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          const year = String(date.getFullYear());
          formattedDate = `${month}${day}${year}`;
        }
        this.selectedItem.boxNumber = this.selectedItem.boxNumber
          ? this.selectedItem.boxNumber
          : formattedDate.length;
        if (formattedDate && formattedDate.length) {
          console.log("1", formattedDate.length, formattedDate);
          this.selectedItem.boxNumber = this.selectedItem.boxNumber
            ? this.selectedItem.boxNumber
            : formattedDate.length
            ? formattedDate.length
            : 0;
        }
        console.log(
          "this.selectedItem.boxNumber",
          this.selectedItem.boxNumber,
          this.selectedItem.boxes
        );
        if (
          this.selectedItem.boxNumber > 0 &&
          this.selectedItem.boxes &&
          this.selectedItem.boxes.length === 0
        ) {
          console.log("-=-=-=-coming into ig------");
          this.selectedItem.boxes = Array.from(formattedDate);
          // this.selectedItem.boxNumber = this.selectedItem.boxNumber ? this.selectedItem.boxNumber : formattedDate.length ? formattedDate.length : 0 ;
        }
      }
      if (
        this.selectedItem.type == "SELECT" ||
        this.selectedItem.type == "MULTI_SELECT"
      ) {
        let fontSize =
          this.selectedItem.font && this.selectedItem.font.size
            ? this.selectedItem.font.size
            : 16;
        this.selectedItem.height =
          this.selectedItem.selected_tag == "address"
            ? this.selectedItem.height
            : fontSize * 1.5;
        if (
          (this.selectedItem &&
            this.selectedItem.options &&
            !this.selectedItem.options.length) ||
          this.selectedItem.options.some((opt) => opt.trim() === "")
        ) {
          this.$message({
            message: "Please add options and those should not only spaces",
            type: "warning",
          });
          return;
        }
      }
      if (this.selectedItem.type == "LIST") {
        if (
          this.selectedItem &&
          this.selectedItem.list_data &&
          !this.selectedItem.list_data.length
        ) {
          this.$message({
            message: "Please add list items data",
            type: "warning",
          });
          return;
        }
      }
      if (this.selectedItem.type == "SINGLE_LINE_CONTENT") {
        if (this.selectedItem && this.selectedItem.content === "") {
          this.$message({ message: "Please add content...!", type: "warning" });
          return;
        }
      }
      if (this.selectedItem.type == "DATA_TABLE") {
        if (
          this.selectedItem.map_keys
            ? this.selectedItem.cols == 0
            : this.selectedItem &&
              this.selectedItem.headers &&
              !this.selectedItem.headers.length
        ) {
          this.$message({
            message: "Please add table cloumns data",
            type: "warning",
          });
          return;
        }
        // let tblWidth = this.selectedItem.headers.length * 70;
        // if (this.selectedItem.width < tblWidth) {
        //   this.selectedItem.width = tblWidth;
        // }
      }
      if (this.selectedItem.type == "SIGNATURE") {
        this.selectedItem.width = 225;
        this.selectedItem.height = 36;
        this.isAspectRatio = false;
        setTimeout(() => {
          this.isAspectRatio = true;
        }, 1000);
      }
      if (this.selectedItem.type == "INITIAL") {
        this.selectedItem.width = 100;
        this.selectedItem.height = 50;
      }
      console.log(
        "this.selectedItem 22",
        this.selectedItem,
        this.selectedItemIndex
      );
      this.imgData = null;
      this.elements[this.selectedItemIndex] = { ...this.selectedItem };
      if (this.selectedItem.type == "FORMULA") {
        bus.$emit("formula-updated");
      }
      if (this.selectedItem.type == "DATE") {
        // this.selectedItem.placeholder =
      }
      this.addItemDataDialogVisible = false;
      this.dialogFormVisible = false;

      if (this.selectedItem.type == "PARAGRAPH") {
        setTimeout(() => {
          const element = document.getElementById(
            `MULTI_LINE_TEXT_${this.selectedItem.index}`
          );
          if (element && element.scrollHeight) {
            this.selectedItem.height = element.scrollHeight;
            this.elements[this.selectedItemIndex] = { ...this.selectedItem };
          }
        }, 100);
      }
      this.selectedItem.key = "";
      this.selectedItem.label = "";
      this.selectedItem.filled_by = "";
      this.selectedItem.placeholder = "";
    },
    checkLabelIsReserved() {
      let reserve = false;
      if (
        this.selectedItem.label &&
        this.reservedLabels.indexOf(this.selectedItem.label.toLowerCase()) != -1
      ) {
        reserve = true;
      }
      return reserve;
    },
    async updatingProfileInfo(){
      let updatedCompanyData = {};
      let updatedProfileData = {
        first_name:this.getAuthenticatedUser.first_name,
        last_name:this.getAuthenticatedUser.last_name,
        title:this.getAuthenticatedUser.title,
        phone:this.getAuthenticatedUser.phone,
        address:this.getAuthenticatedUser.address
      };
      this.elements.forEach((e) => {
        if (
          e &&
          e.type === 'PRIMARY_FIELDS' &&
          e.selected_user === 'SENDER' 
          
        ) {
          if(e.selected_tag === 'address' && e.addressInfo === 'Company Address' && e.content){
            updatedCompanyData = {
            ...this.getCompanyDetails,
            address: e.content
          };
          }else if((e.selected_tag === 'address' && e.addressInfo === 'Personal Address') || e.selected_tag === 'first_name' || e.selected_tag === 'last_name' || e.selected_tag === 'title' || e.selected_tag === 'phone') {
            console.log('updatedProfileData',updatedProfileData)
          
            updatedProfileData = {
              ...updatedProfileData,
              [e.selected_tag]: e.content || this.getAuthenticatedUser?.[e.selected_tag]
              
            };
          
        }
        } 
      });
      if (Object.keys(updatedCompanyData).length > 0) {
        await this.$store.dispatch("company/updateCompany", updatedCompanyData);
      }

      if (Object.keys(updatedProfileData).length > 0) {
       await  this.$store.dispatch("auth/updateUserProfile", updatedProfileData);
      }
    },
    onRowDelete(row) {
      console.log(this.selectedItem,this.activatedItem)
      console.log('--=-=',this.filesData,row,this.selectedItem)
      let index = this.filesData[this.activatedItem.key].files.findIndex((obj) => obj.uid === row.uid);
      if (index > -1) {
        this.filesData[this.activatedItem.key].files.splice(index, 1);
        this.activatedItem.files = this.filesData[this.activatedItem.key].files;
      }
      console.log(this.selectedItem,this.activatedItem)
    },
    closeUploadDocuments() {
      this.uploadDocuments = false;
    },
    searchUploadDocumentName() {
      this.documentUploadData = [];
      this.documentUploadData = this.filesData.filter(
        (e) =>
          e.name
            .toLowerCase()
            .includes(this.searchUploadDocument.toLowerCase()) == true
      );
      //  }
    },
    headerCellStyle() {
      return {
        backgroundColor: "#F2F6FC",
        color: "#606266",
        fontWeight: "bold",
      };
    },
    getFieldValuesForEntityDataFields(array, key){
      const [templateId, field] = key.split("#");
      const foundItem = array.find((item) => item.template_id === templateId);
      if (
        foundItem &&
        foundItem.template_data &&
        foundItem.template_data[field]
      ) {
        return foundItem.template_data[field];
      } else {
        return null; // or any default value you want to return if the key is not found
      }
    },
    async generateEntityLablesIntoValues(users){
      await this.fetchEntities();
      let d1 = []
      let usersss = this.documentRecipientsList ? this.documentRecipientsList : users
      usersss.forEach(async(user) =>{
        console.log(user)
       this.contactTypeEntityId = user && user.value === 'SENDER' ? user.entity_data_id?.entity_id : user.entity_data_id?.entity_id ? user.entity_data_id?.entity_id : this.contactTypeEntityId
        this.allMentionableFields = await this.fetchEntityDetails(
          this.contactTypeEntityId,
          true
        );
        if(user.email && user.entity_data_id?.entity_id){
          //await this.getAllNecessaryDetails();
        let seletedUserTemplateData = [];
        if (this.getTemplatesData && this.getTemplatesData.length) {
          seletedUserTemplateData = this.getTemplatesData.filter(
            (e) =>
              (user.entity_data_id &&
                user.entity_data_id._id &&
                e.entity_data_id == user.entity_data_id._id) ||
              e.entity_data_id == user.entity_data_id
          );
        }
        let da = [];
        if (this.allMentionableFields && this.allMentionableFields.length) {
          da = seletedUserTemplateData.filter((e) =>
            this.allMentionableFields.some(
              (el) => el.template_id === e.template_id
            )
          );
        }
        d1 = da;
      //});
      this.allSelectedEntityMentionFields = this.allMentionableFields;
      if (this.allMentionableFields) {
      //  console.log('---',this.allMentionableFields)
      this.allMentionableFields = this.allMentionableFields.map((item) => ({
        label: item.label,
        template_key: item.template_key,
        id: item.template_key,
        input_type: item.inputType,
        value: this.findValueByKey(d1, item.template_key),
        width:item.width,
      }));
      this.allMentionableFields.forEach(async(field) => {
        let labelPattern = `\\[\\[${field.label}\\]\\]`;
        let regex = new RegExp(labelPattern, 'g');
        if (this.editorContent.includes(`[[${field.label}]]`) && field.value) {
          await this.addNullOrUndeifnedValuesFromMentions(field,field.value)
          this.editorContent = this.editorContent.replace(regex, field.value);
        }
        // else if (this.editorContent.includes(`[[${field.label}]]`) && this.isBase64String(field.value)) {
        //   await this.insertImage(field.value)
        //   // If it's a base64 string, replace it with an image tag
        //   return `<img src="data:image/png;base64,${field.value}" alt="" />`;
        //  }
        else if(field.input_type === 'DATE' || field.input_type === "DATE_TIME_RANGE" || field.input_type === "TIME_RANGE" || field.input_type === "DATE_RANGE" || field.input_type === 'TIME' || field.input_type === 'DATE_TIME'){
          const formattedValue = this.formatField(field);
          await this.addNullOrUndeifnedValuesFromMentions(field,formattedValue)
          this.editorContent = this.editorContent.replace(regex, formattedValue);
          //return `<span style="color: #000000;">${formattedValue}</span>`;
        }else{
          if(this.editorContent.includes(`[[${field.label}]]`) && field.value === null || field.value === undefined || field.value === 'null' || field.value === 'undefined'){
            await this.addNullOrUndeifnedValuesFromMentions(field)
            console.log('000-000',field)
            return `<input type="text" style="width: ${field.width}px; border-top: none; border-right: none; border-left: none; border-bottom: 2px solid yellow;" readonly>`;
          }
        }
    });
    
    console.log(this.editorContent);
      }

    }
      //console.log("--this.allMentionableFields---", this.allMentionableFields);
      });
      
    },
    
    getFieldsFromElements() {
      let duplicateKeyCounter = {},
        highCounter = {};
      this.templatesUsed = [];
      this.entitiesUsed = [];
      this.elements.forEach((e) => {
        if (duplicateKeyCounter[e.type]) {
          duplicateKeyCounter[e.type] = duplicateKeyCounter[e.type] + 1;
        } else {
          duplicateKeyCounter[e.type] = 1;
        }
        if (e.key && e.key.includes(e.type.toLowerCase())) {
          let keyCount = e.key.split(e.type.toLowerCase() + "_")[1];
          if (Number(keyCount)) {
            if (Number(keyCount) > highCounter[e.type]) {
              highCounter[e.type] = Number(keyCount);
            } else {
              highCounter[e.type] = Number(keyCount);
            }
          }
        }
      });
      return this.elements.map((e) => {
        if (!e.key) {
          e.key =
          e.filled_by + "@" + e.type.toLowerCase() +
            "_" +
            (highCounter[e.type]
              ? highCounter[e.type] + 1
              : duplicateKeyCounter[e.type]
              ? duplicateKeyCounter[e.type]
              : 1);
        }
        return e;
      });
      // this.elements.forEach((e) => {
      //   let key
      //   if(e.key){
      //     key = e.key;
      //   }else{
      //     key = this.generateKeyForField(e.label);
      //   }
      //   if (!e.entity_id && !e.template_id) {
      //     if (typeof duplicateKeyCounter[key] == "number") {
      //       let index =
      //         e.key && e.key.includes(key + "_") && e.key.split(key + "_")[1]
      //           ? parseInt(e.key.split(key + "_")[1]) + 1
      //           : 1;
      //       if (index > duplicateKeyCounter[key]) {
      //         duplicateKeyCounter[key] = index;
      //       }
      //     } else {
      //       duplicateKeyCounter[key] =
      //         e.key && e.key.includes(key + "_") && e.key.split(key + "_")[1]
      //           ? parseInt(e.key.split(key + "_")[1]) + 1
      //           : 1;
      //     }
      //   }
      // });
      // console.log("duplicateKeyCounter duplicateKeyCounter", duplicateKeyCounter)
      // return this.elements.map((element, index) => {
      //   element.sno = index + 1;
      //   if (!element.entity_id && !element.template_id) {
      //     let newKey
      //     if(element.key){
      //       newKey = element.key;
      //     }else{
      //       newKey = this.generateKeyForField(element.label);
      //     }
      //     if (duplicateKeyCounter[newKey]) {
      //       if (!element.key) {
      //         let index = parseInt(duplicateKeyCounter[newKey]);
      //         element.key = newKey + "_" + index + 1;
      //         duplicateKeyCounter[newKey]++;
      //       }
      //     } else {
      //       duplicateKeyCounter[newKey] = 1;
      //       if (!element.key) {
      //         element.key = newKey + '_' + index + 1;
      //         duplicateKeyCounter[newKey]++;
      //       }
      //     }
      //   }
      //   if (element.type == "HEADING") {
      //     element.content = element.content ? element.content : element.value;
      //     element.source = element.selected_tag;
      //   }

      //   if (element.type == "PARAGRAPH") {
      //     element.content = element.content ? element.content : element.value;
      //     element.source = "";
      //   }
      //   if (element.type == "HORIZONTAL_LINE") {
      //     element.value = element.content;
      //   }
      //   return element;
      // });
    },

    deleteElement() {
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.configurable_document_data &&
        this.getSingleDocumentData.data.configurable_document_data
          .lock_template_fields
      ) {
        //this.$message.warning('This document fields are locked');
      } else {
        if (this.selectedItemIndex > -1 && this.selectedItem) {
          if (
            this.elements[this.selectedItemIndex] &&
            this.elements[this.selectedItemIndex]["type"] &&
            (this.elements[this.selectedItemIndex]["type"] == "SIGNATURE" ||
              this.elements[this.selectedItemIndex]["type"] ==
                "my-signature") &&
            this.elements[this.selectedItemIndex]["filled_by"] == "SENDER"
          ) {
            let selectedUserSignatures = this.elements.filter(
              (el) =>
                (el.type == "SIGNATURE" || el.type == "my-signature") &&
                el.filled_by == "SENDER"
            );
            if (selectedUserSignatures && selectedUserSignatures.length == 1) {
              // let dateSignedIndex = this.elements.findIndex(el => el.type == 'DATE_SIGNED' && el.filled_by == 'SENDER');
              // this.elements.splice(dateSignedIndex, 1);
              let dateSignedIndexs = [];
              this.elements.forEach((el, index) => {
                if (el.type == "DATE_SIGNED" && el.filled_by == "SENDER") {
                  dateSignedIndexs.push(index);
                }
              });
              if (dateSignedIndexs.length) {
                dateSignedIndexs.forEach((el) => this.elements.splice(el, 1));
              }
            }
          }
          if (
            this.elements[this.selectedItemIndex] &&
            this.elements[this.selectedItemIndex]["type"] &&
            this.elements[this.selectedItemIndex]["filled_by"] &&
            this.elements[this.selectedItemIndex]["type"] == "SIGNATURE" &&
            this.elements[this.selectedItemIndex]["filled_by"] != "SENDER"
          ) {
            let selectedUserSignatures = this.elements.filter(
              (el) =>
                el.type == "SIGNATURE" &&
                el.filled_by ==
                  this.elements[this.selectedItemIndex]["filled_by"]
            );
            if (selectedUserSignatures && selectedUserSignatures.length == 1) {
              // let dateSignedIndex = this.elements.filter(el => el.type == 'DATE_SIGNED' && el.filled_by == this.elements[this.selectedItemIndex]['filled_by']);
              let dateSignedIndexs = [];
              this.elements.forEach((el, index) => {
                if (
                  el.type == "DATE_SIGNED" &&
                  el.filled_by ==
                    this.elements[this.selectedItemIndex]["filled_by"]
                ) {
                  dateSignedIndexs.push(index);
                }
              });
              if (dateSignedIndexs.length) {
                dateSignedIndexs.forEach((el) => this.elements.splice(el, 1));
              }
            }
          }
          if (
            this.elements[this.selectedItemIndex] &&
            this.elements[this.selectedItemIndex]["type"] == "ENTITY" &&
            this.checkForChildren
          ) {
            this.$message({
              message:
                "Some fields are associated to this entity field. Can't delete.",
              type: "warning",
            });
            return;
          }
          if (
            this.elements[this.selectedItemIndex] &&
            this.elements[this.selectedItemIndex]["type"] ==
              "REPEATABLE_PARENT" &&
            this.checkForRepeatableChildren
          ) {
            this.$message({
              message:
                "Some fields are associated to this repeatable field. Can't delete.",
              type: "warning",
            });
            return;
          }
          console.log(
            "567",
            this.selectedItem,
            this.elements[this.selectedItemIndex]
          );
          let addFieldToHistory = {
            action: "delete",
            data: this.selectedItem,
            index: this.selectedItemIndex,
          };
          this.history.push(addFieldToHistory);
          // console.log(this.elements);

          this.elements.splice(this.selectedItemIndex, 1);

          this.addItemDataDialogVisible = false;
          this.dialogFormVisible = false;
          this.selectedItemIndex = -1;
          this.selectedItem = {};
        }
      }
    },
    generateKeyForField(label) {
      let parsedLabel = label.replace(/[^a-zA-Z 0-9]/g, "");
      parsedLabel = parsedLabel.toLowerCase().split(" ").join("_");
      return parsedLabel;
    },
    fillImageData({ file, imgDataHeight, imgDataWidth }) {
      this.imgData = file;
      this.imgDataWidth = imgDataWidth;
      this.imgDataHeight = imgDataHeight;
    },
    checkRoleDuplicate(name, index) {
      let checkExistedUser = "";
      this.users.forEach((e, i) => {
        if (
          e.name &&
          name &&
          (typeof index != "number" || (index > -1 && index != i))
        ) {
          if (
            this.generateKeyForField(name) == this.generateKeyForField(e.name)
          ) {
            checkExistedUser = e.name;
          }
        }
      });
      //
      if (checkExistedUser) {
        return false;
      } else {
        return true;
      }
    },
    async getCompanyInformation() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "company/fetchCompany",
          this.getActiveWorkspace.company_id
        );
        if (this.getCompanyDetails) {
          console.log("called successfully");
          this.loading = false;
          return this.getCompanyDetails;
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
  },
};
