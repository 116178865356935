import axios from "@/config/axios";

const initialState = () => {
  return {
    userDetails: null,
    userVerifyDetails: null,
    userEmailVerifyDetails: null,
    userEmailVerificationErrors: null,
    userSignInVerify: null,
    forgotAndResendVerify: null,
    forgotAndResendVerifyError: null,
    userDocuments: null,
    userWorkflows: null,
    userFormbuilders: null,
  };
};

const state = initialState();

const getters = {
  getUserDetails: state => state.userDetails,
  getUserVerifyDetails: state => state.userVerifyDetails,
  getUserEmailVerifyDetails: state => state.userEmailVerifyDetails,
  getUserEmailVerificationErrors: state => state.userEmailVerificationErrors,
  getUserSignInVerify: state => state.userSignInVerify,
  getForgotAndResendVerify: state => state.forgotAndResendVerify,
  getForgotAndResendVerifyError: state => state.forgotAndResendVerifyError,
  getUserDocuments: state => state.userDocuments,
  getUserWorkflows: state => state.userWorkflows,
  getUserFormbuilders: state => state.userFormbuilders,
};

const mutations = {
  setUserDetails: (state, data) => {
    state.userDetails = data
  },
  setUserVerifyDetails: (state, data) => {
    state.userVerifyDetails = data
  },
  setUserEmailVerifyDetails: (state, data) => {
    state.userEmailVerifyDetails = data
  },
  setUserEmailVerificationErrors: (state, data) => {
    state.userEmailVerificationErrors = data
  },
  setUserSignInVerify: (state, data) => {
    state.userSignInVerify = data
  },
  setForgotAndResendVerify: (state, data) => {
    state.forgotAndResendVerify = data
  },
  setForgotAndResendVerifyError: (state, data) => {
    state.forgotAndResendVerifyError = data
  },
  setUserDocuments: (state, data) => {
    state.userDocuments = data
  },
  setUserWorkflows: (state, data) => {
    state.userWorkflows = data
  },
  setUserFormbuilders: (state, data) => {
    state.userFormbuilders = data
  },
}

const actions = {
  fetchUserVerifyDetails: async ({
    commit
  }, params) => {
    try {
      commit('applicationUsers/setUserVerifyDetails', null, {
        root: true
      })
      // console.log("params",params)
      const response = await axios.post('/application/user-verify', params);
      console.log("response", response.data)
      commit('applicationUsers/setUserVerifyDetails', response.data, {
        root: true
      });
    } catch (error) {
      commit('applicationUsers/setUserVerifyDetails', null, {
        root: true
      });
    }
  },
  fetchUserEmailVerifyDetails: async ({
    commit
  }, params) => {
    try {
      commit('applicationUsers/setUserEmailVerifyDetails', null, {
        root: true
      })
      console.log("params", params)
      const response = await axios.post('/application/user-email-verify', params);
      console.log("response", response.data)
      commit('applicationUsers/setUserEmailVerifyDetails', response.data, {
        root: true
      });
    } catch (error) {
      console.log("ddddddddddddddddddd", error.response)
      if (error?.response?.data) {
        commit('applicationUsers/setUserEmailVerificationErrors', error.response.data, {
          root: true
        });
      }
      commit('applicationUsers/setUserEmailVerifyDetails', null, {
        root: true
      });
    }
  },
  fetchSetUserSignInVerify: async (context, params) => {
    context.commit("auth/resetAuthData", null, {
      root: true
    });
    context.commit("auth/setAuthenticationDetails", {}, {
      root: true
    });
    context.commit("auth/setErrorCode", "", {
      root: true
    });
    context.commit("auth/setUserSignInErrors", null, {
      root: true
    });
    context.commit("auth/setAuthenticatedUser", {}, {
      root: true
    });
    context.commit("auth/setActiveContactType", null, {root: true});
    try {
      const response = await axios.post('/application-user-signin', params);
      let userDetails = response.data.user_details;
      let tokens = {
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
        refresh_token_expires_at: response.data.refresh_token_expires_at,
        access_token_expires_at: response.data.access_token_expires_at
      };
      userDetails['is_contact'] = true;
      context.commit("auth/setActiveContactType", response.data.activeWorkSpace, {root: true});
  
      context.commit("auth/setAuthenticatedUser", userDetails, {
        root: true
      });

      context.commit("auth/setAuthenticationDetails", tokens, {
        root: true
      });

      context.commit("auth/setAuthenticationStatus", true, {
        root: true
      });
      context.commit("auth/setErrorCode", "", {
        root: true
      });
      context.commit("auth/setUserSignInErrors", null, {
        root: true
      });
    } catch (error) {
      context.commit("auth/setAuthenticatedUser", null, {
        root: true
      });
      context.commit("auth/setAuthenticationStatus", false, {
        root: true
      });
      if (error.response) {
        if (error.response.data.errorCode === "EMAIL_NOT_VERIFIED") {

          context.commit("auth/setAccountNotVerified", true, {
            root: true
          })

          context.commit("auth/setAccountNotVerifiedEmail", error.response.data.email, {
            root: true
          })
          await context.dispatch('errors/errorResponse', error.response, {
            root: true
          });
        } else {
          await context.dispatch('errors/errorResponse', error.response, {
            root: true
          });
        }
        console.log("error.response",error.response)
        context.commit("auth/setErrorCode", error.response.data.errorCode, {
          root: true
        });
        context.commit("auth/setUserSignInErrors", error.response.data, {
          root: true
        });
      }
    }
  },
  fetchForgotAndResendVerify: async ({
    commit
  }, params) => {
    try {
      commit('applicationUsers/setForgotAndResendVerify', null, {
        root: true
      })
      // console.log("params",params)
      const response = await axios.post('/application-user/forgot-or-resend', params);
      console.log("response", response.data)
      commit('applicationUsers/setForgotAndResendVerify', response.data, {
        root: true
      });
    } catch (error) {
      console.log("ddddddddddddddddddd", error.response)
      if (error?.response?.data) {
        commit('applicationUsers/setForgotAndResendVerifyError', error.response.data, {
          root: true
        });
      }
      commit('applicationUsers/setForgotAndResendVerify', null, {
        root: true
      });
    }
  },
  fetchUserDocuments: async ({
    commit
  }, params) => {
    try {
      commit('applicationUsers/setUserDocuments', null, {
        root: true
      })
      console.log("params", params)
      const response = await axios.get('/application-user/documents', {params});
      console.log("response", response.data)
      commit('applicationUsers/setUserDocuments', response.data, {
        root: true
      });
    } catch (error) {
      commit('applicationUsers/setUserDocuments', null, {
        root: true
      });
    }
  },
  fetchUserWorkflows: async ({
    commit
  }, params) => {
    try {
      commit('applicationUsers/setUserWorkflows', null, {
        root: true
      })
      const response = await axios.get('/application-user/workflows', params);
      console.log("response", response.data)
      commit('applicationUsers/setUserWorkflows', response.data, {
        root: true
      });
    } catch (error) {
      commit('applicationUsers/setUserWorkflows', null, {
        root: true
      });
    }
  },
  fetchUserFormbuilders: async ({
    commit
  }, params) => {
    try {
      commit('applicationUsers/setUserFormbuilders', null, {
        root: true
      })
      const response = await axios.get('/application-user/formbuilders', {params});
      console.log("response", response.data)
      commit('applicationUsers/setUserFormbuilders', response.data, {
        root: true
      });
    } catch (error) {
      commit('applicationUsers/setUserFormbuilders', null, {
        root: true
      });
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};