import config from '@/config/app'
import store from '@/store'

import CryptoJS from 'crypto-js';



// function checkSSOUserLogin() {
//     let authDetails = store.getters['auth/getAuthenticatedUser']
//     return authDetails && authDetails.sso_user_id
// }



export default {
    authenticatedRoutes(to, from, next) {
        let authDetails = store.getters['auth/getAuthenticationDetails']
        let accessToken = authDetails && authDetails['access_token'] ? authDetails['access_token'] : null

        if (!accessToken) {
            next()
        } else {
            next("/dashboard")
        }


    },

    applicationGuest(to, from, next) {
        let authDetails = store.getters['auth/getAuthenticationDetails']
        let accessToken = authDetails && authDetails['access_token'] ? authDetails['access_token'] : null
        if (!accessToken) {
            next()
        } else {
            next("/ap/documents")
            // next(!accessToken ? true : {
            //     name: "home"
            // })
        }


    },
    guest(to, from, next) {
        let authDetails = store.getters['auth/getAuthenticationDetails']
        let accessToken = authDetails && authDetails['access_token'] ? authDetails['access_token'] : null
        if (!accessToken) {
            next()
        } else {
            next("/dashboard")
            // next(!accessToken ? true : {
            //     name: "home"
            // })
        }


    },

    guestEmployee(to, from, next) {
        var authData = JSON.parse(localStorage.getItem(config.APP_NAME + '_' + config.APP_VERSION))
        var isAuthenticated = authData ? authData.auth.authenticationStatus : false
        next(!isAuthenticated ? true : {
            path: "/emp"
        })

    },

    //Application user route
    applicationUser(to, from, next) {
        let authDetails = store.getters['auth/getAuthenticationDetails'];
        let authUser = store.getters['auth/getAuthenticatedUser'];
        let companyDetails = store.getters['company/getContactLoginSlug']
        let accessToken = authDetails && authDetails['access_token'] ? authDetails['access_token'] : null;

        if (!accessToken) {
            var pB64 = CryptoJS.AES.encrypt(
                JSON.stringify({path:to.fullPath,company_slug: to.query?.companySlug ? to.query?.companySlug : ''}),
                "esignsRedirect"
              ).toString();
              var pE64 = CryptoJS.enc.Base64.parse(pB64);
              var pHex = pE64.toString(CryptoJS.enc.Hex);
            if (companyDetails && companyDetails.slug) {
                next('/ap/signin?redirect_url=' + pHex +'&company=' + companyDetails.slug);
            } else if(to?.query?.companySlug) {
                next('/ap/signin?redirect_url=' + pHex +'&company=' + to.query.companySlug);
            } else {
                next('/signin');
            }
        } else {
            if (authUser.is_contact) {
                if (to.name == 'contact-login-page') {
                    next('/ap/dashboard');
                } else {
                    next();
                }
            } else {
                next('/ap/forbidden')
            }
        }
    },

    user(to, from, next) {
        console.log("route", to)
        let authDetails = store.getters['auth/getAuthenticationDetails']
        let accessToken = authDetails && authDetails['access_token'] ? authDetails['access_token'] : null
        let authUser = store.getters['auth/getAuthenticatedUser'];
        if (!accessToken) {
            var pB64 = CryptoJS.AES.encrypt(
                JSON.stringify({path:to.fullPath}),
                "esignsRedirect"
              ).toString();
              var pE64 = CryptoJS.enc.Base64.parse(pB64);
              var pHex = pE64.toString(CryptoJS.enc.Hex);

            next('/signin?redirect_url=' + pHex);
        } else if (authUser.is_contact) {
            next('/ap/forbidden')
        } else if (to.path == '/signin') {
            next('/');
        }
        else {
            next();
        }


        // else if (checkSSOUserLogin()) {

        //     if (to.query && to.query.already) {
        //         next();
        //     }
        //     else {
        //         let baseURL = window.location.origin + "/sso-signin"
        //         let serverBaseURL = config.AUTHFAST_BASE_URL
        //         window.location.href = serverBaseURL + "/signin/client?redirect_url=" + baseURL + '?next_path=' + to.path;
        //         return;
        //     }

        // }
    },

    passwordProtected(to, from, next) {
        let authDetails = store.getters['auth/getAuthenticationDetails']
        let password = authDetails && authDetails['password'] ? authDetails['password'] : null

        if (!password) {
            next('/signin');
        } else {
            next();
        }
    },
    passwordProtectedOrAuthUser(to, from, next) {
        let authDetails = store.getters['auth/getAuthenticationDetails']
        let password = authDetails && authDetails['password'] ? authDetails['password'] : null
        let accessToken = authDetails && authDetails['access_token'] ? authDetails['access_token'] : null

        var isPasswordUser = password ? true : false
        var isAuthenticatedUser = accessToken ? true : false

        if (!isPasswordUser && !isAuthenticatedUser) {
            next('/signin');
        } else {
            next();
        }

    },
    userOrServer(to, from, next) {
        let authDetails = store.getters['auth/getAuthenticationDetails']
        let accessToken = authDetails && authDetails['access_token'] ? authDetails['access_token'] : null
        var isAuthenticated = accessToken ? true : false
        var server = false
        if (to.query.access_token) {
            let data = {
                "access_token": to.query.access_token,
                "refresh_token": to.query.refresh_token
            }
            store.commit('auth/setAuthenticationDetails', data, {
                root: true
            })
            server = true;
        }
        next((isAuthenticated || server) ? true : {
            path: '/signin',
            query: {
                redirect: to.name
            }
        })
    },

    onlyAdmin(to, from, next) {
        let authUser = store.getters['auth/getAuthenticatedUser']
        let isAdmin = authUser && authUser.user_type && authUser.user_type.name == "ADMIN"
        let redirectPath = '/emp/'
        next(isAdmin ? true : {
            path: redirectPath
        })
    },

    guestUserFormbuilderPasswordProtected(to, from, next) {
        let authDetails = store.getters['auth/getAuthenticationDetails']
        let passcode = authDetails && authDetails['passcode']
        let formbuilderUserId = authDetails && authDetails['user_id'] || authDetails['anonymous_user_id']

        var isAuthenticated = passcode && formbuilderUserId ? true : false
        next(isAuthenticated ? true : {
            name: 'FormbuilderGuestAuth',
            params: to.params,
            query: to.query
        })
    },
    //Form builder approval middleware
    guestApprovalUserFormbuilderPasswordProtected(to, from, next) {
        let authDetails = store.getters['auth/getAuthenticationDetails']
        let passcode = authDetails && authDetails['passcode']
        let formbuilderUserId = authDetails['approver_company_user_id'] || authDetails['approver_contact_id'];

        var isAuthenticated = passcode && formbuilderUserId ? true : false
        next(isAuthenticated ? true : {
            params: '/signin',
            query: to.query
        })
    },
}