<template>
  <div>
    <div style="background-color: #f4f4f4">
      <el-dialog
        :visible.sync="show"
        title="Document Users"
        @close="closeSettings"
        top="20px"
        width="100%"
        v-loading="docUsersLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        class="outersize-confirm-type-three"
      >
        <div
          class="document-users-order add-receipents"
          v-if="!showNewUserForm"
        >
          <!-- <el-button @click="contactTypeDialogVisibible = true" type="warning" size="small" class="float-right" plain> <i class="el-icon-plus"></i> Add Contact Type</el-button> -->
          <div class="select-recipients" v-if="selectedUser">
            <el-form :model="selectRecipient" class="demo-form-inline">
              <h3 class="fw-normal">Edit {{ selectedUser.name }}</h3>
              <el-row type="flex" align="end" :gutter="15">
                <el-col :span="6">
                  <!-- <div>
                      <a
                      :disabled="selectedUser && selectedUser.name == 'SENDER'"
                        style="border: 0; float: right; cursor: pointer;margin-top:-12px"
                        @click="showAddGroupDialog = true"
                      >
                        <i
                          class="el-icon-circle-plus-outline"
                          style="
                            font-weight: 800;
                            color: #f754a2;
                            font-family: inter;
                            "
                        ></i>
                      </a>
                  </div> -->
                  <div class="d-flex">
                    <el-select
                      v-model="selectedUser.contact_type"
                      placeholder="Select Contact Type"
                      @change="checkEntityFields"
                      filterable
                      allow-create
                      default-first-option
                      :disabled="selectedUser.type == 'SENDER'"
                    >
                      <el-option
                        v-for="(type, index) in getAllContactTypes"
                        :key="index"
                        :value="type.name"
                        :label="type.name"
                      >
                        {{ type.name }}
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div>
                    <el-form-item>
                      <el-input
                        v-model="selectedUser.name"
                        type="text"
                        placeholder="Role"
                        :disabled="selectedUser.type == 'SENDER'"
                      >
                      </el-input>
                      <p
                        class="error"
                        v-if="this.selectedUser.name.length == 0"
                      >
                        Role is required
                      </p>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="d-flex">
                    <el-select
                      v-model="selectedUser.user_type"
                      placeholder="Select Signer Type"
                    >
                      <el-option
                        v-for="(type, index) in ['SIGNER', 'CC', 'APPROVER']"
                        :key="index"
                        :value="type"
                        :label="type"
                        :disabled="
                          type == 'CC' &&
                          selectedUser &&
                          (fetchSignUsers.indexOf(selectedUser.value) != -1 ||
                            users.length == 1)
                        "
                      >
                        {{ type }}
                      </el-option>
                    </el-select>
                  </div>
                </el-col>

                <el-col :span="6">
                  <div class="d-flex">
                    <el-button
                      type="danger"
                      @click="updateReceipentUser"
                      v-if="selectedUser"
                      :disabled="checkReceipentUpdate"
                      >Update
                    </el-button>
                    <el-button
                      class="px-3"
                      @click="onCancel"
                      v-if="selectedUser"
                      >Cancel</el-button
                    >
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="select-recipients" v-else>
            <el-form :model="selectRecipient" class="demo-form-inline">
              <h3 class="fw-normal">Add Recipient</h3>
              <el-row type="flex" align="end" :gutter="15">
                <el-col :span="6">
                  <div>
                    <!-- <a
                      :disabled="selectedUser && selectedUser.name == 'SENDER'"
                        style="border: 0; float: right; cursor: pointer;margin-top:-12px"
                        @click="showAddGroupDialog = true"
                      >
                        <i
                          class="el-icon-circle-plus-outline"
                          style="
                            font-weight: 800;
                            color: #f754a2;
                            font-family: inter;
                            "
                        ></i>
                      </a> -->
                  </div>
                  <div class="d-flex">
                    <el-select
                      v-model="selectRecipient.contact_type"
                      placeholder="Select Contact type"
                      @change="onChangeContactType"
                      filterable
                      allow-create
                      default-first-option
                    >
                      <el-option
                        v-for="(type, index) in getAllContactTypes"
                        :key="index"
                        :value="type.name"
                        :label="type.name"
                      >
                        {{ type.name }}
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div>
                    <el-form-item>
                      <el-input
                        v-model="selectRecipient.name"
                        type="text"
                        placeholder="Role"
                      ></el-input>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="d-flex">
                    <el-select
                      v-model="selectRecipient.user_type"
                      placeholder="Select Signer Type"
                    >
                      <el-option
                        v-for="(type, index) in ['SIGNER', 'CC', 'APPROVER']"
                        :key="index"
                        :value="type"
                        :label="type"
                        :disabled="
                          type == 'CC' &&
                          user &&
                          (fetchSignUsers.indexOf(user.value) != -1 ||
                            users.length == 1)
                        "
                      >
                        {{ type }}
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="d-flex">
                    <el-button
                      type="danger"
                      @click="addReceiver"
                      :disabled="
                        !selectRecipient.contact_type ||
                        !selectRecipient.user_type
                      "
                      >Add</el-button
                    >
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <el-dialog
            style="width: 80%"
            title="Add Contact Type"
            :visible.sync="showAddGroupDialog"
            class="outersize-confirm-type-two"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
          >
            <el-form>
              <div>
                <el-form-item class="mb" label="Contact Type">
                  <el-form-item>
                    <el-input
                      v-model="contact_type"
                      placeholder="Enter contact type"
                    >
                    </el-input>
                  </el-form-item>
                </el-form-item>

                <el-form-item class="text-center mt-20">
                  <el-button
                    @click="
                      showAddGroupDialog = false;
                      erase_contactType();
                    "
                  >
                    Cancel
                  </el-button>
                  <el-button
                    type="primary"
                    @click="addContactType(index)"
                    :loading="addContactTypeLoading"
                    :disabled="!contact_type.trim()"
                    class="px-3"
                    ><i class="el-icon-check"></i> Submit
                  </el-button>
                </el-form-item>
              </div>
            </el-form>
          </el-dialog>
          <!-- <el-button type="primary" @click="showNewUserForm = true">Add Receipent</el-button> -->
          <el-row>
            <el-col :span="24">
              <p class="black6 mt-0 mb-1">
                <i class="el-icon-info"></i> You can move the list either by
                dragging it or using the arrow keys (
                <i class="el-icon-top"></i>
                <i class="el-icon-bottom"></i>) to change the order.
              </p>
              <!-- <el-divider class="mb-1"></el-divider> -->
              <div class="users-list table-type-one">
                <div class="table-type-one-child">
                  <div slot="header" class="users-header">
                    <el-row
                      :gutter="15"
                      class="header-row"
                      style="padding-left: 5px"
                    >
                      <el-col
                        :lg="2"
                        :md="4"
                        :sm="8"
                        :xs="8"
                        v-if="!getIsMobile"
                      >
                        <div class="index-1">No</div>
                      </el-col>
                      <el-col
                        :lg="1"
                        :md="4"
                        :sm="8"
                        :xs="8"
                        v-if="!getIsMobile"
                      >
                        <div class="arrow" style="color: white">_</div>
                      </el-col>
                      <el-col :lg="3" :md="8" :sm="8" :xs="7">
                        <div class="index-1">Role</div>
                      </el-col>
                      <el-col :lg="4" :md="8" :sm="8" :xs="7">
                        <div class="index-1">Contact Type</div>
                      </el-col>
                      <el-col
                        :lg="4"
                        :md="4"
                        :sm="8"
                        :xs="8"
                        v-if="!getIsMobile"
                      >
                        <div class="index-1">Signer Type</div>
                      </el-col>
                      <el-col
                        :lg="10"
                        :md="8"
                        :sm="8"
                        :xs="10"
                        style="display: flex; justify-content: space-between"
                      >
                        &nbsp;
                      </el-col>
                    </el-row>
                  </div>
                  <draggable v-model="users">
                    <transition-group
                      tag="div"
                      name="flip-list"
                      id="field-group"
                    >
                      <div v-for="(user, index) of users" :key="user.name">
                        <div class="each-user" style="padding-bottom: 5px">
                          <el-row :gutter="15" class="each-user-row">
                            <el-col
                              :lg="2"
                              :md="4"
                              :sm="8"
                              :xs="8"
                              v-if="!getIsMobile"
                            >
                              <div class="priority">{{ index + 1 }}</div>
                            </el-col>
                            <el-col
                              :lg="1"
                              :md="4"
                              :sm="8"
                              :xs="8"
                              v-if="!getIsMobile"
                            >
                              <div>
                                <span class="el-icon-right grey-light-1"></span>
                              </div>
                            </el-col>
                            <el-col :lg="3" :md="8" :sm="8" :xs="7">
                              <div class="row-2">{{ user.name }}</div>
                            </el-col>
                            <el-col :lg="4" :md="8" :sm="8" :xs="3">
                              <div>
                                <span
                                  v-if="
                                    user.contact_type && user.contact_type.name
                                  "
                                  >{{ user.contact_type.name }}</span
                                >
                                <span
                                  v-else-if="user.type == 'SENDER'"
                                  style="color: white"
                                  >_</span
                                >
                                <span
                                  style="font-size: 12px"
                                  class="error"
                                  v-else
                                >
                                  Contact Type is Required
                                </span>
                              </div>
                            </el-col>
                            <el-col
                              :lg="4"
                              :md="4"
                              :sm="8"
                              :xs="8"
                              v-if="!getIsMobile"
                            >
                              <div>
                                {{ user.user_type }}
                                <span style="color: white">_</span>
                              </div>
                            </el-col>
                            <el-col
                              :lg="10"
                              :md="8"
                              :sm="8"
                              :xs="14"
                              style="
                                display: flex;
                                justify-content: space-between;
                              "
                            >
                              <div>
                                <el-checkbox
                                v-model="user.constant_user"
                                  :disabled="user.type == 'SENDER'"
                                  @change="checkContactsAdd(user, index)"
                                  
                                  >{{
                                    getIsMobile
                                      ? "Assign user"
                                      : "Assign recipient"
                                  }}</el-checkbox
                                >
                                <p
                                  v-if="
                                    user.constant_user &&
                                    user.first_name &&
                                    user.type != 'SENDER'
                                  "
                                  style="font-size: 10px"
                                >
                                  {{ user.first_name + " " + user.last_name }}
                                </p>
                              </div>
                              <div>
                                <el-button
                                  type="text"
                                  icon="el-icon-bottom"
                                  class="directions"
                                  @click="moveDown(index)"
                                  :disabled="selectedUser ? true : false"
                                >
                                </el-button>
                                <el-button
                                  type="text"
                                  icon="el-icon-top"
                                  class="directions"
                                  @click="moveUp(index)"
                                  :disabled="selectedUser ? true : false"
                                >
                                </el-button>
                              </div>
                              <div>
                                <el-button
                                  size="mini"
                                  circle
                                  plain
                                  class="btn-delete1"
                                  type="danger"
                                  icon="el-icon-edit"
                                  @click="editReceiver(user, index)"
                                ></el-button>
                              </div>
                              <div>
                                <el-button
                                  size="mini"
                                  circle
                                  plain
                                  class="btn-delete position-relatvie"
                                  type="danger"
                                  icon="el-icon-delete"
                                  v-if="user.type != 'SENDER'"
                                  @click="deleteReceiver(index)"
                                ></el-button>
                                <div
                                  style="color: white; width: 28px"
                                  v-if="user.type == 'SENDER'"
                                  class="btn-delete position-relatvie"
                                >
                                  _
                                </div>
                              </div>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </div>
              <el-divider class="mt-0"></el-divider>
              <el-checkbox v-model="enforceSignatureOrderValue"
                >Enforce Signature Order</el-checkbox
              >
              <el-checkbox
                v-model="isAnyoneCanApproveValue"
                v-if="checkHasMoreApprovers"
                :disabled="checkAllApproverPosition"
                >Any approver user can approve</el-checkbox
              >
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <el-form :model="newUser" :rules="newUserFormRules" ref="newUserForm">
            <el-row type="flex" align="bottom" justify="center" :gutter="15">
              <el-col :lg="{ span: 12 }" :sm="{ span: 12 }">
                <h4 class="fw-medium" v-if="selectedUser">Update Recipient</h4>
                <h4 class="fw-medium" v-else>Add New Recipient</h4>
              </el-col>
            </el-row>
            <el-row type="flex" align="bottom" justify="center" :gutter="15">
              <el-col :lg="{ span: 6 }" :sm="{ span: 12 }">
                <el-form-item
                  label="First Name"
                  class="mb-10"
                  prop="first_name"
                >
                  <el-input
                    v-model="newUser.first_name"
                    placeholder="First Name"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="{ span: 6 }" :sm="{ span: 12 }">
                <el-form-item label="Last Name" class="mb-10" prop="last_name">
                  <el-input v-model="newUser.last_name" placeholder="Last Name">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" align="bottom" justify="center" :gutter="15">
              <el-col :lg="{ span: 6 }" :sm="{ span: 12 }">
                <el-form-item label="Email" class="mb-10" prop="email">
                  <el-input
                    type="email"
                    v-model="newUser.email"
                    placeholder="Email Address"
                    :disabled="selectedUser && selectedUser.name == 'SENDER'"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="{ span: 6 }" :sm="{ span: 12 }">
                <el-form-item label="Phone Number" class="mb-10" prop="phone">
                  <el-input placeholder="Phone Number" v-model="newUser.phone">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" align="bottom" justify="center" :gutter="15">
              <el-col :lg="{ span: 12 }" :sm="{ span: 12 }">
                <el-form-item
                  label="Company Name"
                  class="mb-10"
                  prop="company_name"
                >
                  <el-input
                    placeholder="Company Name"
                    v-model="newUser.company_name"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" align="bottom" justify="center" :gutter="15">
              <el-col :lg="{ span: 12 }" :sm="{ span: 12 }">
                <el-form-item label="Address" class="mb-10" prop="address">
                  <el-input
                    type="textarea"
                    placeholder="Enter Address"
                    v-model="newUser.address"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex" align="bottom" justify="center" :gutter="15">
              <el-col :lg="{ span: 12 }" :sm="{ span: 12 }">
                <el-form-item class="mt-20 mb-20">
                  <el-button type="danger" plain @click="onCancel"
                    >Cancel</el-button
                  >
                  <el-button
                    type="danger"
                    @click="submitForm('newUserForm')"
                    v-if="selectedUser"
                    >Update User</el-button
                  >
                  <el-button
                    type="danger"
                    @click="submitForm('newUserForm')"
                    v-else
                    >Add Receiver</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer" v-if="!showNewUserForm">
          <el-button @click="closeSettings">Cancel</el-button>
          <el-button type="danger" @click="saveUsers" class="px-5"
            >Save Users</el-button
          >
        </div>
      </el-dialog>
    </div>
    <el-dialog
      title="Assign a recipient"
      :visible.sync="addConstantUserModel"
      class="outersize-confirm-type-three"
      :width="getIsMobile ? '100%' : ''"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="checkForUserSelected"
      v-loading="usersLoading" 
      element-loading-text="Loading Users..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      
      <div v-if="addConstantUserModel" >
        <el-input
          type="text"
          v-model="searchedContact"
          placeholder="Search by Email"
          class="mb-1"
          @input="searchContact"
          clearable
          ><i slot="prefix" class="el-input__icon el-icon-search"></i
        ></el-input>
        <el-scrollbar
          wrap-style="max-height: 300px;"
          v-if="
            !searchedContact &&
            addConstantUsetContacts &&
            addConstantUsetContacts.length
          "
        >
          <div
            v-for="(user, index) in addConstantUsetContacts"
            :key="index"
            style="width: 100%"
          >
            <el-radio-group v-model="selectedConstantUser">
              <el-radio
                :label="user.email"
                border
                style="width: 370px"
                :disabled="(users || []).findIndex(e => e.email == user.email) != -1"
              ></el-radio>
            </el-radio-group>
          </div>
        </el-scrollbar>
        <el-scrollbar
          wrap-style="max-height: 300px;"
          v-else-if="
            searchedContact &&
            searchedConstantUserContacts &&
            searchedConstantUserContacts.length
          "
        >
          <div
            v-for="(user, index) in searchedConstantUserContacts"
            :key="index"
            style="width: 100%"
          >
            <el-radio-group v-model="selectedConstantUser">
              <el-radio
                :label="user.email"
                border
                style="width: 370px"
                :disabled="(users || []).findIndex(e => e.email == user.email) != -1"
              ></el-radio>
            </el-radio-group>
          </div>
        </el-scrollbar>
        <div style="text-align: center" v-else>
          <p
            v-if="
              !searchedConstantUserContacts.length &&
              addConstantUsetContacts.length
            "
          >
            No results found
          </p>
          <p v-if="!addConstantUsetContacts.length">
            No contacts found for this contact type
            <b v-if="selected_contactType && selected_contactType.name">{{
              selected_contactType.name
            }}</b>
          </p>
        </div>
        <!-- <div style="margin-top: 6px">
          <el-button
            class="el-button--primary is-plain"
            @click="goToCreateContact"
          >
            <i class="el-icon-circle-plus-outline"></i>
            Create Contact
          </el-button>
        </div> -->
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkForUserSelected">Cancel</el-button>
        <el-button
          type="danger"
          :disabled="!selectedConstantUser"
          @click="addConstantUserMethod"
          >Save User</el-button
        >
      </div>
    </el-dialog>
    <AddContactTypes
      v-on:newContactTypeAdded="contactTypeDialogVisibible = false"
      v-if="contactTypeDialogVisibible"
    ></AddContactTypes>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import UserHelper from "@/mixins/UserHelper";
import AddContactTypes from "@/components/contacts/AddContactTypes";
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
export default {
  name: "configureDocuments-ConfigureDocumentReceipents",
  props: [
    "documentUsers",
    "enforceSignatureOrder",
    "isSubmit",
    "allElements",
    "isAnyoneCanApprove",
  ],
  components: {
    draggable,
    AddContactTypes,
  },
  mixins: [UserHelper, DocumentsHelper],
  data() {
    var validateEmail = (rule, value, callback) => {
      var emailReg =
        /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      let emails = this.getAllContacts.data.map((user) => user.email);
      if (value === "") {
        callback(new Error("Email is Required"));
      } else if (!emailReg.test(value)) {
        callback(new Error("Email is not valid"));
      } else if (emails.indexOf(value) != -1) {
        callback(new Error("Email already existed"));
      } else {
        callback();
      }
    };
    return {
      selected_contactType: "",
      addContactTypeLoading: false,
      showAddGroupDialog: false,
      contact_type: "",
      contactTypeDialogVisibible: false,
      show: true,
      user: null,
      showNewUserForm: false,
      users: [
        {
          email: "",
          first_name: "",
          last_name: "",
          e_signature_required: false,
          value: "SENDER",
          name: "SENDER",
          default: true,
          is_cc: false,
          phone: "",
          address: "",
          company_name: "",
          e_signature_order: 0,
          user_type: "SIGNER",
          contact_type: "",
          contact: "",
        },
      ],
      usersLoading:false,
      newUser: {
        email: "",
        first_name: "",
        e_signature_required: "",
        last_name: "",
        phone: "",
        address: "",
        company_name: "",
        is_cc: false,
        user_type: "SIGNER",
        contact_type: "",
        contact: "",
      },
      customeUsers: [],
      refreshSignatureOrder: false,
      enforceSignatureOrderValue: true,
      selectedUser: null,
      isAnyoneCanApproveValue: false,
      selectedUserIndex: null,
      selectRecipient: {
        email: "",
        user_type: "SIGNER",
        password_protected: true,
        name: "",
        contact_type: "",
      },
      docUsersLoading: false,
      newUserFormRules: {
        first_name: [
          {
            required: true,
            message: "First name is required",
            trigger: "blur",
          },
        ],
        last_name: [
          { required: true, message: "Last name is required", trigger: "blur" },
        ],
        company_name: [
          { required: true, message: "Company is required", trigger: "blur" },
        ],
        Phone: [
          { required: true, message: "Phone is required", trigger: "blur" },
        ],
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
      },
      //constant user
      addConstantUserModel: false,
      addConstantUsetContacts: [],
      searchedConstantUserContacts: [],
      selectedConstantUser: "",
      searchedContact: "",
      selectedDocumentUser: null,
      selectedDocumentUserIndex: -1,
      is_Submit: false,
      elements: [],
    };
  },

  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveWorkspace"]),
    ...mapGetters("contacts", ["getAllContacts"]),
    ...mapGetters("contactTypes", [
      "getAllContactTypes",
      "getContactTypeCreateStatus",
    ]),
    ...mapGetters("configurableDocuments", [
      "getDocumentUsers",
      "getConfigurableDocumentUpdateStatus",
      "getConfigurableDocumentDataById",
    ]),

    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("company", ["getCompanyDetails"]),
    fetchSignUsers() {
      return this.allElements.flatMap(
        (element) => element.filled_by || element.selected_user
      );
    },
    existedSignatureDate() {
      return (dateField) =>
        this.signaturesConfig.find(
          (user) => user.e_signature_date_field == dateField.key
        )
          ? true
          : false;
    },
    checkAddReceiverDisable() {
      if (
        !this.newUser.email ||
        !this.newUser.first_name ||
        !this.newUser.last_name
      ) {
        return true;
      }
      return false;
    },
    hasDeleteAccess() {
      return (user) => {
        if (!user.default) {
          let element = this.allElements.find(
            (element) =>
              element.selected_user == user.value ||
              element.filled_by == user.value
          );
          if (element) {
            return false;
          } else {
            return true;
          }
        }
        return false;
      };
    },
    checkReceipentUpdate() {
      if (
        this.selectedUser.contact_type != "" &&
        this.selectedUser.user_type != "" &&
        this.selectedUser.name != ""
      ) {
        return false;
      } else if (
        this.selectedUser.type == "SENDER" &&
        this.selectedUser.user_type
      ) {
        return false;
      }
      return true;
    },
    fetchHasFieldsUses() {
      return this.allElements.flatMap((element) => element.filled_by);
    },
    checkAllApproverPosition() {
      if (!this.enforceSignatureOrderValue) {
        return false;
      }
      let approvalUsers = [];
      this.users.forEach((user, index) => {
        if (user.user_type == "APPROVER") {
          approvalUsers.push(index);
        }
      });
      if (approvalUsers && approvalUsers.length) {
        approvalUsers = approvalUsers.sort((a, b) => a - b);
        let checkApproversPosistion = false;
        for (let i = 0; i < approvalUsers.length - 1; i++) {
          if (approvalUsers.indexOf(approvalUsers[i] + 1) == -1) {
            if (this.isAnyoneCanApproveValue) {
              this.$message.warning(
                "If enforce signature is on all approvers must be together then only anyone can approve will be available"
              );
              this.changeAnyonecanApprove();
              // this.isAnyoneCanApproveValue = false;
            }
            checkApproversPosistion = true;
          }
        }
        return checkApproversPosistion;
      }
      return false;
    },
    checkHasMoreApprovers() {
      let approvalUsers = this.users.filter(
        (user) => user.user_type == "APPROVER"
      );
      if (approvalUsers && approvalUsers.length && approvalUsers.length > 1) {
        return true;
      }
      return false;
    },
    fetchCurrentDocumentUsers() {
      console.log("aaaaaaaaaaaaaaaaaaaaaaa", this.users);
      return this.users.map((user) => user.email);
    },
  },

  async mounted() {
    await this.mountedData();
    if (this.allElements && this.allElements.length) {
      this.elements = this.allElements;
    }
  },
  methods: {
    changeAnyonecanApprove() {
      this.isAnyoneCanApproveValue = false;
    },
    goToCreateContact() {
      if (this.selected_contactType && this.selected_contactType._id) {
        this.$router.push({
          name: "add-contact",
          params: { selectedContact_typeId: this.selected_contactType._id },
        });
      }
    },
    checkForUserSelected() {
      if (!this.users[this.selectedDocumentUserIndex]["email"]) {
        this.users[this.selectedDocumentUserIndex]["constant_user"] = false;
        this.addConstantUserModel = false;
        this.selectedDocumentUser = null;
        this.selectedDocumentUserIndex = -1;
      } else {
        this.addConstantUserModel = false;
        this.selectedDocumentUser = null;
        this.selectedDocumentUserIndex = -1;
      }
      this.addConstantUsetContacts = [];
      this.selectedConstantUser = [];
      this.searchedConstantUserContacts = [];
    },
    addConstantUserMethod() {
      let contact = this.getAllContacts.data.find(
        (user) => user && user.email && user.email == this.selectedConstantUser
      );
      this.selectedDocumentUser.first_name = contact.first_name;
      this.selectedDocumentUser.last_name = contact.last_name;
      this.selectedDocumentUser.email = contact.email;
      this.selectedDocumentUser.contact = contact;
      this.selectedDocumentUser.contact_id = contact._id;
      if (contact.address) {
        this.selectedDocumentUser.address = contact.address;
      }
      if (contact.phone_number) {
        this.selectedDocumentUser.phone = contact.phone_number;
      }
      if (contact.title) {
        this.selectedDocumentUser.title = contact.title;
      }
      if (contact.company_name) {
        this.selectedDocumentUser.company_name = contact.company_name;
      }
      let selectedContactTypes = this.getContactTypesFromContact(contact);
      let id =
        this.users[this.selectedDocumentUserIndex].contact_type &&
        this.users[this.selectedDocumentUserIndex].contact_type._id
          ? this.users[this.selectedDocumentUserIndex].contact_type._id
          : this.users[this.selectedDocumentUserIndex].contact_type;
      let selectedContactTypeData = selectedContactTypes.find(
        (e) => e.contact_type == id
      );
      console.log("selectedContactTypeData", selectedContactTypeData);
      if (selectedContactTypeData?.account_data_id) {
        this.selectedDocumentUser.entity_data_id = selectedContactTypeData
          .account_data_id._id
          ? selectedContactTypeData.account_data_id._id
          : selectedContactTypeData.account_data_id;
      }
      this.users[this.selectedDocumentUserIndex] = {
        ...this.selectedDocumentUser,
      };
      this.checkForUserSelected();
      this.addConstantUserModel = false;
    },
    searchContact() {
      if (this.searchedContact) {
        this.searchedConstantUserContacts = this.addConstantUsetContacts.filter(
          (el) => {
            return el.email.includes(`${this.searchedContact}`);
          }
        );
      }
    },
    async checkContactsAdd(user, index) {
      
      this.usersLoading = true
      this.selectedDocumentUser = user;
      if (user.constant_user) {
        this.selectedConstantUser =
          this.selectedDocumentUser && this.selectedDocumentUser.email
            ? this.selectedDocumentUser.email
            : "";
        this.selectedDocumentUserIndex = index;
        let id = this.selectedDocumentUser["contact_type"]["_id"]
          ? this.selectedDocumentUser["contact_type"]["_id"]
          : this.selectedDocumentUser["contact_type"];
        await this.$store.dispatch("contacts/fetchAllContacts", {
          get_all: true,
          contact_type: id,type:'CONTACT'
        });
        this.usersLoading = false
        this.getAllContacts && this.getAllContacts.data && this.getAllContacts.data.forEach((el) => {
          if (el && el.email) {
            this.addConstantUsetContacts.push(el);
          }
        });
        this.usersLoading = false;
        this.addConstantUserModel = true;
      } else {
        this.users[index]["first_name"] = "";
        this.users[index]["last_name"] = "";
        this.users[index]["email"] = "";
        //]this.users[index]["full_name"] = '';
        delete this.users[index]["contact"];
        delete this.users[index]["contact_id"];
        this.users[index]["address"] = "";
        this.users[index]["company_name"] = "";
        this.users[index]["phone"] = "";
        this.users[index]["title"] = "";
        if (this.users[index].entity_data_id) {
          delete this.users[index]["entity_data_id"];
        }
      }
      this.selected_contactType = this.selectedDocumentUser["contact_type"];
    },
    async mountedData() {
      try {
        this.docUsersLoading = true;
        this.fetchContacts();
        await this.fetchContactTypes();
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigDocumentUsers",
          {
            id: this.$route.params.configurable_document_id,
          }
        );
        this.users = [];
        let users;
        if (this.getDocumentUsers && this.getDocumentUsers.document_users) {
          users = [];
          users = [...this.getDocumentUsers.document_users];
          this.enforceSignatureOrderValue =
            this.getDocumentUsers.enforce_signature_order;
          this.isAnyoneCanApproveValue =
            this.getDocumentUsers.is_anyone_can_approve;
          if (!users.length) {
            let companyName = "";
            if (
              this.getActiveWorkspace.company_id &&
              this.getActiveWorkspace.plan_type != "INDIVIDUAL" &&
              this.getActiveWorkspace.company_id != "0"
            ) {
              companyName =
                this.getCompanyDetails &&
                this.getCompanyDetails.name &&
                this.getCompanyDetails.legal_name != ""
                  ? this.getCompanyDetails.legal_name
                  : this.getCompanyDetails && this.getCompanyDetails.name
                  ? this.getCompanyDetails.name
                  : "";
            } else {
              companyName = this.getAuthenticatedUser.company_name;
            }
            users.push({
              default: true,
              email: this.getAuthenticatedUser.email,
              first_name: this.getAuthenticatedUser.first_name,
              last_name: this.getAuthenticatedUser.last_name,
              address:
                this.getActiveWorkspace &&
                this.getActiveWorkspace.company_id != "0" &&
                this.getActiveWorkspace.plan_type != "INDIVIDUAL"
                  ? this.getCompanyDetails && this.getCompanyDetails.address
                    ? this.getCompanyDetails.address
                    : ""
                  : this.getAuthenticatedUser.address,
              phone: this.getAuthenticatedUser.phone,
              company_name: companyName,
              title: this.getAuthenticatedUser.title,
              e_signature_required: true,
              value: "SENDER",
              name: "SENDER",
              type: "SENDER",
              e_signature_order: 0,
              e_signature_fields: [],
              e_signature_date_fields: [],
              has_approval_access: false,
              is_cc: false,
              user_type: "SIGNER",
              color: "#F754A2",
            });
            let params = {
              document_users: users,
              id: this.$route.params.configurable_document_id,
            };
            await this.$store.dispatch(
              "configurableDocuments/updateConfigurableDocument",
              params
            );
            await this.$store.dispatch(
              "configurableDocuments/fetchConfigurableDocumentById",
              {
                id: this.$route.params.configurable_document_id,
              }
            );
          }
        } else {
          users = [...this.documentUsers];
        }
        this.users = users.map((user) => {
          if (user.type == "SENDER") {
            let companyName = "";
            if (
              this.getActiveWorkspace.plan_type != "INDIVIDUAL" &&
              this.getActiveWorkspace.company_id &&
              this.getActiveWorkspace.company_id != "0"
            ) {
              companyName =
                this.getCompanyDetails &&
                this.getCompanyDetails.name &&
                this.getCompanyDetails.legal_name &&
                this.getCompanyDetails.legal_name != ""
                  ? this.getCompanyDetails.legal_name
                  : this.getCompanyDetails.name &&
                    this.getCompanyDetails.name != ""
                  ? this.getCompanyDetails.name
                  : "";
            } else {
              companyName = this.getAuthenticatedUser.company_name;
            }
            user.email = this.getAuthenticatedUser.email;
            user.first_name = this.getAuthenticatedUser.first_name;
            user.last_name = this.getAuthenticatedUser.last_name;
            user.company_name = companyName;
            user.phone = this.getAuthenticatedUser.phone;
            (user.address =
              this.getActiveWorkspace &&
              this.getActiveWorkspace.company_id != "0" &&
              this.getActiveWorkspace.plan_type != "INDIVIDUAL"
                ? this.getCompanyDetails && this.getCompanyDetails.address
                  ? this.getCompanyDetails.address
                  : this.getAuthenticatedUser.address
                : this.getAuthenticatedUser.address),
              (user.constant_user = true);
          } else if (!user.constant_user) {
            if (user.first_name) {
              user.first_name = "";
            }
            if (user.last_name) {
              user.last_name = "";
            }
            if (user.email) {
              user.email = "";
            }
            if (user.contact) {
              delete user.contact;
            }
            if (user.entity_data_id) {
              delete user.entity_data_id;
            }
            if (user.address) {
              user.address = "";
            }
            if (user.phone) {
              user.phone = "";
            }
            if (user.company_name) {
              user.company_name = "";
            }
          }

          if (!user.user_type) {
            user.user_type = "SIGNER";
          }
          if (user.contact_type && this.getAllContactTypes) {
            let typeData = this.getAllContactTypes.filter(
              (type) => type._id == user.contact_type
            );
            if (typeData && typeData.length) {
              user.contact_type = typeData[0];
            }
          }
          if (this.getAllContactTypes && this.getAllContactTypes.length == 1) {
            this.selectRecipient.contact_type = this.getAllContactTypes[0].name;
            let oldUsers = this.users.filter((user) => {
              if (user.name.split("-")[0] == this.getAllContactTypes[0].name) {
                return user;
              }
            });
            if (oldUsers.length) {
              this.selectRecipient.name =
                this.getAllContactTypes[0].name + "-" + (oldUsers.length + 1);
            } else {
              this.selectRecipient.name =
                this.getAllContactTypes[0].name + "-1";
            }
          }

          if (user.type != "SENDER" && !user.user_type) {
            user.user_type = "SIGNER";
          }
          return user;
        });
        this.isAnyoneCanApproveValue = this.isAnyoneCanApprove;
        this.enforceSignatureOrderValue =
          this.getDocumentUsers.enforce_signature_order;
        this.docUsersLoading = false;
      } catch (err) {
        this.docUsersLoading = false;
        console.log(err);
      }
    },
    async fetchContacts() {
      try {
        await this.$store.dispatch("contacts/fetchAllContacts", {
          get_all: true,type:'CONTACT'
        });
      } catch (err) {
        console.log(err);
      }
    },
    async fetchContactTypes() {
      try {
        await this.$store.dispatch("contactTypes/fetchAllContactTypes", {
          get_all: true,
          from_contacts: true,
          type:'CONTACT_TYPE'
        });
      } catch (err) {
        console.log(err);
      }
    },
    checkEntityFields() {
      const userFields = this.elements.filter(
        (e) => e.entity_id && e.selected_user == this.selectedUser.value
      );
      if (userFields && userFields.length) {
        this.$confirm(
          `Entity fields are used. If you change the contact type related entity fields will be changed to custom fields?`,
          "Warning",
          {
            confirmButtonText: "Continue",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.resetElements(this.selectedUser.value);
            this.onChangeContactType();
          })
          .catch(() => {
            this.selectedUser.contact_type =
              this.users[this.selectedUserIndex] &&
              this.users[this.selectedUserIndex].contact_type &&
              this.users[this.selectedUserIndex].contact_type.name
                ? this.users[this.selectedUserIndex].contact_type.name
                : this.selectedUser.contact_type;
            return;
          });
      } else {
        this.onChangeContactType();
      }
    },
    async onChangeContactType() {
      console.log(this.selectRecipient, this.selectedUser);
      let name = "";
      let selectedContactType = "";
      if (this.selectedUser) {
        name = this.selectedUser.name;
        selectedContactType = this.selectedUser.contact_type;
      } else {
        name = this.selectRecipient.name;
        selectedContactType = this.selectRecipient.contact_type;
      }

      let contactType = this.getAllContactTypes.filter(
        (contact) => contact && contact.name == selectedContactType
      );
      if (!contactType || !contactType.length) {
        this.docUsersLoading = true;
        await this.$store.dispatch("contactTypes/createContactType", {
          name: selectedContactType,
        });
        await this.$store.dispatch("contactTypes/fetchAllContactTypes", {
          get_all: true,
          from_contacts: true,
          type:'CONTACT_TYPE'
        });
        this.docUsersLoading = false;
        contactType = this.getAllContactTypes.filter(
          (contact) => contact && contact.name == selectedContactType
        );
      }

      // if (!name) {
      let namesData = this.users.filter(
        (user) => user && user.name.split("-")[0] == selectedContactType
      );
      let maxLength = 1;
      if (namesData && namesData.length) {
        namesData.forEach((user) => {
          if (
            user.name &&
            user.name.split("-")[1] &&
            parseInt(user.name.split("-")[1]) >= maxLength
          ) {
            maxLength = parseInt(user.name.split("-")[1]) + 1;
          }
        });
      }
      name = contactType[0].name + "-" + maxLength;
      if (this.selectedUser) {
        this.selectedUser.name = name;
      } else {
        this.selectRecipient.name = name;
      }
      // }
    },
    resetElements(value) {
      this.elements = this.elements.map((e) => {
        if (
          e.selected_user == value &&
          e.field_type == "ENTITY_FIELD" &&
          e.template_id
        ) {
          e.selected_user = "";
          e.field_type = "CUSTOM_FIELD";
          delete e.template_id;
          delete e.entity_id;
          delete e.indexKey;
        }
        return e;
      });
    },
    deleteElements(value) {
      console.log("user value", value, this.getConfigurableDocumentDataById);
      this.elements = this.elements.filter((e) => {
        if (e.selected_user != value && e.filled_by != value) {
          return e;
        }
      });
      if (
        this.getConfigurableDocumentDataById &&
        this.getConfigurableDocumentDataById.pages &&
        this.getConfigurableDocumentDataById.pages[0] &&
        this.getConfigurableDocumentDataById.pages[0].fields &&
        this.getConfigurableDocumentDataById.pages[0].fields.length
      ) {
        this.getConfigurableDocumentDataById.pages[0].fields = this.elements;
      }
    },
    async erase_contactType() {
      this.contact_type = "";
    },
    async addContactType(index) {
      try {
        this.addContactTypeLoading = true;
        await this.$store.dispatch("contactTypes/fetchAllContactTypes", {
          get_all: true,
          from_contacts: true,
          type:'CONTACT_TYPE'
        });
        if (this.getAllContactTypes) {
          let exst_type = this.getAllContactTypes.find((el) => {
            if (
              el &&
              el.name &&
              this.contact_type &&
              el.name.toLowerCase() == this.contact_type.toLowerCase()
            ) {
              return el;
            }
          });
          if (exst_type) {
            this.$notify.error({
              title: "Error",
              message: "Contact Type with this name is already exist!",
            });
            this.addContactTypeLoading = false;
            return;
          }
        }
        // console.log(" CHEKING UP");
        await this.$store.dispatch("contactTypes/createContactType", {
          name: this.contact_type,
        });
        // console.log("checking down")
        // this.users[index].contact_type = this.contact_type;
        if (this.getContactTypeCreateStatus) {
          if (this.selectedUser) {
            this.addContactTypeLoading = false;
            this.showAddGroupDialog = false;
            this.successNotify("Contact type created Successfully");
            this.selectedUser.contact_type = this.contact_type;
            this.onChangeContactType(index);
            this.$emit("newContactTypeAdded", true);
          } else {
            this.addContactTypeLoading = false;
            this.showAddGroupDialog = false;
            this.successNotify("Contact type created Successfully");
            this.selectRecipient.contact_type = this.contact_type;
            this.onChangeContactType(index);
            this.onChangeContactType(index);
            this.$emit("newContactTypeAdded", true);
          }
        } else {
          this.$notify.error({
            title: "Error",
            message: "Contact Type with this name is already exist!",
          });
          this.addContactTypeLoading = false;
        }
        await this.$store.dispatch("contactTypes/fetchAllContactTypes", {
          get_all: true,
          from_contacts: true,
          type:'CONTACT_TYPE'
        });
        //this.users[index].contact_type = this.getAllContactTypes[0]._id;
        this.showAddGroupDialog = false;
        this.modalLoading = false;
        // this.loading = false;
        this.contact_type = "";
      } catch (err) {
        this.modalLoading = false;
        console.log(err);
      }
    },
    onCancel() {
      this.showNewUserForm = false;
      this.selectedUser = null;
      this.selectRecipient.email = "";
      this.resetSelectedReceiver();
      this.resetNewUserData();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.selectedUser) {
            this.updateReceipentUser();
          } else {
            this.addReceiver();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addReceiver() {
      try {
        let checkRoleName = this.checkRoleDuplicate(
          this.selectRecipient && this.selectRecipient.name
            ? this.selectRecipient.name
            : ""
        );
        if (!checkRoleName) {
          this.$message.warning("Role should be unique.");
          return;
        }
        let exst = this.users
          .filter((user) => user.color)
          .map((user) => user.color);
        let userColor = this.getRandomColor(exst);
        let contactType = "";
        if (this.selectRecipient.contact_type) {
          contactType = this.getAllContactTypes.filter(
            (contact) => contact.name == this.selectRecipient.contact_type
          );
          if (contactType && contactType.length) {
            contactType = contactType[0];
          } else {
            contactType = "";
          }
        }
        let maxValue = this.users.length;
        this.users.forEach((user) => {
          if (
            user.value &&
            user.value.split("_")[1] &&
            parseInt(user.value.split("_")[1]) >= maxValue
          ) {
            maxValue = parseInt(user.value.split("_")[1]) + 1;
          }
        });
        this.users.push({
          default: false,
          name: this.selectRecipient.name || "RECEIVER - " + maxValue,
          value: "RECEIVER_" + maxValue,
          type: "RECEIVER",
          e_signature_fields: [],
          e_signature_date_fields: [],
          e_signature_order: maxValue,
          e_signature_required: false,
          has_approval_access: false,
          color: userColor,
          email: "",
          first_name: "",
          last_name: "",
          phone: "",
          address: "",
          company_name: "",
          user_type: this.selectRecipient.user_type || "SIGNER",
          is_cc: false,
          contact_type: contactType,
          constant_user: false,
        });
        this.resetNewUserData();
        if (this.getAllContactTypes.length == 1) {
          let namesData = this.users.filter(
            (user) =>
              user && user.name.split("-")[0] == this.getAllContactTypes[0].name
          );
          this.selectRecipient.contact_type = this.getAllContactTypes[0].name;
          this.selectRecipient.name =
            this.getAllContactTypes[0].name + "-" + (namesData.length + 1);
        }
        this.showNewUserForm = false;
      } catch (err) {
        console.log(err);
      }
    },
    resetNewUserData() {
      this.newUser.email = "";
      this.newUser.first_name = "";
      this.newUser.last_name = "";
      this.newUser.phone = "";
      this.newUser.address = "";
      this.newUser.company_name = "";
      this.newUser.contact_type = "";
      this.newUser.user_type = "";
      this.selectRecipient.name = "";
      this.selectRecipient.user_type = "SIGNER";
      this.selectRecipient.contact_type = "";
    },
    editReceiver(user, index) {
      this.selectedUser = { ...user };
      console.log(this.selectedUser);
      this.selectedUser.contact_type =
        this.selectedUser.contact_type && this.selectedUser.contact_type.name
          ? this.selectedUser.contact_type.name
          : this.selectedUser.contact_type;
      this.selectedUserIndex = index;
    },
    updateReceipentUser() {
      let checkRoleName = this.checkRoleDuplicate(
        this.selectedUser && this.selectedUser.name
          ? this.selectedUser.name
          : "",
        this.selectedUserIndex
      );
      if (!checkRoleName) {
        this.$message.warning("Role should be unique.");
        return;
      }
      let contactType = "";
      if (this.selectedUser.contact_type) {
        contactType = this.getAllContactTypes.filter(
          (contact) => contact.name == this.selectedUser.contact_type
        );
        if (contactType && contactType.length) {
          contactType = contactType[0];
        } else {
          contactType = "";
        }
      }
      this.users[this.selectedUserIndex].name = this.selectedUser.name;
      this.users[this.selectedUserIndex].contact_type = contactType;
      this.users[this.selectedUserIndex].user_type =
        this.selectedUser.user_type;
      this.resetSelectedReceiver();
    },
    resetSelectedReceiver() {
      this.selectedUser = null;
      this.selectedUserIndex = null;
      this.showNewUserForm = false;
    },
    moveDown(index) {
      if (index == this.users.length - 1) {
        return;
      }
      let sortedUsers = this.swapSingatureUsers(index, index + 1, this.users);
      this.users = [];
      this.users = sortedUsers;
    },
    moveUp(index) {
      if (index == 0) {
        return;
      }
      let sortedUsers = this.swapSingatureUsers(index, index - 1, this.users);
      this.users = [];
      this.users = sortedUsers;
    },
    swapSingatureUsers(fromIndex, toIndex, signaturedUsers) {
      let fromUser = signaturedUsers[fromIndex];
      let toUser = signaturedUsers[toIndex];
      signaturedUsers[fromIndex] = toUser;
      signaturedUsers[toIndex] = fromUser;
      return signaturedUsers;
    },
    closeSettings() {
      this.$emit("close", {});
    },
    checkUsersData() {
      let errorContact = this.users.find((el) => {
        return !el.contact_type && el.type != "SENDER";
      });
      let approvers = 0;
      let signers = 0;
      this.users.forEach((user) => {
        if (user && user.user_type == "APPROVER") {
          approvers++;
        }
        if (user && user.user_type == "SIGNER") {
          signers++;
        }
      });
      if (errorContact || (approvers == 0 && signers == 0)) {
        if (approvers == 0 && signers == 0) {
          this.$message({
            message: "Warning, Atleast one signer or one approver must be add.",
            type: "warning",
          });
        }
        this.is_Submit = true;
        return false;
      }
      return true;
    },
    prepareDocumentUsers(users) {
      let signUsers = this.allElements
        .filter((element) => element.type == "SIGNATURE")
        .flatMap((element) => element.filled_by);
      let requiredUsers = this.allElements.flatMap((el) => el.filled_by);
      return users.map((user, index) => {
        user.e_signature_order = index;
        if (signUsers.indexOf(user.value) != -1) {
          user.e_signature_required = true;
        } else {
          user.e_signature_required = false;
        }
        if (requiredUsers.indexOf(user.value) != -1) {
          user.fields_required = true;
        } else {
          user.fields_required = false;
        }
        if (user && user.user_type == "APPROVER") {
          user.has_approval_access = true;
        }
        if (user && user.user_type == "CC") {
          user.is_cc = true;
        }
        if (user && user.user_type != "APPROVER" && user.user_type != "CC") {
          user.has_approval_access = false;
          user.is_cc = false;
        } else {
          user.is_cc = false;
        }
        if (user && user.type == "SENDER") {
          delete user.contact_type;
        }
        return user;
      });
    },
    async updateConfigDocumentUsers(params) {
      await this.$store.dispatch(
        "configurableDocuments/updateConfigurableDocument",
        params
      );
      if (this.getConfigurableDocumentUpdateStatus) {
        this.$notify.success({
          title: "Success",
          message: "Recipients saved successfully",
        });
        this.$emit("close", { elements: this.elements });
        console.log("this.elements", this.elements);
        this.docUsersLoading = false;
      } else {
        this.$notify.success({
          title: "Error",
          message: "Error in adding recipients",
        });
        this.docUsersLoading = false;
      }
    },
    saveUsers() {
      let checkusers = this.checkUsersData();
      if (checkusers) {
        this.docUsersLoading = true;
        let configuredUsers = this.prepareDocumentUsers(this.users);
        let params = {
          document_users: configuredUsers,
          enforce_signature_order: this.enforceSignatureOrderValue,
          is_anyone_can_approve: this.isAnyoneCanApproveValue,
          id: this.$route.params.configurable_document_id,
          pages: this.getConfigurableDocumentDataById.pages,
        };
        this.updateConfigDocumentUsers(params);
        console.log(
          "params",
          params,
          this.getConfigurableDocumentDataById.pages
        );
      }
    },
    deleteReceiver(index) {
      if (confirm) {
        this.$confirm(
          "All fields associated with this recipient will be deleted. Are you sure you want to delete the recipient? ",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }
        ).then(async () => {
          this.deleteElements(this.users[index].value);
          await this.users.splice(index, 1);
          this.$notify.success({
            title: "Success",
            message: "Recipient Deleted Successfully",
          });
        });
      }
    },

    getEignaturePriority(userName) {
      let index = this.users.findIndex((user) => user.value == userName);
      if (index > -1) {
        return index + 1;
      } else {
        return 0;
      }
    },
    handleApprovalUsers(approvedUser) {
      this.users = this.users.map((user) => {
        if (
          user.name != approvedUser.name &&
          user.has_approval_access == true
        ) {
          user.has_approval_access = false;
        }
        return user;
      });
    },
    async addNewDocumentUser() {
      try {
        let newUserDetails = this.getAllContacts.data.filter(
          (user) => user.email === this.selectRecipient.email
        );
        if (newUserDetails && newUserDetails.length) {
          this.newUser.email = newUserDetails[0].email;
          this.newUser.first_name = newUserDetails[0].first_name;
          this.newUser.last_name = newUserDetails[0].last_name;
          this.newUser.phone = newUserDetails[0].phone_number || "";
          this.newUser.address = newUserDetails[0].address || "";
          this.newUser.company_name = newUserDetails[0].company_name || "";
          if (this.selectedUser) {
            await this.updateReceipentUser();
          } else {
            await this.addReceiver();
          }
          this.selectRecipient.email = "";
          this.selectRecipient.name = "";
          this.selectRecipient.user_type = "";
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("contacts/setAllContacts", null, { root: true });
    this.$store.commit("contactTypes/setAllContactTypes", null, { root: true });
    this.$store.commit("contactTypes/setContactTypeCreateStatus", null, {
      root: true,
    });
    this.$store.commit(
      "configurableDocuments/setConfigurableDocumentUpdateStatus",
      null,
      {
        root: true,
      }
    );
  },
};
</script>
<style lang="scss">
.outersize-confirm-type-three {
  .el-dialog__body {
    scrollbar-width: none;
  }
}
.add-receipents {
  &.document-users-order {
    .users-header {
      display: grid;
      padding: 10px 0px 0px 10px;
      grid-template-columns: 20px 0px 150px 150px 150px 80px 70px;
      align-items: center;
      grid-gap: 10px;
      justify-content: center;
      & > div {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }
    }

    .field-group {
      max-height: 400px;
      overflow: scroll;
    }
    .each-user {
      position: relative;
      border: 1px dashed #dddddd;
      padding: 10px 0px 0px 10px;
      display: grid;
      grid-template-columns: 20px 20px 150px 150px 150px 80px 70px;
      align-items: center;
      gap: 15px;
      margin: 1em 0;
      text-align: left;
      justify-content: left;
      .btn-delete {
        position: absolute;
        top: 13px;
        right: 13px;
      }
    }

    .priority {
      font-size: 1.1em;
      padding: 5px;
      margin-right: 5px;
    }
    .directions {
      padding: 5px;
      cursor: pointer;
    }
    .flip-list-move {
      transition: transform 1s;
    }

    .select-recipients {
      margin-bottom: 1.5em;

      .new-contact-btn {
        position: absolute;
        right: 0;
      }
    }
  }
  .users-list {
    #field-group {
      overflow: scroll;
      height: calc(100vh - 450px);
      scrollbar-width: none;
    }
  }
}
.error {
  color: red;
}
</style>
