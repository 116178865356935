<template>
	<section
		class="configure-company-document"
		id="drag-template-background"
		v-loading="documentDataLoading"
	>
		<div class="inner-navbar" v-if="documentFormData">
			<el-row type="flex" align="middle" justify="center">
				<el-col :span="7">
					<el-button round icon="el-icon-back" @click="goToDocuments()"
						>Back to Documents</el-button
					>
				</el-col>
				<el-col :span="10">
					<h2 class="font-weight-normal blue-2">{{ documentFormData.name }}</h2>
				</el-col>
				<el-col :span="6">
					<div class="float-right">
						<el-button class="btn" type="success" @click="saveDocumentData()">
							<i class="el-icon-s-claim"></i> SAVE &amp; UPDATE
						</el-button>
					</div>
				</el-col>
			</el-row>
		</div>

		<div class="input-options">
			<el-row type="flex" justify="center">
				<el-col :span="17">
					<div v-loading="documentDataLoading">
						<div class="form-builder" ref="formBuilder">
							<div class="form-holder">
								<!-- <div v-if="!documentDataLoading" ref="docementData" v-html="documentBody"></div> -->
								<component
									ref="customDocument"
									v-if="!documentDataLoading"
									:is="dynamicComponent"
								></component>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="form-fields-set">
						<el-row>
							<h3 class="font-weight-normal text-center blue mb-05">
								Document Details
							</h3>
							<el-form v-model="userDocumentFormData">
								<el-form-item label="Document Valid From">
									<el-date-picker
										type="date"
										v-model="userDocumentFormData.valid_from"
									></el-date-picker>
								</el-form-item>

								<el-form-item label="Document Expires">
									<el-date-picker
										type="date"
										v-model="userDocumentFormData.expires_at"
									></el-date-picker>
								</el-form-item>

								<el-divider class="mb-05"></el-divider>

								<el-form-item label="E-signature Required ?" class="mb-0">
									<el-radio-group
										v-model="userDocumentFormData.e_signature_value"
										@change="updateEsignValue"
									>
										<el-radio :label="'YES'">Yes</el-radio>
										<el-radio :label="'NO'">No</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-form>

							<div
								class="signature-box mb-0"
								v-if="
									documentSentStatus &&
									userDocumentFormData.e_signature_required
								"
							>
								<el-card shadow="never" class="mt-1 bg-light">
									<div
										class="float-right"
										v-if="userDocumentFormData.e_signature_status"
									>
										<div class="success">
											<i class="el-icon-finished"></i> Completed
										</div>
									</div>
									<div
										class="float-right"
										v-if="!userDocumentFormData.e_signature_status"
									>
										<div class="mb-05 error">Pending</div>
									</div>

									<h4 class="font-weight-normal">E-Signature</h4>
									<el-divider class="mt-05 mb-05"></el-divider>
									<div
										class="text-center"
										v-if="userDocumentFormData.e_signature_status"
									>
										<el-button
											class="btn-link p-0"
											type="text"
											@click="onESignView"
											>View Signature</el-button
										>
									</div>
								</el-card>
							</div>
						</el-row>
					</div>
				</el-col>
			</el-row>
		</div>

		<el-dialog :visible.sync="showSavedSignatureData" title="eSignature">
			<div v-loading="esignLoading">
				<el-row>
					<el-col :span="24" v-if="esignData">
						<ESignatureDataView :esignData="esignData"></ESignatureDataView>
					</el-col>
				</el-row>
			</div>
		</el-dialog>
	</section>
</template>

<script>
	import appConfig from "@/config/app";
	import { mapGetters } from "vuex";
	import ESignatureDataView from "@/components/signature/ESignatureDataView";
	// import VRuntimeTemplate from "v-runtime-template";
	import offerLetterBuildHelper from "@/mixins/offerLetterBuildHelper";

	export default {
		name:"previewCompanyDocuments-CustomDocTypeDocumentPreview",
		components: {
			ESignatureDataView,
		},
		mixins: [offerLetterBuildHelper],

		created() {},
		data() {
			return {
				elements: [],

				editorConfig: {
					removePlugins: "toolbar",
					allowedContent: "p h1 h2 strong em; a[!href]; img[!src,width,height];",
				},
				documentId: null,
				configurableDocumentId: null,
				documentDataLoading: false,
				documentFormData: null,
				documentName: "",
				loading: false,
				backgroundImage: "",
				previewVisible: false,
				activatedItem: null,
				activatedItemIndex: -1,

				currentFormBuilderWidth: null,
				storedFormBuilderWidth: null,
				checkExistingData: false,
				backgroundImages: [],
				loadingElements: false,
				selectedTemplateFields: [],
				templatesUsed: [],
				showErrorElementDialog: false,
				errorElements: [],
				fieldErrorMessage: "",
				workflowDataId: "",
				userId: "",

				userDocumentFormData: {
					valid_from: "",
					expires_at: "",
					e_signature_required: "",
					e_signature_value: "",
				},
				employeeDocumentId: "",
				templateDataIdsData: [],
				workflowStepId: "",

				showSavedSignatureData: false,
				esignLoading: false,
				esignData: {},
				documentSentStatus: false,
				documentBody: "",
				fields: [],
				documentData: {},
			};
		},
		computed: {
			...mapGetters("company", [
				"getCompanyProfileData",
				"getAuthorizedSignatures",
				"getAuthorizedSignatureById",
			]),

			...mapGetters("employee", ["getSingleEmployeeData"]),
			...mapGetters("configurableDocuments", ["getConfigurableDocumentDataById"]),
			...mapGetters("employeeData", ["getUserTemplatesData", "getESignData"]),

			...mapGetters("templatesData", ["getTemplatesData"]),
			...mapGetters("workflowData", [
				"getWorkflowDataAssetDataAddStatus",
				"getUserWorkflowData",
			]),
			...mapGetters("companyDocuments", ["getCompanyDocumentDataById"]),
			...mapGetters("employeeDocuments", [
				"getEmployeeDocumentDataById",
				"getEmployeeDocumentUpdateStatus",
			]),

			getLogoUrl() {
				return appConfig.S3_BUCKET_URL + "/" + this.getCompanyProfileData.logo;
			},
			dynamicComponent() {
				let data = { ...{}, ...this.documentData };
				return {
					template:
						`<div class='document-editor'>` + this.documentBody + `</div>`,
					data() {
						return {
							documentData: data,
						};
					},
				};
			},
		},
		async mounted() {
			this.fetchCompanyDetails();
			this.setData();
			await this.fetchDocumentData();
			await this.fetchWorkflowData();

			if (this.templatesUsed.length) {
				await this.fetchUserTemplateData();
			}

			if (this.employeeDocumentId) {
				await this.fetchEmployeDocumentData();
			}

			this.setEmployeDataToForm();
		},
		methods: {
			setData() {
				this.employeeId = this.$route.params.employee_id;
				this.configurableDocumentId = this.$route.params.configurable_document_id;
				this.documentId = this.$route.params.document_id;
				this.employeeDocumentId = this.$route.query.employee_document_id;
				this.workflowStepId = this.$route.query.workflow_step_id;
			},

			async setEmployeDataToForm() {
				this.documentDataLoading = true;

				if (this.employeeDocumentId && this.getEmployeeDocumentDataById) {
					this.userDocumentFormData.e_signature_required = this.getEmployeeDocumentDataById.e_signature_required;
					this.userDocumentFormData.e_signature_status = this.getEmployeeDocumentDataById.e_signature_status;
					this.userDocumentFormData.expires_at = this.getEmployeeDocumentDataById.expires_at;
					this.userDocumentFormData.valid_from = this.getEmployeeDocumentDataById.valid_from;
					this.userDocumentFormData.e_signature_value = this
						.getEmployeeDocumentDataById.e_signature_required
						? "YES"
						: "NO";
					this.documentData = this.getEmployeeDocumentDataById.document_data;
				}

				this.fields = await this.prepareElements(this.documentBody);

				this.documentDataLoading = false;
			},

			async fetchAuthorizedSingatureById(id) {
				await this.$store.dispatch(
					"company/getCompanyAuthorizationSignatureById",
					{ id }
				);
				return this.getAuthorizedSignatureById;
			},

			async fetchWorkflowData() {
				this.workflowDataId = this.$route.params.workflow_data_id;
				if (this.workflowDataId) {
					this.userId =
						this.getUserWorkflowData && this.getUserWorkflowData.user_id
							? this.getUserWorkflowData.user_id
							: null;

					this.templateDataIdsData = this.getUserWorkflowData.template_data_ids;

					let steps = this.getUserWorkflowData.workflow_steps_data || [];

					this.documentSentStatus = steps.find(
						(x) => x.step_id == this.workflowStepId && x.sent_status
					)
						? true
						: false;
				}
			},

			async fetchUserTemplateData() {
				let usedTemplateIds = this.templatesUsed.map((e) => e._id);
				let params = {
					template_ids: usedTemplateIds,
					user_id: this.userId,
				};
				await this.$store.dispatch("templatesData/fetchUserTemplateData", params);
			},
			getFieldDataFromTemplate(templateId, templateFieldId) {
				let template = this.templatesUsed.find((x) => x._id == templateId);
				if (template) {
					let field = template.sections[0].fields.find(
						(x) => x._id == templateFieldId
					);
					if (field) {
						return field;
					}
					return {};
				}
				return {};
			},
			getValueFromTempaleFieldFromEmployeeData(field) {
				let key = field.key.split("#")[1];

				if (this.getTemplatesData && this.getTemplatesData.length) {
					let template = this.getTemplatesData.find(
						(x) => x.template_id == field.template_id
					);
					if (template) {
						return template.template_data[key] ? template.template_data[key] : "";
					}
					return "";
				}
				return "";
			},
			async getValueFromTempaleFieldFromCompanyData(field) {
				let [source, key] = field.key.split("#");
				var logoUrl =
					appConfig.S3_BUCKET_URL + "/" + this.getCompanyProfileData.logo;

				if (source == "CompanyDetails") {
					if (this.getCompanyProfileData) {
						let comapnyData = this.getCompanyProfileData;
						if (key == "name") {
							return comapnyData[key] ? comapnyData[key] : "";
						} else if (key == "logo") {
							return `<img src='${logoUrl}' height='100'/>`;
						} else return "";
					} else {
						return "";
					}
				} else if (source == "AuthorizedSignature") {
					let authorizedSingatureId = key.split("_").pop();
					if (authorizedSingatureId) {
						let authorizedSignature = await this.fetchAuthorizedSingatureById(
							authorizedSingatureId
						);
						if (authorizedSignature) {
							let signatureHTML = `<img src="${await this.convertImageToBase64(
								authorizedSignature.auth_sign_url
							)}" height="60"  />`;
							let nameHTML = `<p>${authorizedSignature.name}</p>`;
							let designationHTML = `<p>${authorizedSignature.designation}</p>`;
							return signatureHTML + nameHTML + designationHTML;
						}
						return "";
					}
					return "";
				}
			},

			getValueFromSavedDocument(key) {
				if (
					this.getEmployeeDocumentDataById &&
					this.getEmployeeDocumentDataById.document_data
				) {
					return this.getEmployeeDocumentDataById.document_data[key];
				}
				return null;
			},
			async fetchCompanyDetails() {
				await this.$store.dispatch("company/getCompany");
			},
			async fetchEmployeeData() {
				this.empDataLoading = true;
				await this.$store.dispatch("employee/fetchEmployeeSingleEmployee", {
					id: this.employeeId,
				});
				this.empDataLoading = false;
			},

			async fetchEmployeDocumentData() {
				await this.$store.dispatch(
					"employeeDocuments/fetchEmployeeDocumentById",
					{
						id: this.employeeDocumentId,
					}
				);
			},

			async fetchDocumentData() {
				this.documentDataLoading = true;
				await this.$store.dispatch(
					"configurableDocuments/fetchConfigurableDocumentById",
					{
						id: this.configurableDocumentId,
						include_template_details: true,
					}
				);
				await this.$store.dispatch("companyDocuments/fetchCompanyDocumentById", {
					id: this.documentId,
				});

				this.userDocumentFormData.valid_from = this.getCompanyDocumentDataById.valid_from;
				this.userDocumentFormData.expires_at = this.getCompanyDocumentDataById.valid_to;
				this.userDocumentFormData.e_signature_required = this.getCompanyDocumentDataById.e_signature_required;

				this.documentFormData = JSON.parse(
					JSON.stringify(this.getConfigurableDocumentDataById)
				);

				this.documentBody = this.documentFormData.document_body;
				this.templatesUsed = this.documentFormData.templates_used;

				this.documentDataLoading = false;
			},

			checkFieldAllow(item) {
				return item.filled_by === "RECEIVER";
			},
			goToEmployesPage() {
				this.$router.push({ path: "/employee-list" });
			},

			getData() {
				let data = {};

				for (let index = 0; index < this.elements.length; index++) {
					let field = this.elements[index];
					let key = field.key;
					data[key] = this.elements[index].value || this.elements[index].source;
					if (field.type == "SIGNATURE") {
						this.signatureFields.push(field);
					}
				}
				return data;
			},

			goToDocuments() {
				this.$router.go(-1);
			},
			setColor(selectedSignatureItem, color) {
				this.$set(selectedSignatureItem, "color", color);
			},

			saveDocumentData() {
				try {
					if (this.employeeDocumentId) {
						this.updateEmployeeDocument();
					} else {
						this.addEmployeeDocument();
					}
				} catch (err) {
					console.log(err);
				}
			},

			async updateEmployeeDocument() {
				try {
					let documentData = this.prepareDocumentData();
					let params = {
						id: this.employeeDocumentId,
						document_data: documentData,
						valid_from: this.userDocumentFormData.valid_from,
						expires_at: this.userDocumentFormData.expires_at,
						e_signature_required:
							this.userDocumentFormData.e_signature_value == "YES" ? true : false,
					};

					await this.$store.dispatch(
						"employeeDocuments/updateEmployeeDocument",
						params
					);

					if (this.getEmployeeDocumentUpdateStatus) {
						this.$notify.success({
							title: "Success",
							message: "Document Data Saved Successfully",
						});
						this.goToDocuments();
					} else {
						this.$notify.error({
							title: "Error",
							message: "Erorr at updating document",
						});
					}
				} catch (err) {
					console.log(err);
				}
			},

			async prepareElements() {
				var el = document.createElement("html");
				el.innerHTML =
					"<html><head></head><body class='document-editor'>" +
					this.documentBody +
					"</body></html>";

				el = await this.updateMentionsData(el);
				let duplicateKeyCounter = {};
				el = this.updateInputsData(el, duplicateKeyCounter);
				el = this.updateSignaturesData(el, duplicateKeyCounter);

				this.documentBody = el.innerHTML.replace("<html><head></head><body>", "");
				this.documentBody = el.innerHTML.replace("</body></html>", "");
				return [];
			},

			async updateMentionsData(el) {
				const mentions = el.querySelectorAll("[data-mention-id]");
				for (let index = 0; index < mentions.length; index++) {
					// replacing employee details
					let key = mentions[index].getAttribute("data-mention-id");
					let templateId = mentions[index].getAttribute("template_id");

					let fieldType = mentions[index].getAttribute("field_type");

					let field = {
						key: key,
						template_id: templateId,
					};

					let value = "";
					let isAssetValue;
					if (fieldType == "COMPANY_ASSET") {
						value = await this.getValueFromTempaleFieldFromCompanyData(field);

						isAssetValue = true;
					} else {
						value = this.getValueFromTempaleFieldFromEmployeeData(field);
					}

					let documentValue =
						this.documentData && this.documentData[key]
							? this.documentData[key]
							: "";

					if (documentValue) {
						mentions[index].innerHTML = documentValue;
						field.value = documentValue;
					} else if (value) {
						mentions[index].innerHTML = value;
						field.value = value;
					}

					if (isAssetValue) {
						if (field.value && field.value.indexOf("img") > -1) {
							mentions[index].setAttribute("style", "background:none;");
						}
					}
					this.elements.push(field);
				}
				return el;
			},

			updateInputsData(el, duplicateKeyCounter) {
				const inputs = el.querySelectorAll("input");

				for (let index = 0; index < inputs.length; index++) {
					let label = inputs[index].getAttribute("name");

					let field = {
						value: "",
					};
					let newKey = this.generateKeyForField(label);
					if (duplicateKeyCounter[newKey]) {
						field.key = newKey + "_" + duplicateKeyCounter[newKey];
						duplicateKeyCounter[newKey]++;
					} else {
						duplicateKeyCounter[newKey] = 1;
						field.key = newKey;
					}

					let documentValue =
						this.documentData && this.documentData[field.key]
							? this.documentData[field.key]
							: "";

					if (documentValue) {
						field.value = documentValue;
						inputs[index].setAttribute("value", documentValue);
					} else {
						inputs[index].setAttribute("value", "");
					}
					inputs[index].setAttribute("key", field.key);
					inputs[index].setAttribute("v-model", "documentData." + field.key);

					this.elements.push(field);
				}

				const textAreas = el.querySelectorAll("textarea");

				for (let index = 0; index < textAreas.length; index++) {
					let label = textAreas[index].getAttribute("name");

					let field = {
						value: "",
					};
					let newKey = this.generateKeyForField(label);
					if (duplicateKeyCounter[newKey]) {
						field.key = newKey + "_" + duplicateKeyCounter[newKey];
						duplicateKeyCounter[newKey]++;
					} else {
						duplicateKeyCounter[newKey] = 1;
						field.key = newKey;
					}

					let documentValue =
						this.documentData && this.documentData[field.key]
							? this.documentData[field.key]
							: "";

					if (documentValue) {
						field.value = documentValue;
						textAreas[index].setAttribute("value", documentValue);
					} else {
						textAreas[index].setAttribute("value", "");
					}
					textAreas[index].setAttribute("key", field.key);
					textAreas[index].setAttribute("v-model", "documentData." + field.key);

					this.elements.push(field);
				}

				return el;
			},

			updateSignaturesData(el, duplicateKeyCounter) {
				var signatures = el.querySelectorAll("[data-type]");
				for (let index = 0; index < signatures.length; index++) {
					let label = signatures[index].getAttribute("name");

					let field = {
						value: "",
						type: "signature",
					};
					let newKey = this.generateKeyForField(label);
					if (duplicateKeyCounter[newKey]) {
						field.key = newKey + "_" + duplicateKeyCounter[newKey];
						duplicateKeyCounter[newKey]++;
					} else {
						duplicateKeyCounter[newKey] = 1;
						field.key = newKey;
					}

					let documentValue =
						this.documentData && this.documentData[field.key]
							? this.documentData[field.key]
							: "";

					if (documentValue) {
						field.value = documentValue;

						signatures[index].setAttribute("style", "background:none;");
						signatures[index].innerHTML =
							"<img height='50' src='" + documentValue + "'>";
					}
					signatures[index].setAttribute("key", field.key);

					this.elements.push(field);
				}
				return el;
			},

			getInputElementsData() {
				if (this.$refs.customDocument && this.$refs.customDocument.documentData) {
					return this.$refs.customDocument.documentData;
				} else {
					return {};
				}
			},
			async addEmployeeDocument() {
				try {
					let documentData = this.prepareDocumentData();
					let companayDocumentData = {
						configurable_document_id: this.configurableDocumentId,
						company_document_id: this.documentId,
						document_data: documentData,
						document_type: "CUSTOM",
						valid_from: this.userDocumentFormData.valid_from,
						expires_at: this.userDocumentFormData.expires_at,
						e_signature_required:
							this.userDocumentFormData.e_signature_value == "YES" ? true : false,
					};
					let params = {
						workflow_data_id: this.workflowDataId,
						workflow_step_id: this.workflowStepId,

						asset: "COMPANY_DOCUMENTS",
						company_document_data: companayDocumentData,
					};
					await this.$store.dispatch(
						"workflowData/addAssetDataToWorkFlowData",
						params
					);
					if (this.getWorkflowDataAssetDataAddStatus) {
						this.$notify.success({
							title: "Success",
							message: "Document Data Saved Successfully",
						});
						this.goToDocuments();
					} else {
						this.$notify.error({
							title: "Error",
							message: "Erorr at updating document",
						});
					}
				} catch (err) {
					this.$notify.error({
						title: "Error",
						message: "Erorr at updating document",
					});
				}
			},

			prepareDocumentData() {
				let data = {
					document_body: this.documentBody,
				};

				this.elements.forEach((element) => {
					if (element.value) {
						data[element.key] = element.value;
					} else if (element.source) {
						data[element.key] = element.source;
					} else {
						data[element.key] = "";
					}
				});

				let inputData = this.getInputElementsData();
				data = { ...data, ...inputData };

				return data;
			},

			generateKeyForField(label) {
				let parsedLabel = label.toLowerCase().replace(/[^a-zA-Z 0-9]/g, "");
				parsedLabel = parsedLabel.toLowerCase().split(" ").join("_");

				return parsedLabel;
			},
			async onESignView() {
				try {
					this.showSavedSignatureData = true;
					this.esignLoading = true;
					let params = {
						id: this.getEmployeeDocumentDataById.e_signature,
					};
					await this.$store.dispatch("employeeData/fetchESignData", params);
					this.esignLoading = false;
					this.esignData = this.getESignData;
				} catch (err) {
					console.log(err);
				}
			},
			updateEsignValue(value) {
				this.userDocumentFormData.e_signature_required = value === "YES";
			},
		},
		beforeDestroy() {
			this.$store.commit("company/setCompanyProfileData",null,{root:true})
			this.$store.commit("employee/setSingleEmployeeData",null,{root:true})
			this.$store.commit("employeeData/setESignData",null,{root:true})
			this.$store.commit("workflowData/setUserWorkflowData",null,{root:true})
			this.$store.commit("workflowData/setWorkflowDataAssetDataAddStatus",null,{root:true})
			this.$store.commit("companyDocuments/setCompanyDocumentDataById",null,{root:true})
			this.$store.commit("employeeDocuments/setEmployeeDocumentUpdateStatus",null,{root:true})
			this.$store.commit("employeeDocuments/setEmployeeDocumentDataById",null,{root:true})
		}
	};
</script>

<style lang="scss" scoped>
	.form-builder {
		width: 950px;
		max-height: 900px;
		overflow-y: auto;
		background-color: #ffffff;
		border: 1px solid #efefef;
		-webkit-box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
		box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
		border-radius: 10px;
		.form-image-holder {
			width: 100%;
			img {
				width: 100%;
			}
		}

		.form-fields-holder {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			.each-element img {
				width: 100%;
			}
		}
	}
	#drag-template-background {
		.img-fluid {
			height: auto !important;
		}
		.inner-navbar {
			margin-bottom: 1.5em;
			.left-block {
				.el-input__inner {
					min-width: 250px;
					max-width: 300px;
					background: #fff;
					height: 47px;
				}
			}
		}
		.input-options {
			td {
				padding-top: 0;
				vertical-align: top;
				&:nth-child(1) {
					width: 20%;
				}
				&:nth-child(2) {
					width: 80%;
				}
			}
			.inputs-list {
				background: #fff;
				padding: 15px;
				box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.06),
					0 1px 0px rgba(0, 0, 0, 0.08);
				border-radius: 5px;
				.el-button {
					border: none;
					background: rgba(236, 245, 255, 0.7);
					&:hover {
						background: rgba(236, 245, 255, 1);
					}
				}
				li {
					&:not(:last-child) {
						margin-bottom: 15px;
					}
				}
			}
		}
		.form-image {
			max-width: 770px;
			min-height: 1000px;
			background-color: #e6dcdc;
		}
		.el-input__inner {
			display: inline !important;
		}

		.form-holder {
			width: 100%;
			position: relative;
			.el-input__inner,
			.el-textarea__inner {
				height: 100% !important;
				min-height: 30px !important;
				line-height: inherit !important;
			}
			input[type="checkbox"] {
				margin: 0 !important;
			}

			// .draggable.resizable {
			//   position: absolute;
			//   z-index: 100;
			// }
		}

		.sample-form1 {
			position: relative;
			width: 100%;
			max-height: 91vh;
			overflow: scroll;
			border: 1px solid #ccc;
			margin-left: 20px;
			margin-bottom: 45px;

			///
			.each-page {
				position: relative;
				width: 900px;
				display: block;
				height: 800px;
				overflow-y: auto;
				.form-image {
					height: 800px;
					position: absolute;
					width: 850px;
					top: 0;
					left: 0;
				}
				.form-fields {
					height: 800px;
					position: absolute;
					width: 850px;
					top: 0;
					left: 0;
					z-index: 100;
				}
				.page-image {
					width: 850px;
					margin: 0 auto;
				}
			}

			// .draggable.resizable {
			//   position: absolute;
			//   z-index: 100;
			// }
		}

		.color-btn {
			height: 20px;
			width: 20px;
			border-radius: 50%;
			margin: 10px;
			&.red {
				background-color: red;
			}
			&.black {
				background-color: black;
			}

			&.green {
				background-color: green;
			}
		}

		// Fields
		.form-fields-set {
			background-color: #ffffff;
			padding: 1.25em 1em;
			border-radius: 2px;
			-webkit-box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
			box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
			.el-form-item {
				margin-bottom: 0.5em;
			}
		}
	}
</style>