import axios from "@/config/axios";

// initial state
const initialState = () => {
  return {
    companyDetails: null,
    updateCompany: null,
    companyLogo: null,
    listOfCompanies: [],
    singleCompanyLogo: null,
    contactLoginSlug: null,
    companyExistsStatus : {},
  };
};

const state = initialState();

const getters = {
  getCompanyDetails: state => state.companyDetails,
  getUpdateCompany: state => state.updateCompany,
  getCompanyLogo: state => state.companyLogo,
  getListOfCompanies: state => state.listOfCompanies,
  getSingleCompanyLogo: state => state.singlecompanyLogo,
  getContactLoginSlug: state => state.contactLoginSlug,
  getCompanyExistsStatus: state => state.companyExistsStatus,
};

const mutations = {
  setCompanyDetails: (state, data) => {
    state.companyDetails = data
  },
  setUpdateCompany: (state, data) => {
    state.updateCompany = data
  },
  setCompanyLogo: (state, data) => {
    state.companyLogo = data
  },
  setListOfCompanies: (state, data) => {
    state.listOfCompanies = data
  },
  setSingleCompanyLogo: (state, data) => {
    state.singlecompanyLogo = data
  },
  setContactLoginSlug: (state,data) => {
    state.contactLoginSlug = data
  },
  setCompanyExistsStatus: (state, data)=> {
    state.companyExistsStatus = data
  },
  resetData: state => {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  reset: function (context) {
    context.commit("company/resetData", null, {
      root: true
    });
  },
  fetchCompany: async ({
    commit
  }, params) => {
    try {
      commit('company/setCompanyDetails', null, {
        root: true
      })
      commit('company/setCompanyDetails', null, {
        root: true
      });
      const response = await axios.get('/company/' + params);
      commit('company/setCompanyDetails', response.data.data, {
        root: true
      });
    } catch (error) {
      commit('company/setCompanyDetails', null, {
        root: true
      });
    }
  },
  updateCompany: async (context, params) => {
    try {
      context.commit('company/setUpdateCompany', null, {
        root: true
      })
      context.commit('company/setUpdateCompany', null, {
        root: true
      });
      context.commit('errors/errorResponse', null, {
        root: true
      });
      const response = await axios.patch('/company', params);
      context.commit('company/setUpdateCompany', response.data, {
        root: true
      });
    } catch (error) {
      context.commit('company/setUpdateCompany', error.data, {
        root: true
      });
      await context.dispatch("errors/errorResponse", error.response, {
        root: true
      });
    }
  },
  updateCompanyLogo: async (context, params) => {
    try {
      // Reset the store to initial state
      context.commit('company/resetData', null, {
        root: true
      });
  
      // Perform the API call
      const response = await axios.post('/company/logo', params);
  
      // Forcefully update the store data for companyLogo
      context.commit('company/setCompanyLogo', response.data.data, {
        root: true
      });
    } catch (error) {
      // Handle errors
      context.commit('company/setCompanyLogo', error.data, {
        root: true
      });
      await context.dispatch("errors/errorResponse", error.response, {
        root: true
      });
    }
  },
  
  fetchCompanyLogo: async (context, id) => {
    try {
      context.commit('company/setSingleCompanyLogo', null, {
        root: true
      })
      const response = await axios.get('/company-logo/'+id);
      context.commit('company/setSingleCompanyLogo', response.data.data, {
        root: true
      });
    } catch (error) {
      context.commit('company/setSingleCompanyLogo', error.data, {
        root: true
      });
      await context.dispatch("errors/errorResponse", error.response, {
        root: true
      });
    }
  },
  fetchListOfCompanies: async ({
    commit
  }, params) => {
    try {
      commit('company/setListOfCompanies', null, {
        root: true
      })
      commit('company/setListOfCompanies', null, {
        root: true
      });
      // const response = await axios.get('/accountNames');
      const response = await axios.get(`/entityDataNames/${params.contactTypeName}`);
      commit('company/setListOfCompanies', response.data.data, {
        root: true
      });
    } catch (error) {
      commit('company/setListOfCompanies', null, {
        root: true
      });
    }
  },
  fetchCompanyBySlug: async ({
    commit
  }, params) => {
    try {
      commit('company/setContactLoginSlug', null, {
        root: true
      });
      const response = await axios.get('/contact-login/company/' + params.slug);
      commit('company/setContactLoginSlug', response.data.data, {
        root: true
      });
    } catch (error) {
      commit('company/setContactLoginSlug', null, {
        root: true
      });
    }
  },
  fetchCompanyNameStatus: async(context,params) => {
    try{
      context.commit('company/setCompanyExistsStatus',false,{root : true})
      const response = await axios.post('/company-name-status',params)
      console.log("response",response)
      context.commit('company/setCompanyExistsStatus',response.data,{root : true})
    }
    catch(err){
      console.log(err)
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};