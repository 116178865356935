<template>
  <section
    class="configure-document workflow-edit-page"
    id="drag-template-background"
    v-loading.fullscreen.lock="modalLoading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    :class="{ mobile: getIsMobile, 'is-close-RS': !getIsRSOpen }"
  >
    <div v-if="usedWorkflow" style="text-align: center" class="mt-3">
      <h3>
        You can't edit or send this workflow. Because it's already sent workflow
      </h3>
    </div>
    <div v-else>
      <div class="flow-top-bar">
        <div class="left-side">
          <div class="close-icon">
            <el-button class="rounded-0 back-btn type-2" @click="goBack">
              <i class="el-icon-back">
                {{ getIsMobile ? "" : "Back" }}
              </i>
            </el-button>
          </div>
          <el-form
            :class="!this.isEdit ? 'form-title hide-title' : 'form-title'"
          >
            <el-input
              v-model="workflowName"
              placeholder="Enter Template Title"
              class="input-type-one"
            ></el-input>
            <!-- <p class="error" v-if="!workflowName">
            {{ "Workflow name is required" }}
          </p> -->
          </el-form>
          <div>
            <el-steps
              finish-status="success"
              :space="200"
              align-center
              class="document-flow"
              :active="activeStep"
              v-if="!this.isEdit"
            >
              <el-step title="Builder" @click.native="gotoBuilder"></el-step>
              <el-step
                title="Users"
                @click.native="checkUpdateOrCreate"
              ></el-step>
              <el-step
                title="Preview"
                @click.native="checkWorkflowUsers"
              ></el-step>
            </el-steps>
          </div>
        </div>
        <div style="display: flex" class="right-side">
          <el-link
            :underline="false"
            @click="visibleSettingsVisible"
            class="settings-button"
            icon="el-icon-s-tools"
            v-if="!getIsMobile"
            style="margin-right: 20px;"
          >
            <!-- <i class="el-icon-s-tools"></i> -->
            {{ getIsMobile ? "" : "Settings" }}
          </el-link>
          <el-link
            :underline="false"
            @click="checkUpdateData"
            class="save-workflow-button"
            type="primary"
            v-if="activeStep == 0 && !getIsMobile"
          >
            <!-- <i class="el-icon-s-tools"></i> -->
            {{
              getIsMobile
                ? "Save"
                : isEdit
                ? "Save Workflow"
                : "Save workflow Data"
            }}
          </el-link>
          <el-button
            type="danger"
            class="doc-save-send-button"
            @click="checkUpdateOrCreate"
            :disabled="!selectedWorkflow.length"
            v-if="activeStep == 0"
          >
            {{ getIsMobile ? "Send" : "Save & send" }}
          </el-button>
          <el-button
            type="danger"
            class="next-button"
            @click="checkWorkflowUsers"
            v-else-if="activeStep == 1"
          >
            {{ "Next" }}
          </el-button>
          <el-button
            type="danger"
            class="doc-send-button"
            @click="prepareTempData"
            v-else-if="activeStep == 2"
          >
            {{ "Send" }}
          </el-button>

          <el-dropdown v-if="getIsMobile" class="vertical-three-dots-wrapper">
            <div class="el-dropdown-link vertical-three-dots">
              <img class="arrows" src="@/assets/Icon-ellipsis-v.svg" />
            </div>
            <el-dropdown-menu slot="dropdown">
              <a :underline="false" @click="visibleSettingsVisible">
                <el-dropdown-item icon="el-icon-s-tools">
                  Settings
                </el-dropdown-item>
              </a>
              <a v-if="activeStep == 0" @click="checkUpdateData">
                <el-dropdown-item>
                  <i class="el-icon-folder-add"></i>
                  {{ isEdit ? "Save Workflow" : "Save workflow Data" }}
                </el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>

          <div class="action-wrapper-type-two">
            <el-button
              slot="reference"
              class="rounded-0 btn-one type-2"
              type="primary"
              v-on:click="openRightSideBar()"
              :disabled="getIsRSOpen"
              v-if="!getIsRSOpen && activeStep === 0"
              >&lt;&lt;</el-button
            >
            <el-button
              slot="reference"
              class="rounded-0 btn-one close type-2"
              type="primary"
              v-on:click="closeRightSideBar()"
              v-if="getIsRSOpen && activeStep === 0"
              >&gt;&gt;</el-button
            >
          </div>
        </div>
        <!-- <el-col :span="4">
     
        </el-col> -->
      </div>
      <!-- <div class="inner-navbar">
      <div class="inner-navbar-2nd-level-child" style="margin-top: 0px">
        <div class="inner-navbar-1th-level-child flex-direction-row">
          <div>
            <el-button
              slot="reference"
              class="rounded-0 back-btn type-2 mt-1"
              @click="goBack"
            >
              <i class="el-icon-back">Back</i>
            </el-button>
          </div>
          <div class="flex-fill left-block vue-form ml-2 mt-1">
            <el-form class="form-title">
              <el-input
                style="width: 300px"
                v-model="workflowName"
                placeholder="Enter Template Title"
                class="input-type-one"
              ></el-input>
              <p class="error" v-if="!workflowName">
                {{ "Worflow name is required" }}
              </p>
            </el-form>
          </div>
        </div>
         <div
          class="flex-self-end pe-3 align-items-self">
        <el-steps :space="200" :active="1" finish-status="success">
          <el-step title="Done"></el-step>
          <el-step title="Processing"></el-step>
        </el-steps>
         </div>
        <div class="flex-self-end pe-3 align-items-self">
          <el-button
            type="danger"
            class="type-2 ml-2 settigns-font button-type-one doc-plus"
          >
            {{ getIsMobile ? "+ Doc" : "Create workflow" }}
          </el-button>
        </div>
      </div>
    </div> -->
      <div class="workflow-editor-container" v-if="activeStep == 0">
        <div class="document-container-wrapper">
          <el-scrollbar wrap-style="max-height: calc(100vh - 86px);">
            <div class="form-builder">
              <div>
                <div class="emp-box" v-if="!selectedWorkflow.length">
                  <h3 class="head pt-1 pb-1">Start Building Workflow!</h3>
                  <p class="text pb-1">
                    Select templates from the right panel and place here to add
                    them to your workflow.
                  </p>
                </div>
                <div v-if="selectedWorkflow.length" class="workflow-steps">
                  <draggable
                    v-model="selectedWorkflow"
                    v-if="selectedWorkflow && selectedWorkflow.length"
                  >
                    <transition-group
                      tag="div"
                      name="flip-list"
                      id="field-group"
                    >
                      <div
                        v-for="(step, index) of selectedWorkflow"
                        :key="index"
                      >
                        <div class="each-step">
                          <span class="arrow-down"></span>
                          <el-row>
                            <el-col :span="2">
                              <img
                                src="@/assets/img/icons/pdf-file.svg"
                                alt="icon"
                                class="img-fluid"
                                style="margin-top: 18px"
                              />
                            </el-col>
                            <el-col :span="16">
                              <div class="name-box">
                                <span>Step {{ index + 1 }}</span>
                                <p>{{ step.title | truncate(40, "...") }}</p>
                              </div>
                            </el-col>
                            <el-col :span="2">
                              <el-button
                                type="Primary"
                                icon="el-icon-view"
                                class="setting-btn"
                                size="mini"
                                style="margin-top: 18px"
                                @click="openTemplatePreviewPopup(step, index)"
                              ></el-button>
                            </el-col>
                            <el-col :span="2">
                              <el-button
                                type="Primary"
                                icon="el-icon-setting"
                                class="setting-btn"
                                size="mini"
                                style="margin-top: 18px"
                                @click="openTemplateSettingsPopup(step, index)"
                              ></el-button>
                            </el-col>

                            <el-col :span="2">
                              <el-button
                                type="danger"
                                icon="el-icon-delete"
                                class="delete-btn"
                                size="mini"
                                style="margin-top: 18px"
                                @click="deleteTemplate(step,index)"
                              ></el-button>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="right-side-bar">
          <div class="right-temp-block">
            <div style="text-align: center">
              <h3 class="temp mt-1">Document Templates</h3>
            </div>
            <el-divider></el-divider>
            <el-input
              placeholder="Search by template name"
              class="mb-1 ml-1 mr-2"
              style="width: 300px"
              v-model="search_string"
              clearable
            >
            </el-input>
            <router-link
                v-if="
                  !this.allTemplates.length ||
                  this.allTemplates.length == 0 ||
                  !this.allTemplates
                "
                to="/templates"
              >
                <el-button
                  class="mb-1 ml-2 mr-1"
                  style="width: 300px"
                  type="primary"
                >
                  Add Document Templates
                </el-button>
              </router-link>
            <div class="sidemenu">
              <el-button
                v-for="(temp, index) in this.allTemplates"
                :key="index"
                class="temp-box mb-1 mr-1 ml-1"
                @click="addTemplate(temp)"
                :disabled="checkIsUsedTemplate(temp)"
              >
                <div class="icon-block">
                  <img
                    src="@/assets/img/icons/pdf-file.svg"
                    alt="icon"
                    class="img-fluid"
                  />
                  <el-popover
                    width="200"
                    trigger="hover"
                    :content="temp.title"
                    placement="left"
                  >
                    <div
                      slot="reference"
                      class="doc-title"
                      style="cursor: pointer"
                    >
                      {{ temp.title | truncate(40, "...") }}
                    </div>
                  </el-popover>
                </div>
              </el-button>
              <!-- </el-scrollbar> -->
            </div>
          </div>
        </div>
      </div>
      <div class="workflow-editor-container" v-else-if="activeStep == 1">
        <ConfigureUsers
          @createWorkflowData="fetchAllUsersData"
          :enforceWorkflowOrderValue="enforceWorkflowOrderValue"
          :is_submit="is_submit"
          :workflowData="getWorkflowDataById"
          :loading="loading"
        >
        </ConfigureUsers>
      </div>
      <div v-else-if="activeStep == 2">
        <PreviewWorkflowTemplate
          :workflowData="getCreatedworkflowDataById"
          :allUsersData="this.allUsersData"
          :is_prepare="is_prepare"
          @createWorkflowDocuments="createWorkflowDocuments"
          :dialogForSavingData="dialogForSavingData"

        >
        </PreviewWorkflowTemplate>
      </div>
      <ConfigureSettings
        v-if="visibleSettings"
        :docSettingsVisible="visibleSettings"
        :workflowSettings="this.settings"
        :isEdit="isEdit"
        @close="closeWorkflowSettings"
      ></ConfigureSettings>
      <ConfigureTemplateSettings
      v-if="docSettingsVisible"
      :docSettingsVisible="docSettingsVisible"
      @close="docSettingsVisible = false"
      @updateDocSettingsData="closeSettingsAndSend"
      :configurableId="this.configIdForSettings ? this.configIdForSettings : ''"
    ></ConfigureTemplateSettings>
    <el-dialog
        :visible.sync="showFinishErrorElementDialog"
        class="outersize-confirm-type-one"
        title="Alert Message!"
        :width="getIsMobile ? '100%' : '80%'"
        :before-close="closeAlertPopup"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <p style="font-family: inter, sans-serif">
          Please fill the following required fields in these templates:
        </p>
        <div class="alertPopupStyle" v-for="(groupedFields, documentName) in groupedUnfilledFields" :key="documentName">
          <strong>{{ documentName }}</strong>
          <ul class="listUnFilledFieldsSty">
            <li v-for="(field, index) in groupedFields" :key="index">
              &nbsp;&nbsp;&nbsp;&nbsp;{{ index + 1 + " - " + (field.placeholder !== undefined && field.placeholder !== 'Enter value' ? field.placeholder : field.label) }} <br />
            </li>
          </ul>
        </div>
  
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeAlertPopup()">Close</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :visible.sync="sentDocumentSuccess"
        width="100%"
        class="dialog-success outersize-confirm-type-three"
        :title="
          workflowSentStatus ? 'Sending workflow' : 'Workflow sent successfully'
        "
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="reloadPage"
      >
        <div style="text-align: center" v-if="workflowSentStatus">
          <span>
            <img alt="Document Sent" src="@/assets/img/workflow-sending.gif" />
            <p class="sent-text">Sending workflow...</p>
          </span>
        </div>
        <div style="text-align: center" v-else-if="workflowSuccessStatus">
          <span>
            <img
              alt="Document Sent"
              src="@/assets/img/workflow-sent-success.gif"
            />
            <p class="sent-text">Workflow has been sent.</p>
          </span>

          <p style="width: 100%">
            We will send you an email notification when any action is taken on
            the workflow documents.
          </p>
          <!-- <p class="subpstyle">if someone view,signs or declined etc...</p> -->

          <div>
            <el-button
              id="doc"
              type="primary"
              style="margin-bottom: 5px"
              @click="goToActionRequiredDocument"
              >View Document</el-button
            >
          </div>
        </div>
      </el-dialog>
      <el-dialog
        :visible.sync="showTemplateUsersDialog"
        
        title="Template Users"
        :width="getIsMobile ? '100%' : '65%'"
        :before-close="closeTemplateUsersPopup"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
     
        <el-table 
        v-loading="tempUsersLoading"
        :element-loading-text="loadingText"
         :data="templateDocumentUsers" style="width:100%" border >
          <el-table-column
            label="Contact Type"
            min-width="150"
          ><template slot-scope="scope">
            <p v-if="scope.row.name !== 'SENDER'" class="templateUsersSty">{{ getContactTypeName(scope.row)}}</p>
            <p v-else>-- --</p>
          </template></el-table-column>
          <el-table-column
            label="Constant User"
            min-width="300"
          ><template slot-scope="scope" >
           <p class="templateUsersSty" v-if="scope.row.constant_user || scope.row.email">{{ scope.row.email }}</p>
           <p class="templateUsersSty" v-else>The user has not been assigned yet..!</p>
          </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="Role"
            width="200"
            class="templateUsersSty"
          ><template slot-scope="scope">
            <p class="templateUsersSty">{{ scope.row.name.toLocaleLowerCase() }}</p>
          </template>
        </el-table-column>
          <el-table-column
            label="User Type"
            min-width="80"
          ><template slot-scope="scope">
          <p class="templateUsersSty"> {{ scope.row.user_type.toLocaleLowerCase() }} </p>
          </template>
          </el-table-column>
        </el-table>
    
        <span slot="footer">
          <!-- <el-button @click="goToTemplates()">View Template</el-button> -->
        </span>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import PermissionsHelper from "@/mixins/permissionsHelper";
import { bus } from "../../main";
const ConfigureSettings = () =>
  import("@/components/workFlows/workflowSettings");
  const ConfigureTemplateSettings = () =>
  import(
    "@/components/companyDocuments/configureDocuments/ConfigureSettingsNew"
  );
const ConfigureUsers = () =>
  import("@/components/workFlows/workflowConfigUsers.vue");
const PreviewWorkflowTemplate = () =>
  import("@/components/workFlows/workflowTemplatePreview.vue");

import NavigationHelper from "@/mixins/navigationHelper";
export default {
  name: "workflows-addEditWorkflow",
  components: {
    draggable,
    ConfigureSettings,
    ConfigureUsers,
    PreviewWorkflowTemplate,
    ConfigureTemplateSettings,

  },
  mixins: [MobileRelatedHelper, NavigationHelper,PermissionsHelper,],
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.getIsMobile) {
      this.$store.commit("navigationOpen/setIsOpen", false, {
        root: true,
      });
      this.$store.commit("navigationOpen/setIsRSOpen", false, {
        root: true,
      });
    }
  },
  data() {
    return {
      search_string: "",
      loading: false,
      checkDocumentUsers: [],
      dialogForSavingData:false,
      loadingText: "",
      newWorkflowModal: false,
      workflowName: "",
      modalLoading: false,
      isEdit: false,
      showTemplateUsersDialog:false,
      templateDocumentUsers:[],
      activeStep: 0,
      tempUsersLoading:false,
      allUsersData: [],
      is_submit: false,
      allTemplates: [],
      enforceWorkflowOrderValue: false,
      selectedWorkflow: [],
      visibleSettings: false,
      docSettingsVisible: false,
      workflowId: "",
      workflowSettings: null,
      isUpdate: false,
      createdWorkflowDataId: null,
      sentDocumentSuccess: false,
      workflowSentStatus: false,
      workflowSuccessStatus: false,
      currentActiveDocument: "",
      contactUsers: [],
      sender_auto_fill: false,
      showFinishErrorElementDialog: false,
      unfilledFields: [],
      unWantedFields: [
        "INITIAL",
        "SIGNATURE",
        "IMAGE",
        "HORIZONTAL_LINE",
        "HEADING",
        "PARAGRAPH",
        "my-signature",
        "MY_INITIAL",
        "DATE_SIGNED",
        "ZSEAL",
        "SINGLE_LINE_CONTENT",
        "ATTACHMENT",
        "ZSEALARROWIMAGE",
        //"CHECKBOX",
        "FORMULA"
      ],
      is_prepare: false,
      workflow_data_id: null,
      settings: {
        secure_e_sign: {
          allow_document_e_sign: true,
        },
        auto_remainders_settings: {
          send_first_reminder_in_days: "",
          repeat_reminder: "",
          repeat_reminder_status: true,
          first_reminder_status: true,
        },
        expiration_settings: {
          send_expiration_reminder_status: true,
          repeat_expiration_reminder_status: true,
          send_first_reminder_in_days: "",
          is_default: true,
          repeat_expiration_reminder: "",
          expire_documents_in_days: 1,
          document_expired_date: "",
        },
        update_entity_data: 0,
        document_generation_settings: {
          add_document_generated_id: true,
          add_page_number: true,
        },
        email_attachement: {
          attach_pdf: true,
        },
      },
      usedWorkflow: false,
    };
  },
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
      "getIsRSOpen",
    ]),
    ...mapGetters("documents", [
      "getAllTemplates",
      "getAddWorkflowContactStatus",
    ]),
    ...mapGetters("templateWorkflow", [
      "getWorkflowCreatedData",
      "getWorkflowDataById",
      "getWorkflowDataUpdateStatus",
      "getWorkflowDataCreatedData",
      "getWorkflowDataCreatedStatus",
      "getWorkflowsendStatus",
      "getWorkflowsendData",
      "getCreatedworkflowStatus",
      "getSelectedWorkflowUsers",
      "getCreatedworkflowDataById",
      "getWorkflowCreatedDataUpdateStatus",
      "getWorkflowSavingData"
    ]),
    ...mapGetters("settings", ["getUserSettings"]),
    ...mapGetters("auth", ["getAuthenticatedUser",     
       "getInitialUrl",
      "getUserSignature",]),
    ...mapGetters("contacts", ["getAllContacts"]),
    ...mapGetters("settings", ["getApplicationSettings"]),
    ...mapGetters("entities",["getEntityByNames"]),
    groupedUnfilledFields() {
      const groupedFields = {};
  
      for (const field of this.unfilledFields) {
        if (!groupedFields[field.documentName]) {
          groupedFields[field.documentName] = [];
        }
        groupedFields[field.documentName].push(field);
      }
  
      return groupedFields;
    }
    
  },
  async mounted() {
    this.modalLoading = true;
    // this.loadingText = "Fetching users data"
    // setTimeout(() => {
    //         this.modalLoading = false;
    //     }, 600);
    this.activeStep =
      this.$route.query && this.$route.query.activeStep
        ? parseInt(this.$route.query.activeStep)
        : 0;
    if (this.$route.query.activeStep == 1 && this.allUsersData.length == 0) {
      this.loading = true;
      this.loadingText = "Fetching template users...";
    }
    this.checkIsEdit();
    this.userDefaultSettings();
    await this.fetchAlltemplates();
    await this.checkSendDocumentRedirection();
    if (this.isEdit) {
      await this.fetchWorkflowId();
      // this.userDefaultSettings();
    } else {
      this.workflow_data_id = this.$route.params.workflow_data_id;
      await this.fetchWorkflowDataById();
    }
    this.$store.dispatch("auth/fetchInitialSignature"),
      this.$store.dispatch("auth/fetchUserSignature"),
      console.log("this.allTemplates",this.allTemplates,this.getWorkflowDataCreatedData)
    this.loading = false;
  },
  methods: {
    prepareTempData() {
      this.is_prepare = true;
      bus.$emit("isPrepare",{
        is_prepare : this.is_prepare
      })

    },
    async goToTemplates(){
    
      this.$router.push({
        name:'edit-configure-template-image',
        params: {
          configurable_document_id : this.getDocumnetSentDocumnet._id,
         // document_id: 
        },
      })
    },
     getContactTypeName(data){
      console.log(data,this.getEntityByNames)
      const idToNameMap = {};
      let result
      if(this.getEntityByNames && this.getEntityByNames.length){
        this.getEntityByNames.forEach((item) => {
          idToNameMap[item._id] = item.name;
        });
        console.log("idToNameMap",idToNameMap[data.contact_type])
          result = idToNameMap[data.contact_type] || "-- --"
          console.log("asasas",result)
        return result
      }else{
        return result = '-- --'
      }
      
    },
    async openTemplatePreviewPopup(step,index){
      this.tempUsersLoading = true
      this.loadingText = 'Fetching Template Users...'
      this.templateDocumentUsers = []
      this.showTemplateUsersDialog = true
      let selectedConfigurableDocument = this.templetes_users_data.find(
          (e) => e._id === step.configurable_document_id
        );
        this.templateDocumentUsers = selectedConfigurableDocument.document_users
        const contactTypeIds = [];
          for (const user of selectedConfigurableDocument.document_users) {
              if (user.type !== 'SENDER') {
                  const contactTypeId = user.contact_type;
                  if (contactTypeId) {
                      contactTypeIds.push(contactTypeId);
                  }
              }
          }
          let params = {
            contactTypes : contactTypeIds
          }
       await this.$store.dispatch('entities/fetchAllEntityNames',params)
       this.tempUsersLoading = false
      console.log('-------------------------------',step,this.getEntityByNames,contactTypeIds,selectedConfigurableDocument,step,index)
    },
    closeAlertPopup() {
        this.names = [];
        this.showSignErrorElementDialog = false;
        this.showFinishErrorElementDialog = false;
      },
      closeTemplateUsersPopup(){
        this.showTemplateUsersDialog = false
      },
    async checkWorkflowUsers() {
      if (!this.checkPermissionByPermissionsName('sendWorkflow')) {
        this.$notify.error({
          title: "Error",
          message: "Permission denied for sending workflow. Please contact the owner!"
        });
      } else if (this.selectedWorkflow.length) {
        this.is_submit = true;
        let checkData = this.checkUsersData();
        console.log("--------------",checkData,this.contactUsers,this.getWorkflowDataCreatedData,this.getCreatedworkflowDataById)
        if (checkData || this.contactUsers && this.contactUsers.length == 0) {
        let params = this.prepareParams();
          console.log("params ", params);
          await this.$store.dispatch(
            "templateWorkflow/updateWorkFlowDataById",
            params
          );
          this.activeStep = 2;
        } else {
          if (!this.workflowName) {
            this.$message.error("Workflow name is required");
            return false;
          } else {
            this.$message.error("Oops, Please configure users.");
          }
        }
      } else {
        this.$message.error("Oops, Please add templates.");
      }
    },

    async reloadPage() {
      if (this.workflowSuccessStatus) {
        let query = await this.getNavigationQuery(this.$route.query);
        this.$router.push({
          path: "/workflows",
          query,
        });
      }
      // this.$router.go();
    },
    async goToActionRequiredDocument() {
      let query = await this.getNavigationQuery(this.$route.query);
      this.$router.push({
        name: "employee-documents-custom-document",
        params: {
          employee_document_id: this.currentActiveDocument,
        },
        query: {
          ...query,
          workflow_id: this.workflow_data_id,
          ...this.isMenuNavigation(),
        },
      });
    },
    async fetchUsersDataWhenUsersAreConfigured(){
        await this.getTemplateUsers()
        const matchedUsers = this.templetes_users_data.filter(userObj => {
            return this.getCreatedworkflowDataById.templates_used.some(templateUsed => {
                return templateUsed.template_id._id === userObj._id;
            });
        });
        let order = this.getCreatedworkflowDataById.enforce_workflow_order
        if (order) {
          this.enforceWorkflowOrderValue = order;
        } else {
          this.enforceWorkflowOrderValue = false;
        }
        let contactUsers = this.contactUsers
        if (contactUsers) {
          this.contactUsers = contactUsers;
        }
        const matchedUsersWithContacts = matchedUsers.map(documentObj => {
        const documentUsers = documentObj.document_users;
        const mappedUsers = documentUsers.map(documentUser => {
          if (!documentUser.email || documentUser.email === "") {
              const matchedContact = contactUsers.find(contactUser => {
                  return contactUser.name === documentUser.name;
              });
              if (matchedContact) {
                  return {
                      ...documentUser,
                      email: matchedContact.email,
                      first_name: matchedContact.first_name,
                      last_name: matchedContact.last_name
                  };
              }
          }
          return documentUser;
            });
            return {
                ...documentObj,
                document_users: mappedUsers
            };
        });
        this.allUsersData = matchedUsersWithContacts
        console.log(matchedUsersWithContacts);
        console.log("--------------------------",this.allUsersData,order,contactUsers)
      },
    fetchAllUsersData({ allUser, order, contactUsers }) {
      if (allUser) {
        this.allUsersData = [];
        this.allUsersData = allUser;
      }
      if (order) {
        this.enforceWorkflowOrderValue = order;
      }else{
         this.enforceWorkflowOrderValue = false
      }
      if (contactUsers) {
        this.contactUsers = contactUsers;
      }
    },
    openTemplateSettingsPopup(step, index){
      console.log("-----syeo--------",step,index)
      this.docSettingsVisible = true;
      this.configIdForSettings = step.configurable_document_id;
    },
    async closeSettingsAndSend() {
      this.docSettingsVisible = false;
    },
    getTimeZone() {
      let date1 = new Date();
      var sign = date1.getTimezoneOffset() > 0 ? "-" : "+";
      var offset = Math.abs(date1.getTimezoneOffset());
      var hours =
        Math.floor(offset / 60) < 10
          ? "0" + Math.floor(offset / 60)
          : Math.floor(offset / 60);
      var minutes = offset % 60 < 10 ? "0" + (offset % 60) : offset % 60;
      let timezoneoffset = sign + hours + ":" + minutes;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return timezone + ", UTC" + timezoneoffset;
    },
    checkUsersData() {
      let emptyUser = [];
      let emailRe =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let NameRe = /[a-zA-Z]{1,}/ || /(?:[A-Za-z].?\d|\d.?[A-Za-z]){1,}/;
      if (!this.workflowName) {
        this.$message.error("Workflow name is required");
        return false;
      }
      if (this.allUsersData && this.allUsersData.length) {
        this.allUsersData.forEach((list) => {
          list.document_users.forEach((el) => {
            if (
              (!emailRe.test(el.email) ||
                !NameRe.test(el.first_name) ||
                !el.last_name ||
                !el.name ||
                !el.contact_type) &&
              el.type != "SENDER"
            ) {
              emptyUser.push(el);
            }
          });
        });
        if (emptyUser && emptyUser.length) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
    async checkSendDocumentRedirection() {
      try {
        await this.$store.dispatch("settings/fetchApplicationSettings");
        if (this.getApplicationSettings && this.getApplicationSettings.data) {
          if (this.getApplicationSettings.data.document_signature) {
            this.sender_auto_fill =
              this.getApplicationSettings.data.document_signature.allow_sender_auto_fill;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async createWorkflowData() {
      this.loading = true;
      this.loadingText = "Creating workflow data...";
      let templates_used = [];
      this.selectedWorkflow.forEach((e, index) => {
        templates_used.push({
          template_id: e.configurable_document_id,
          document_order: index + 1,
          type: "DOCUMENT",
        });
      });
      let params = {
        workflow_id: this.$route.params.workflow_id,
        enforce_workflow_order: this.enforceWorkflowOrderValue,
        templates_used: templates_used,
        name: this.workflowName,
        workflow_settings: this.settings,
        workflow_status: "DRAFT",
      };
      await this.$store.dispatch(
        "templateWorkflow/createNewWorkflowData",
        params
      );
      if (this.getWorkflowDataCreatedStatus) {
        this.$notify({
          title: "Success",
          message: "Workflow data created successfully",
          type: "success",
        });
        this.createdWorkflowDataId = null;
        this.createdWorkflowDataId = this.getWorkflowDataCreatedData._id;

        let query = await this.getNavigationQuery(this.$route.query);
        this.$router.push({
          name: "workflow-send",
          params: {
            workflow_data_id: this.getWorkflowDataCreatedData._id,
          },
          query: {
            ...query,
            activeStep: 1,
            ...this.isMenuNavigation(),
          },
        });
        setTimeout(() => {
          this.refreshDocument();
        }, 1000);
        this.loading = false;
      } else {
        this.$notify({
          title: "Error",
          message: "Error in sending workflow",
          type: "error",
        });
        this.loading = false;
      }
    },
    refreshDocument() {
      this.$router.go();
    },
    // async createWorkflowData({ allUser }) {
    //   this.is_submit = true;
    //   this.loading = true;
    //   this.sentDocumentSuccess = true;
    //   this.workflowSentStatus = true;
    //   let checkData = this.checkUsersData();
    //   if (checkData) {
    //     let templates_used = [];
    //     this.selectedWorkflow.forEach((e, index) => {
    //       templates_used.push({
    //         template_id: e.configurable_document_id,
    //         document_order: index + 1,
    //         type: "DOCUMENT",
    //       });
    //     });
    //     let params = {
    //       workflow_id: this.$route.params.workflow_id,
    //       enforce_workflow_order: this.enforceWorkflowOrderValue,
    //       templates_used: templates_used,
    //       name: this.workflowName,
    //       workflow_settings: this.settings,
    //     };
    //     await this.$store.dispatch(
    //       "templateWorkflow/createNewWorkflowData",
    //       params
    //     );
    //     if (this.getWorkflowDataCreatedStatus) {
    //       this.$notify({
    //         title: "Success",
    //         message: "Workflow data created successfully",
    //         type: "success",
    //       });
    //       this.createdWorkflowDataId = null;
    //       this.createdWorkflowDataId = this.getWorkflowDataCreatedData._id;
    //       await this.createContacts();
    //       await this.createWorkflowDocuments(params, allUser);
    //     } else {
    //       this.$notify({
    //         title: "Error",
    //         message: "Error in sending workflow",
    //         type: "error",
    //       });
    //       this.loading = false;
    //     }
    //   } else {
    //     this.loading = false;
    //     this.sentDocumentSuccess = false;
    //     this.workflowSentStatus = false;
    //   }
    // },
    async updateUserEntityData(allData) {
      try {
        await this.$store.dispatch(
          "templatesData/updateUserEntityData",
          allData
        );
      } catch (err) {
        console.log("error", err);
      }
    },
    async updateContacts(contactsData) {

      let allContacts = [];
      this.contactUsers.forEach((user) => {
        let contactUpdateDetails = {};
        if (user.contact) {
          contactUpdateDetails["contact_id"] =
            user.contact && user.contact._id ? user.contact._id : user.contact;
        }
        if (user.entity_data_id && user.entity_data_id._id) {
          contactUpdateDetails["entity_data_id"] = user.entity_data_id._id;
        }
        if (user.contact_type) {
          contactUpdateDetails["contact_type"] = user.contact_type;
        }
        contactUpdateDetails["email"] = contactsData&&contactsData[user.email] &&contactsData[user.email]["email"]?contactsData[user.email]["email"]:user.email
        contactUpdateDetails["first_name"] =
          contactsData &&
          contactsData[user.email] &&
          contactsData[user.email]["first_name"]
            ? contactsData[user.email]["first_name"]
            : user && user.contact && user.contact.first_name
            ? user.contact.first_name
            : user.first_name;
        contactUpdateDetails["last_name"] =
          contactsData &&
          contactsData[user.email] &&
          contactsData[user.email]["last_name"]
            ? contactsData[user.email]["last_name"]
            : user && user.contact && user.contact.last_name
            ? user.contact.last_name
            : user.last_name;
        contactUpdateDetails["phone"] = 
        contactsData && 
        contactsData[user.email] &&
        contactsData[user.email]["phone"] ?
        contactsData[user.email]["phone"] : 
        user && user.contact && user.contact.phone
            ? user.contact.phone
            : user.phone;
              
        if (
          contactsData &&
          contactsData[user.email] &&
          contactsData[user.email]["phone"]
        ) {
          let re =
            /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/;
          if (re.test(contactsData[user.email]["phone"])) {
            contactsData[user.email]["phone_number"] =
              contactsData[user.email]["phone"];
          }
        }
        contactUpdateDetails = {
          ...contactsData[user.email],
          ...contactUpdateDetails,
        };
        if (contactUpdateDetails && contactUpdateDetails.email) {
          allContacts.push(contactUpdateDetails);
        }
      });
      console.log("allContacts", allContacts);
      await this.$store.dispatch("contacts/updateDocumentPrimaryDetails", {
        params: allContacts,
      });
     
    },
    async createWorkflowDocuments({
        allUser,
        templateData,
        contactsData,
        entityData,
        finish_sender_side
      }) {
        console.log("-------------1919",allUser,contactsData)
        await this.updateContacts(contactsData);
        this.updateUserEntityData(entityData);
        allUser = allUser.map((temp) => {
          let tempData = templateData.find((e) => e && e.id == temp._id);
          if (tempData && tempData.elements) {
            temp = { ...temp, ...{ fields: tempData.elements } };
          }
          return temp;
        });
        let params = {};
        let timeZone = this.getTimeZone();
        let data = [];
        let templates_used = [];
        console.log("this.selectedWorkflow-this.selectedWorkflow",this.selectedWorkflow)
        this.selectedWorkflow.forEach((e, index) => {
          templates_used.push({
            template_id: e.configurable_document_id,
            document_order: index + 1,
            type: "DOCUMENT",
          });
        });
        templates_used.forEach((element) => {
          allUser.forEach((e) => {
            if (element.template_id == e._id) {
              data.push(e);
            }
          });
        });
        this.unfilledFields = [];
        const filteredFields = [];
        for (const doc of data) {
          const signers = doc.document_users.filter(user => user.type !== 'SENDER' && user.user_type === 'SIGNER');
          if (!signers.length) {
            let hasUnfilledFields = false;
            for (const field of doc.fields) {
              if (
                field.filled_by === 'SENDER' && 
                field.required && 
                !field.value &&
                !this.unWantedFields.includes(field.type)) {
                filteredFields.push({
                  placeholder: field.placeholder,
                  label: field.userData.user + '---' + field.label,
                  documentName: doc.name,
                });
                hasUnfilledFields = true;
              }if (
                field.filled_by === 'SENDER' && 
                field.required && 
                !field.source &&
                ['SIGNATURE', 'my-signature'].includes(field.type)) {
                field.source = this.getAuthenticatedUser.signature
              }if (
                field.filled_by === 'SENDER' && 
                field.required && 
                !field.value &&
                ['INITIAL', 'MY_INITIAL'].includes(field.type)) {
                field.value = this.getInitialUrl
              } if (field.type == "DATE_SIGNED") {
                let date = new Date();
                field.value = date;
              }
            }
            if (!hasUnfilledFields) {
              doc.finish_sender_side = true;
            }
          }
        }
        console.log(filteredFields);
        if(filteredFields && filteredFields.length){
          this.unfilledFields = filteredFields
          this.showFinishErrorElementDialog = true;
        }else{
        console.log("-------data----------",data)
        this.sentDocumentSuccess = true;
        this.workflowSentStatus = true;
        this.showFinishErrorElementDialog = false;
        params.workflow_users = data;
        params.workflow_data_id = this.workflow_data_id;
        params.sender_time_zone = timeZone;
        params.enforce_workflow_order = this.enforceWorkflowOrderValue;
        params.name = this.workflowName;
        params.workflow_settings = this.settings;
        params.senderFilledAllFields = this.sender_auto_fill;
        params.finish_sender_side = finish_sender_side
        console.log("parmas------params", params);
        await this.$store.dispatch("templateWorkflow/workflowSend", params);
        if (this.getWorkflowsendStatus) {
          this.$notify({
            title: "Success",
            message: "Workflow send successfully",
            type: "success",
          });
          this.workflowSentStatus = false;
          this.workflowSuccessStatus = true;
          this.currentActiveDocument = this.getWorkflowsendData[0]["document_id"];
        } else {
          this.$notify({
            title: "Error",
            message: "Error in sending workflow",
            type: "error",
          });
        }
      }
      },
    // async createWorkflowDocuments({
    //     allUser,
    //     templateData,
    //     contactsData,
    //     entityData,
    //     finish_sender_side
    //   }) {
    //     await this.updateContacts(contactsData);
    //     this.updateUserEntityData(entityData);
    //     allUser = allUser.map((temp) => {
    //       let tempData = templateData.find((e) => e && e.id == temp._id);
    //       if (tempData && tempData.elements) {
    //         temp = { ...temp, ...{ fields: tempData.elements } };
    //       }
    //       return temp;
    //     });
    //     console.log("-------------1919------------",allUser,templateData)
    //     let params = {};
    //     let timeZone = this.getTimeZone();
    //     let data = [];
    //     let templates_used = [];
    //     console.log("this.selectedWorkflow-this.selectedWorkflow",this.selectedWorkflow)
    //     this.selectedWorkflow.forEach((e, index) => {
    //       templates_used.push({
    //         template_id: e.configurable_document_id,
    //         document_order: index + 1,
    //         type: "DOCUMENT",
    //       });
    //     });
    //     templates_used.forEach((element) => {
    //       allUser.forEach((e) => {
    //         if (element.template_id == e._id) {
    //           data.push(e);
    //         }
    //       });
    //     });
    //     // let savedData = []
    //     // templates_used.forEach((element) => {
    //     //   this.getWorkflowSavingData.forEach((e) => {
    //     //     console.log("eeeeeeeeeeeeee",e,element)
    //     //     if (element.template_id === e.templateID) {
    //     //       savedData.push(e);
    //     //     }
    //     //   });
    //     // });
    //     // savedData.forEach(e => {
    //     //   if(e?.elements){
    //     //     (e.elements || []).forEach(fl => {

    //     //     });
    //     //   }
    //     // })
    //     //console.log('---------------------',savedData,this.getWorkflowSavingData,allUser,data)
    //     this.unfilledFields = [];
    //     const filteredFields = [];
    //     // for (const doc of data) {
    //     //   const signers = doc.document_users.filter(user => user.type !== 'SENDER' && user.user_type === 'SIGNER');
    //     //   if (!signers.length) {
    //     //     let hasUnfilledFields = false;
    //     //     for (const fieldsData of savedData) {
    //     //       for(const field of fieldsData.elements) {
    //     //       console.log("savedData",field)
    //     //       if (
    //     //         field.filled_by === 'SENDER' && 
    //     //         field.required && 
    //     //         !field.value &&
    //     //         !['SIGNATURE', 'INITIAL', 'my-signature', 'MY_INITIAL'].includes(field.type)) {
    //     //         filteredFields.push({
    //     //           placeholder: field.placeholder,
    //     //           label: field.userData.user + '---' + field.label,
    //     //           documentName: doc.name,
    //     //         });
    //     //         console.log("----------",field)
    //     //         hasUnfilledFields = true;
    //     //       }if (
    //     //         field.filled_by === 'SENDER' && 
    //     //         field.required && 
    //     //         (!field.source) &&
    //     //         ['SIGNATURE', 'my-signature'].includes(field.type)) {
    //     //         field.source = this.getAuthenticatedUser.signature
    //     //       }if (field.type == "DATE_SIGNED") {
    //     //         let date = new Date();
    //     //         field.value = date;
    //     //       }if (
    //     //         field.filled_by === 'SENDER' && 
    //     //         field.required && 
    //     //         (!field.value) &&
    //     //         ['INITIAL',  'MY_INITIAL'].includes(field.type)) {
    //     //         field.value = this.getInitialUrl
    //     //       }
    //     //     }
    //     //     }
    //     //     if (!hasUnfilledFields) {
    //     //       doc.finish_sender_side = true;
    //     //     }
    //     //   }
    //     // }
    //     console.log(filteredFields);
    //     if(filteredFields && filteredFields.length){
    //       this.unfilledFields = filteredFields
    //       this.showFinishErrorElementDialog = true;
    //     }else{
    //     console.log("-------data----------",data)
    //     this.sentDocumentSuccess = true;
    //     this.workflowSentStatus = true;
    //     this.showFinishErrorElementDialog = false;
    //     params.workflow_users = data;
    //     params.workflow_data_id = this.workflow_data_id;
    //     params.sender_time_zone = timeZone;
    //     params.enforce_workflow_order = this.enforceWorkflowOrderValue;
    //     params.name = this.workflowName;
    //     params.workflow_settings = this.settings;
    //     params.senderFilledAllFields = this.sender_auto_fill;
    //     params.finish_sender_side = finish_sender_side
    //     console.log("parmas------params", params);
    //     await this.$store.dispatch("templateWorkflow/workflowSend", params);
    //     if (this.getWorkflowsendStatus) {
    //       this.$notify({
    //         title: "Success",
    //         message: "Workflow send successfully",
    //         type: "success",
    //       });
    //       this.workflowSentStatus = false;
    //       this.workflowSuccessStatus = true;
    //       this.currentActiveDocument = this.getWorkflowsendData[0]["document_id"];
    //     } else {
    //       this.$notify({
    //         title: "Error",
    //         message: "Error in sending workflow",
    //         type: "error",
    //       });
    //     }
    //   }
    //   },
    checkIsEdit() {
      if (
        this.$route &&
        this.$route.name &&
        this.$route.name == "workflow-edit"
      ) {
        this.isEdit = true;
      }
    },
    checkUpdateOrCreate() {
  if (
    this.getWorkflowDataById &&
    this.getWorkflowDataById.name &&
    this.getWorkflowDataById.name != this.workflowName &&
    !this.checkPermissionByPermissionsName('renameWorkflow')
  ) {
    this.$notify.error({
      title: "Error",
      message:
        "Permission denied for renaming the document. Please contact the owner!",
    });
  } else if (
    this.getWorkflowDataById &&
    this.getWorkflowDataById.name &&
    this.getWorkflowDataById.name != this.workflowName &&
    this.checkPermissionByPermissionsName('renameWorkflow')
  ) {
    // Duplicate else condition for renaming permission
    if (this.selectedWorkflow.length) {
      if (this.isEdit) {
        this.updateWorkflow();
      } else {
        this.updateWorkflowData();
      }
      this.isUpdate = true;
    } else {
      this.$message.error("Oops, Please add templates.");
    }
  } else if (!this.checkPermissionByPermissionsName('sendWorkflow')) {
    this.$notify.error({
      title: "Error",
      message:
        "Permission denied for sending workflow. Please contact owner.!",
    });
  } else {
    // if(this.getWorkflowDataCreatedData && this.getWorkflowDataCreatedData.workflow_users && this.getWorkflowDataCreatedData.workflow_users.length == 0){
    //   this.activeStep = 2;
    //   this.fetchUsersDataWhenUsersAreConfigured()
    //   console.log("---------coming into workflow users------------------------")
    // }else{
    // Original else condition for editing permission
    if (this.selectedWorkflow.length) {
      if (this.isEdit) {
        this.updateWorkflow();
      } else {
        this.updateWorkflowData();
      }
      this.isUpdate = true;
    } else {
      this.$message.error("Oops, Please add templates.");
    //}
  }
  }
},


   async checkUpdateData() {
    if(!this.selectedWorkflow.length){
      this.$message.error("Please add alteast one template.!")
    }else{
      this.isUpdate = false;
      if (
        this.getWorkflowDataById.name &&
        this.getWorkflowDataById.name != this.workflowName &&
        !this.checkPermissionByPermissionName('renameWorkflow')
      ) {
          this.$notify.error({
            title: "Error",
            message:
              "Permission denied for rename document.Please contact owner.!",
          });
      } else {
        if (this.workflowName.length == 0) {
          this.$message.error("Workflow name is required");
        } else {
          if (this.isEdit) {
            this.updateWorkflow();
          } else {
            this.updateWorkflowData();
          }
        }
      }
    }
      
    },

    async updateWorkflowData() {
      this.loading = true;
      this.loadingText = "Updating workflow data...";
      let params = this.prepareParams();
      await this.$store.dispatch(
        "templateWorkflow/updateWorkFlowDataById",
        params
      );
      if (this.getWorkflowCreatedDataUpdateStatus) {
        this.$notify({
          title: "Success",
          message: "Workflow data updated successfully",
          type: "success",
        });
        if (this.isUpdate) {
          this.activeStep = 1;
        }
      } else {
        this.$notify({
          title: "Error",
          message: "Error in updating workflow",
          type: "error",
        });
      }
      this.loading = false;
    },
    async updateWorkflow() {
      this.loading = true;
      this.loadingText = "Updating workflow data...";
      let params = this.prepareParams();
      await this.$store.dispatch("templateWorkflow/updateWorkFlowById", params);
      if (this.getWorkflowDataUpdateStatus) {
        this.loading = false;
        this.$notify({
          title: "Success",
          message: "Workflow update successfully",
          type: "success",
        });
        if (this.isUpdate) {
          this.createWorkflowData();
        }
        this.loading = false;
      } else {
        this.$notify({
          title: "Error",
          message: "Error in updating workflow",
          type: "error",
        });
        this.loading = false;
      }
    },
    async fetchWorkflowDataById() {
      try {
        this.modalLoading = true;
        this.loadingText = "Fetching data...";
        let id = this.workflow_data_id;
        await this.$store.dispatch(
          "templateWorkflow/fetchWorkflowDataById",
          id
        );
        if(this.getCreatedworkflowDataById.workflow_users && this.getCreatedworkflowDataById.workflow_users.length){
          this.contactUsers = this.getCreatedworkflowDataById.workflow_users
        }
        if(
            this.getCreatedworkflowDataById && 
            this.getCreatedworkflowDataById.workflow_status && 
            this.getCreatedworkflowDataById.workflow_status == 'DRAFT' && 
            ((this.getCreatedworkflowDataById.workflow_users.length != 0) ||
            (this.getCreatedworkflowDataById.workflow_users.some(user => user && user.email && user.email != "")))
            ){
              await this.fetchUsersDataWhenUsersAreConfigured()
            }
        setTimeout(() => {
          this.modalLoading = false;
        }, 600);
        if (this.getCreatedworkflowStatus) {
          console.log(
            "this.getCreatedworkflowStatus.workflow_status",
            this.getCreatedworkflowDataById.workflow_id
          );
          if (this.getCreatedworkflowDataById.workflow_status != "DRAFT") {
            this.usedWorkflow = true;
          }
          
          this.setUserSettings(
            this.getCreatedworkflowDataById.workflow_settings
          );
          this.workflowName = this.getCreatedworkflowDataById.name;
          await this.setSelectedTemplates(
            this.getCreatedworkflowDataById.templates_used
          );
        }
        this.modalLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchWorkflowId() {
      this.modalLoading = true;
      this.loadingText = "Fetching workflows data...";
      let id = this.$route.params.workflow_id;
      await this.$store.dispatch("templateWorkflow/fetchWorkflowById", id);
      if (this.getWorkflowDataById) {
        this.setUserSettings(this.getWorkflowDataById.workflow_settings);
        this.workflowName = this.getWorkflowDataById.name;
        this.setSelectedTemplates(this.getWorkflowDataById.templates_used);
      } else {
        this.modalLoading = false;
      }
    },
    setSelectedTemplates(tempList) {
      tempList.forEach((e) => {
        this.allTemplates.forEach((el) => {
          if (e.template_id._id == el.configurable_document_id) {
            this.selectedWorkflow.push(el);
          }
        });
      });
      this.modalLoading = false;
    },
    async userDefaultSettings() {
      await this.$store.dispatch("settings/fetchUserDocumentSettings");
      if (this.getUserSettings) {
        this.setUserSettings(this.getUserSettings);
      }
    },
    setUserSettings(data) {
      this.settings.expiration_settings.send_expiration_reminder_status =
        data.expiration_settings &&  data.expiration_settings.send_expiration_reminder_status;
      this.settings.expiration_settings.repeat_expiration_reminder_status =
       data.expiration_settings && data.expiration_settings.repeat_expiration_reminder_status;
      this.settings.expiration_settings.expire_documents_in_days =
       data.expiration_settings && data.expiration_settings.expire_documents_in_days;
      this.settings.expiration_settings.send_first_reminder_in_days =
       data.expiration_settings && data.expiration_settings.send_first_reminder_in_days;
      this.settings.expiration_settings.repeat_expiration_reminder =
       data.expiration_settings && data.expiration_settings.repeat_expiration_reminder;
      this.settings.auto_remainders_settings.repeat_reminder =
       data.auto_remainders_settings && data.auto_remainders_settings.repeat_reminder;
      this.settings.auto_remainders_settings.send_first_reminder_in_days =
       data.auto_remainders_settings && data.auto_remainders_settings.send_first_reminder_in_days;
      this.settings.auto_remainders_settings.repeat_reminder_status =
       data.auto_remainders_settings && data.auto_remainders_settings.repeat_reminder_status;
      this.settings.auto_remainders_settings.first_reminder_status =
       data.auto_remainders_settings && data.auto_remainders_settings.first_reminder_status;
      this.settings.secure_e_sign.allow_document_e_sign =
        data.secure_e_sign && data.secure_e_sign.allow_document_e_sign
          ? data.secure_e_sign.allow_document_e_sign
          : false;
      this.settings.update_entity_data = data.update_entity_data
        ? data.update_entity_data
        : 0;
      this.settings.email_attachement.attach_pdf =
        data.email_attachement && data.email_attachement.attach_pdf
          ? data.email_attachement.attach_pdf
          : false;
          data.auto_remainders_settings &&  data.auto_remainders_settings.first_reminder_status;
        this.settings.document_generation_settings.add_document_generated_id =
        data.document_generation_settings &&  data.document_generation_settings.add_document_generated_id;
           this.settings.document_generation_settings.add_page_number =
         data.document_generation_settings && data.document_generation_settings.add_page_number;
      this.changeExpireDate();
    },
    changeExpireDate() {
      this.settings.expiration_settings.document_expired_date =
        this.$moment().add(
          this.settings.expiration_settings.expire_documents_in_days,
          "days"
        );
    },
    closeWorkflowSettings() {
      this.visibleSettings = false;
    },
    visibleSettingsVisible() {
      this.visibleSettings = true;
    },
    prepareParams() {
      let data = {};
      data.templates_used = [];
      this.selectedWorkflow.forEach((e, index) => {
        data.templates_used.push({
          template_id: e.configurable_document_id,
          document_order: index + 1,
          type: "DOCUMENT",
        });
      });
      data.name = this.workflowName;
      // data.workflow_settings = this.settings;
      if (this.isEdit) {
        data.id = this.$route.params.workflow_id;
      } else {
        if (this.contactUsers) {
          data.workflow_users = this.contactUsers;
        }
        data.id = this.workflow_data_id;
      }
      return data;
    },
    checkIsUsedTemplate(item) {
      let exists = this.selectedWorkflow.find((e) => {
        if (
          e &&
          e._id &&
          item &&
          item._id &&
          item._id.toString() == e._id.toString()
        ) {
          return e;
        }
      });
      if (exists) {
        return true;
      }
      return false;
    },
    // openTemplateSettingsPopup(step, index){
    //   console.log("-----syeo--------",step,index)
    //   this.docSettingsVisible = true;
    //   this.configIdForSettings = step.configurable_document_id;
    // },
    // async closeSettingsAndSend() {
    //   this.docSettingsVisible = false;
    // },
    deleteTemplate(item, i) {
        this.selectedWorkflow.splice(i, 1);
  
        let selectedConfigurableDocument = this.templetes_users_data.find(
          (e) => e._id === item.configurable_document_id
        );
  
        if (
          selectedConfigurableDocument &&
          selectedConfigurableDocument.document_users &&
          selectedConfigurableDocument.document_users.length
        ) {
          const filteredUsers =
            selectedConfigurableDocument.document_users.filter(
              (user) => user.type !== "SENDER" && !user.constant_user
            );
          this.checkDocumentUsers = this.checkDocumentUsers.filter(
            (user) =>
              !filteredUsers.some((filteredUser) => filteredUser._id === user._id)
          );
        }
      },
    addTemplate(item) {
        console.log("------item---",item)
        let selectedConfigurableDocument = this.templetes_users_data.find(
          (e) => e._id === item.configurable_document_id
        );
        if (
          selectedConfigurableDocument &&
          selectedConfigurableDocument.document_users &&
          selectedConfigurableDocument.document_users.length
        ) {
          let filteredUsers = selectedConfigurableDocument.document_users.filter(
            (user) => user.type !== "SENDER" && !user.constant_user
          );
          this.checkDocumentUsers = this.checkDocumentUsers.concat(filteredUsers);
        }
        this.selectedWorkflow.push(item);
        console.log(item);
      },
    async fetchAlltemplates() {
      this.modalLoading = true;
      this.loadingText = "Fetching templates...";
      let params = {
        get_all: true,
        get_wf: true,
        status: "ACTIVE",
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      await this.$store.dispatch("documents/fetchAllTemplates", params);
      this.modalLoading = false;
      if (this.getAllTemplates) {
        this.allTemplates =
          this.getAllTemplates && this.getAllTemplates.data
            ? this.getAllTemplates.data
            : [];
        await this.getTemplateUsers();
      }
      this.loading = false;
    },
    async getTemplateUsers() {
      try {
        let params = {
          templates_used: this.allTemplates.flatMap(
            (e) => e.configurable_document_id
          ),
        };
        await this.$store.dispatch(
          "templateWorkflow/fetchSelectedWorkflowUsers",
          params
        );
        if (this.getSelectedWorkflowUsers) {
          this.templetes_users_data = this.getSelectedWorkflowUsers;
        }
      } catch (err) {
        console.log(err);
      }
    },
    gotoBuilder() {
      let checkData = this.checkUsersData();
      if (
        !this.isEdit &&
        checkData &&
        this.contactUsers &&
        this.contactUsers.length
      ) {
        this.isUpdate = false;
        this.updateWorkflowData();
      }
      this.activeStep = 0;
    },
    goBack() {
      this.$confirm("Are you sure to Exit?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async () => {
      if(this.activeStep == 2){
        this.dialogForSavingData = true;
       }else{
        if (this.$route.query && this.$route.query.group) {
          let query = await this.getNavigationQuery(this.$route.query);
          this.$router.push({
            name: "Workflows",
            query,
          });
        } else if (
          this.$route.query &&
          this.$route.query.routeType &&
          this.$route.query.t
        ) {
          let query = await this.getNavigationQuery(this.$route.query);
          this.$router.push({
            name: "Workflows-Data",
            params: {
              workflow_id: this.isEdit
                ? this.$route.params.workflow_id
                : this.getCreatedworkflowDataById.workflow_id,
            },
            query: {
              ...query,
              ...this.isMenuNavigation(),
            },
          });
        }
        else {
          let query = await this.getNavigationQuery(this.$route.query);
          this.$router.push({
            name: "Workflows",
            query,
          });
        }
      }
      });
    },
    isMenuNavigation() {
      if (
        this.$route.query &&
        this.$route.query.routeType &&
        this.$route.query.t
      ) {
        return {
          routeType: this.$route.query.routeType,
          t: this.$route.query.t,
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    search_string: function (val) {
      this.search_string = val;
      this.fetchAlltemplates();
    },
  },
  // beforeDestroy() {
  //   this.$store.commit("documents/setAllTemplates", null, { root: true });
  //   this.$store.commit("documents/setAddWorkflowContactStatus", null, {
  //     root: true,
  //   });
  //   this.$store.commit("templateWorkflow/setWorkflowCreatedData", null, {
  //     root: true,
  //   });
  //   this.$store.commit("templateWorkflow/setWorkflowDataById", null, {
  //     root: true,
  //   });
  //   //this.$store.commit("templateWorkflow/setWorkflowDataUpdateStatus",null,{root:true})
  //   this.$store.commit("templateWorkflow/setWorkflowDataCreatedData", null, {
  //     root: true,
  //   });
  //   this.$store.commit("templateWorkflow/setWorkflowDataCreatedStatus", null, {
  //     root: true,
  //   });
  //   this.$store.commit("templateWorkflow/setWorkflowsendStatus", null, {
  //     root: true,
  //   });
  //   this.$store.commit("templateWorkflow/setWorkflowsendData", null, {
  //     root: true,
  //   });
  //   this.$store.commit("templateWorkflow/setSelectedWorkflowUsers", null, {
  //     root: true,
  //   });
  //   this.$store.commit("templateWorkflow/setCreatedworkflowDataById", null, {
  //     root: true,
  //   });
  //   //this.$store.commit("templateWorkflow/setWorkflowCreatedDataUpdateStatus",null,{root:true})
  //   this.$store.commit("settings/setUserSettings", null, { root: true });
  // },
};
</script>

<style lang="scss" scoped>
.inner-navbar {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 100;
  background-color: #ffffff;
  border: 1px solid #efefef;
  margin-bottom: 0 !important;

  & > .d-flex {
    .back-btn {
      height: inherit;
    }
  }

  .left-block {
    .el-input__inner {
      min-width: 250px;
      max-width: 300px;
      background: #fff;
      height: 47px;
    }
  }
}
.sidemenu {
  height: 560px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
}
.sidemenu::-webkit-scrollbar {
  width: 0.7em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.form-builder {
  .workflow-steps {
    margin-top: 30px;
    #field-group {
      & > div:last-child {
        padding-bottom: 35px;
        position: relative;
        &:after {
          content: "END";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 70px;
          display: block;
          margin: 0 auto;
          padding: 0.5em 1.5em;
          border-radius: 5px;
          border: 1px dashed #ff3a79;
          text-align: center;
          font-weight: 600;
          letter-spacing: 1px;
          color: #ff3a79;
        }
      }
    }
  }
  // height: calc(100vh) !important;
  //   scroll-padding-inline-start: 10px;
  .each-step {
    .name-box {
      border-left: 1px solid #ebeef5;
      border-right: 1px solid #ebeef5;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -3em;
      left: calc(50% - 1px);
      width: 2px;
      height: 3em;
      background-color: #409eff;
    }
    #field-group {
      & > div:last-child {
        padding-bottom: 35px;
        position: relative;
        &:after {
          content: "END";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 70px;
          display: block;
          margin: 0 auto;
          padding: 0.5em 1.5em;
          border-radius: 5px;
          border: 1px dashed #ff3a79;
          text-align: center;
          font-weight: 600;
          letter-spacing: 1px;
          color: #ff3a79;
        }
      }
    }

    .arrow-down {
      position: absolute;
      left: calc(50% - 8px);
      bottom: -3em;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #409eff;
      z-index: 10;
    }

    cursor: pointer;
    font-style: normal;
    font-weight: 300;
    position: relative;
    z-index: 10;
    display: block;
    max-width: 30em;
    background: #fdfdfd;
    border: 1px solid #f754a2;
    box-sizing: border-box;
    border-radius: 3px;
    color: #303133;
    padding: 0;
    margin: 0 auto 3em;
    height: 62px;
    .img-fluid {
      width: 25px;
    }
    .delete-btn {
      background: transparent !important;
      border: transparent;
      color: #f754a2;
    }.setting-btn {
      background: transparent !important;
      border: transparent;
      color: #54a8f7;
    }
  }
  .emp-box {
    border: 1px dashed #d2d2d2;
    color: #4b545c;
    font-style: normal;
    .head {
      font-weight: 500;
      font-size: 14px;
    }
    .text {
      font-size: 14px;
    }
  }
  background-color: white;
  width: 100%;
  height: 100%;
}
.right-temp-block {
  background: #ffffff;
  //   text-align: center;
  // height: calc(100vh);
  border: 1px solid #dbe5ee;
  .temp {
    color: #4c4b4e;
    font-style: normal;
    font-weight: normal;
  }
  .temp-box {
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 7px;
    color: #606266;
    width: 100%;
    text-align: left;
    &.is-disabled {
      background-color: #efefef;
      border-color: #e8e5e5;
      cursor: not-allowed;
    }
  }
  .img-fluid {
    width: 25px;
  }
  .icon-block {
    display: grid;
    grid-template-columns: 25px auto;
    gap: 10px;
    align-items: center;

    .icon-text {
      td {
        border-bottom: none !important;
        font-weight: 500;
        color: #000;
      }
    }
  }
}
.input-type-one {
  font-size: 16px;
}
.flow-top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 1000;
  background: white;
  color: #ffffff;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05);
  padding: 5px 10px;
  text-align: center;
}
.close-icon {
  cursor: pointer;
}
.workflow-editor-container {
  position: relative;
  top: 80px;
}
</style>
<style lang="scss">
.close-icon {
  margin-left: 5px;
  margin-top: 10px;
  button {
    padding-left: 11px !important;
    padding-right: 11px !important;
    .el-icon-back {
    }
  }
}
.workflow-edit-page {
  .doc-save-send-button {
    margin-top: 9px;
    height: 43px;
    @media (max-width: 991.98px) {
      padding-left: 5px !important;
      padding-right: 5px !important;
      margin-top: 9px;
      height: 43px;
    }
  }
  .btn-one {
    padding: 6px 10px;
    height: 39px;
    margin-top: 11px;
    width: 42px;
  }
  .flow-top-bar {
    display: flex;
    padding: 0px 15px 5px 8px;
    justify-content: space-between;
    .left-side {
      display: flex;
      .form-title {
        &.hide-title {
          @media (max-width: 400px) {
            display: none;
          }
        }
      }
      .close-icon {
        margin-right: 10px;
        margin-left: 0px;
      }
      .document-flow {
        min-width: 450px;
        @media (max-width: 1000px) {
          min-width: 420px;
        }
        @media (max-width: 800px) {
          min-width: 300px;
        }
        @media (max-width: 600px) {
          min-width: 180px;
        }
        @media (max-width: 400px) {
          min-width: 150px;
        }
        @media (max-width: 350px) {
          min-width: 120px;
        }
      }
    }
    .right-side {
      .settings-button {
        margin-left: 10px;
        margin-right: 3px;
      }
      .save-workflow-button {
        margin-right: 10px;
        margin-left: 5px;
      }
      .next-button {
        padding: 0px 10px;
        height: 44px;
        margin-top: 9px;
        margin-right: -6px;
        @media (max-width: 600px) {
          padding: 0px 5px;
        }
      }
      .vertical-three-dots-wrapper {
        margin-left: 10px;
        .vertical-three-dots {
          margin-top: 22px;
        }
      }
      .action-wrapper-type-two {
        margin-right: -5px;
      }
      .doc-send-button {
        padding: 10px 10px;
        @media (max-width: 600px) {
          padding: 10px 5px;
        }
        margin-top: 8px;
        margin-bottom: 4px;
      }
    }
  }
}
#drag-template-background {
  &.workflow-edit-page {
    &.mobile {
      .workflow-editor-container {
        .right-side-bar {
          top: 0px;
          width: 220px !important;
          .el-scrollbar {
            padding-top: 0px !important;
          }
        }
      }
    }
    .workflow-editor-container {
      .right-side-bar {
        top: 0px;
        @media (max-width: 1200px) {
          width: 220px !important;
        }
      }
    }
    .workflow-editor-container {
      display: flex;
      justify-content: space-between;
      .document-container-wrapper {
        width: 100%;
        // height: calc(100vh - 85px);
        .form-builder {
          .workflow-steps {
            margin-top: 0px;
            padding: 20px 0px;
          }
        }
      }
      .right-side-bar {
        width: 337px;
        .el-scrollbar {
          .el-scrollbar__view {
            padding-left: 3px !important;
            padding-right: 3px !important;
            .el-button {
              margin-left: 0px !important;
              margin-right: 0px !important;
              .icon-block {
                & > div {
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                  white-space: break-spaces !important;
                  line-height: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.templateUsersSty{
  font-size:12px;
  font-family:inter, sans-serif
}
.tableSidemenu {
  height: 100%;
  overflow-x: scroll;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  //  direction: rtl;
}
.tableSidemenu::-webkit-scrollbar {
  width: 1em;
  height: 0.5em;
}
.tableSidemenu::-webkit-scrollbar-horizontal {
  /* Select the down or left scroll button when it's being hovered by the mouse */
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.tableSidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #9b9b9b;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.tableSidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.tableSidemenu::-webkit-scrollbar-button:single-button {
  width: 16px;
  height: 13px;
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
}

.tableSidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement {
  // border-width: 8px 8px 8px 8px;
  // border-color: transparent transparent #555555 transparent;
}

.tableSidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}
/* Down */
.tableSidemenu::-webkit-scrollbar-button:single-button:horizontal:increment {
  //  border-width: 8px 8px 8px 8px;
  // border-color: #555555 transparent transparent transparent;
}

.tableSidemenu::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}
</style>
