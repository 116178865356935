<template>
	<section
		class="configure-company-document"
		id="drag-template-background"
		v-loading="documentDataLoading"
	>
		<div class="inner-navbar" v-if="documentFormData">
			<el-row type="flex" align="middle" justify="center">
				<el-col :span="7">
					<el-button round icon="el-icon-back" @click="goToDocuments()"
						>Back to Documents</el-button
					>
				</el-col>
				<el-col :span="10">
					<h2 class="font-weight-normal blue-2">{{ documentFormData.name }}</h2>
				</el-col>
				<el-col :span="6">
					<div class="float-right">
						<el-button class="btn" type="success" @click="saveDocumentData()">
							<i class="el-icon-s-claim"></i> SAVE &amp; UPDATE
						</el-button>
					</div>
				</el-col>
			</el-row>
		</div>

		<div class="input-options">
			<el-row type="flex" justify="center">
				<el-col :span="17">
					<div v-loading="documentDataLoading">
						<div class="document-root">
							<div class="form-builder" ref="formBuilder">
								<div class="form-holder">
									<div class="form-image-holder">
										<img
											v-for="(image, index) of backgroundImages"
											:key="index"
											:src="image"
											class="page-image"
										/>
									</div>
									<div class="form-fields-holder" v-loading="loadingElements">
										<div v-for="(item, index) of this.elements" :key="index">
											<img
												v-if="item.type == 'logo'"
												:src="getLogoUrl"
												:style="getStyle(item)"
											/>

											<img
												v-if="item.type == 'my-signature'"
												:src="item.source"
												:style="getStyle(item)"
											/>

											<el-input
												:style="getStyle(item)"
												type="text"
												:placeholder="item.placeholder"
												v-if="item.type == 'SINGLE_LINE_TEXT'"
												v-model="item.value"
												:disabled="checkFieldAllow(item)"
												:ref="removeHashesFromKey(item.key)"
												:class="
													currentEnabledFieldKey == item.key ? 'active' : ''
												"
											></el-input>
											<el-input
												:style="getStyle(item)"
												type="text"
												:placeholder="item.placeholder"
												v-if="item.type == 'NUMBER'"
												v-model="item.value"
												:disabled="checkFieldAllow(item)"
												:ref="removeHashesFromKey(item.key)"
												:class="
													currentEnabledFieldKey == item.key ? 'active' : ''
												"
											></el-input>

											<el-date-picker
												:height="item.height"
												:width="item.width"
												:style="getStyle(item)"
												type="date"
												:placeholder="item.placeholder"
												v-if="item.type == 'DATE'"
												v-model="item.value"
												:disabled="checkFieldAllow(item)"
												:ref="removeHashesFromKey(item.key)"
												:class="
													currentEnabledFieldKey == item.key ? 'active' : ''
												"
											></el-date-picker>

											<el-date-picker
												:height="item.height"
												:width="item.width"
												:style="getStyle(item)"
												type="date"
												:placeholder="item.placeholder"
												v-if="item.type == 'DATE_SIGNED'"
												v-model="item.value"
												:disabled="checkFieldAllow(item)"
												:ref="removeHashesFromKey(item.key)"
												:class="
													currentEnabledFieldKey == item.key ? 'active' : ''
												"
											></el-date-picker>

											<el-select
												:height="item.height"
												:width="item.width"
												:style="getStyle(item)"
												v-if="item.type == 'SELECT'"
												v-model="item.value"
												:placeholder="item.placeholder"
												:disabled="checkFieldAllow(item)"
												:ref="removeHashesFromKey(item.key)"
												:class="
													currentEnabledFieldKey == item.key ? 'active' : ''
												"
											>
												<el-option
													v-for="(option, index) of item.options"
													:key="index"
													:value="option"
													:label="option"
												></el-option>
											</el-select>

											<el-select
												:height="item.height"
												:width="item.width"
												:style="getStyle(item)"
												v-if="item.type == 'MULTI_SELECT'"
												v-model="item.value"
												:placeholder="item.placeholder"
												:disabled="checkFieldAllow(item)"
												multiple
												:ref="removeHashesFromKey(item.key)"
												:class="
													currentEnabledFieldKey == item.key ? 'active' : ''
												"
											>
												<el-option
													v-for="(option, index) of item.options"
													:key="index"
													:value="option"
													:label="option"
												></el-option>
											</el-select>

											<input
												type="checkbox"
												:height="item.height"
												:width="item.width"
												:style="getStyle(item)"
												v-if="item.type == 'CHECKBOX'"
												v-model="item.value"
												:disabled="checkFieldAllow(item)"
												:ref="removeHashesFromKey(item.key)"
												:class="
													currentEnabledFieldKey == item.key ? 'active' : ''
												"
											/>
											<el-input
												type="textarea"
												:rows="item.rows"
												:style="getStyle(item)"
												v-if="item.type == 'MULTI_LINE_TEXT'"
												v-model="item.value"
												:placeholder="item.placeholder"
												:disabled="checkFieldAllow(item)"
												:ref="removeHashesFromKey(item.key)"
												:class="
													currentEnabledFieldKey == item.key ? 'active' : ''
												"
											/>

											<a
												v-if="item.type == 'SIGNATURE' && !item.source"
												:style="getStyle(item)"
												@click="captureSignature(item, index)"
												:disabled="checkFieldAllow(item)"
												:ref="removeHashesFromKey(item.key)"
												:class="
													currentEnabledFieldKey == item.key ? 'active' : ''
												"
											>
												{{ item.label }}
												<i class="el-icon-edit"></i>
											</a>
											<img
												v-if="item.type == 'SIGNATURE' && item.source"
												:src="item.source"
												:style="getStyle(item, false)"
												@click="captureSignature(item, index)"
											/>
										</div>
									</div>
								</div>
							</div>
							<div
								class="form-field-options d-flex align-center justify-between"
							>
								<div class="field-stats-block d-flex">
									<div class="each-stat d-flex">
										<div class="count">
											<p class="mt-0 blue-2">{{ getCompletedFieldsCount() }}</p>
										</div>
										<p class="text mt-0">Completed Fields</p>
									</div>
									<div class="each-stat d-flex">
										<div class="count">
											<p class="mt-0 success">
												{{ getRemainingFieldsCount() }}
											</p>
										</div>
										<p class="text mt-0">Remaining Fields</p>
									</div>
								</div>
								<div class="fields-navigators">
									<el-button
										size="mini"
										:disabled="disablePrevButton"
										@click="goToPrevField()"
									>
										<i class="el-icon-back"></i> Prev
									</el-button>
									<el-button
										size="mini"
										:disabled="disableNextButton"
										@click="goToNextField()"
									>
										Next
										<i class="el-icon-right"></i>
									</el-button>
									<el-button
										size="mini"
										class="document-sign ml-2 bg-tomato-red"
										type="danger"
									>
										<i class="el-icon-edit-outline"></i>
										<span>Sign Document</span>
									</el-button>
								</div>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="form-fields-set mb-10">
						<h3 class="font-weight-normal text-center blue mb-05">
							Document Status
						</h3>
						<el-button
							type="warning"
							class="w-100"
							plain
							@click="showDocumentUsers"
						>
							In-Progress
						</el-button>

						<SignatureCompleteStatus
							v-if="documentUsers.length"
							:employeeDocumentUsers="documentUsers"
						></SignatureCompleteStatus>

						<el-button
							type="success"
							class="w-100"
							plain
							@click="showCompleteEmployeeDocument = true"
						>
							<i class="el-icon-check"></i> Complete
						</el-button>
					</div>

					<div class="form-fields-set">
						<el-row>
							<h3 class="font-weight-normal text-center blue mb-05">
								Document Details
							</h3>
							<el-form v-model="userDocumentFormData">
								<el-form-item label="Document Valid From">
									<el-date-picker
										type="date"
										v-model="userDocumentFormData.valid_from"
									></el-date-picker>
								</el-form-item>

								<el-form-item label="Document Expires">
									<el-date-picker
										type="date"
										v-model="userDocumentFormData.expires_at"
									></el-date-picker>
								</el-form-item>

								<el-divider class="mb-05"></el-divider>
							</el-form>
						</el-row>

						<el-row>
							<h3 class="font-weight-normal text-center blue mb-05">
								Document Users
							</h3>
							<hr />
							<div>
								<el-card
									class="signature-box mt-1 mb-0"
									shadow="never"
									v-for="(user, index) of documentUsers"
									:key="index"
								>
									<el-row v-if="user.user_id">
										<el-col :span="24">
											<span>
												{{ user.user_id.first_name }}
												{{ user.user_id.last_name }}
											</span>
											<div>{{ user.user_id.email }}</div>

											<div v-if="isUserIsDocumentOwner(user.user_id)">
												Document User
											</div>

											<div v-else>{{ user.name }}</div>
											<el-divider class="mb-0 mt-1"></el-divider>
										</el-col>

										<template v-if="user.e_signature_required">
											<el-col :span="12">E-Signature</el-col>

											<el-col :span="12" v-if="user.e_signature_status">
												<div class="success text-right">Completed</div>
											</el-col>
											<el-col :span="12" v-if="!user.e_signature_status">
												<div class="mb-05 error">Pending</div>
											</el-col>
											<el-col
												:span="24"
												class="text-center"
												v-if="user.e_signature_status"
											>
												<el-divider class="mb-0 mt-1"></el-divider>
												<el-button
													class="btn-link mb-0 pb-0"
													type="text"
													@click="onESignView(user.e_signature)"
													>View Signature</el-button
												>
											</el-col>
											<el-col
												:span="24"
												v-if="
													!user.e_signature_status &&
													user.user_id._id == currentAuthUserId
												"
											>
												<el-divider class="mb-0 mt-1"></el-divider>
												<el-button
													class="btn-link mb-0 pb-0"
													type="text"
													@click="onSignDocument"
													>Sign Document</el-button
												>
											</el-col>
										</template>
									</el-row>

									<el-row v-else>
										<el-col :span="24">
											<div>{{ user.name }}</div>
											<span>No User Configured</span>
										</el-col>
									</el-row>
								</el-card>
							</div>
						</el-row>
					</div>
				</el-col>
			</el-row>
		</div>

		<DocumentUsersLog
			v-if="showDocumentUsersLog"
			@dialog:close="showDocumentUsersLog = false"
			:employeeDocumentId="employeeDocumentId"
		>
		</DocumentUsersLog>

		<CompleteEmployeeDocument
			v-if="showCompleteEmployeeDocument"
			@dialog:close="closeCompleteDoc"
			:employeeDocumentId="employeeDocumentId"
			:userDocumentFormData="userDocumentFormData"
		>
		</CompleteEmployeeDocument>

		<el-dialog :visible.sync="showSavedSignatureData" title="eSignature">
			<div v-loading="esignLoading">
				<el-row>
					<el-col :span="24" v-if="esignData">
						<ESignatureDataView :esignData="esignData"></ESignatureDataView>
					</el-col>
				</el-row>
			</div>
		</el-dialog>

		<el-dialog
			:destroy-on-close="true"
			:visible.sync="eSignatureDialogVisible"
			title="Authorize Signature"
			class="outersize-confirm-type-three"
			width="100%"
		>
			<ESignature
				v-if="eSignatureDialogVisible"
				:workflowName="workflowName"
				:templateName="documentFormData.name"
				:purpose="'COMPANY_DOCUMENT'"
				v-on:onVerfiedSignature="updateDocumentSignature"
				v-on:cancelESign="eSignatureDialogVisible = false"
			></ESignature>
		</el-dialog>

		<el-dialog :visible.sync="showSelectEntityDataDialog" title="Select Entity">
			<div v-loading="false">
				<el-row
					v-for="(item, index) of entityIdsToSelectEntity"
					v-bind:key="index"
				>
					<el-col :span="12">
						<el-form>
							<el-form-item :label="getLabelNameForSelectEntity(item)">
								<el-select
									v-model="selectedEntityData[index]['key']"
									@change="onSelectEntity($event, index)"
									:placeholder="
										'Select ' +
										getLabelNameForSelectEntity(item) +
										' Entity Data'
									"
								>
									<el-option
										v-for="entityData of getEntityDataOptions(item)"
										:key="entityData.entity_data_id"
										:label="
											getLabelNameForSelectEntityField(
												entityData.entity_data_id
											)
										"
										:value="entityData"
									>
									</el-option>
								</el-select>
							</el-form-item>
						</el-form>
					</el-col>
					<el-col :span="12">
						<el-form>
							<el-form-item label="Mapping Fields">
								<!-- <span>{{getEntityMappedFields(item)}}</span> -->
							</el-form-item>
						</el-form>
					</el-col>
				</el-row>
				<el-button
					class="primary"
					@click="onSubmitSelectEntity()"
					:disabled="checkDisableSelectEntityButton()"
				>
					Submit
				</el-button>
			</div>
		</el-dialog>
	</section>
</template>

<script>
	import appConfig from "@/config/app";
	import { mapGetters } from "vuex";
	import ESignatureDataView from "@/components/signature/ESignatureDataView";
	import ESignature from "@/components/signature/ESignature";
	import DocumentUsersLog from "./DocumentUsersLog";
	import CompleteEmployeeDocument from "./CompleteEmployeeDocument";
	import UserDocumentsHelper from "@/mixins/UserDocumentsHelper";
	import SignatureCompleteStatus from "@/widgets/SignatureCompleteStatus";

	export default {
		components: {
			ESignatureDataView,
			ESignature,
			DocumentUsersLog,
			CompleteEmployeeDocument,
			SignatureCompleteStatus,
		},
		mixins: [UserDocumentsHelper],
		created() {},
		data() {
			return {
				elements: [],
				width: 0,
				height: 0,
				x: 0,
				y: 0,
				addItemDataDialogVisible: false,
				selectedItemIndex: null,
				selectedItem: {},
				refreshForm: false,
				selectedSignatureItem: {},
				selectedSignatureItemIndex: -1,
				captureSignatureDialog: false,
				hasBackground: false,
				documentId: null,
				configurableDocumentId: null,
				documentDataLoading: false,
				documentFormData: null,
				documentName: "",
				loading: false,
				backgroundImage: "",
				previewVisible: false,
				activatedItem: null,
				activatedItemIndex: -1,

				currentFormBuilderWidth: null,
				storedFormBuilderWidth: null,
				checkExistingData: false,
				backgroundImages: [],
				loadingElements: false,
				selectedTemplateFields: [],
				templatesUsed: [],
				entitiesUsed: [],
				selectedEntityData: [],
				showSelectEntityDataDialog: false,
				entityIdsToSelectEntity: [],
				showErrorElementDialog: false,
				errorElements: [],
				fieldErrorMessage: "",
				workflowDataId: "",
				userId: "",

				userDocumentFormData: {
					valid_from: "",
					expires_at: "",
					e_signature_required: "",
					e_signature_value: "",
				},
				employeeDocumentId: "",
				templateDataIdsData: [],
				workflowStepId: "",

				showSavedSignatureData: false,
				esignLoading: false,
				esignData: {},

				documentSentStatus: false,
				eSignatureDialogVisible: false,
				documentUsers: [],
				workflowName: "Company Documents",
				enforceSignatureOrder: false,
				currentFieldIndex: -1,
				disableNextButton: false,
				disablePrevButton: true,
				completedFieldsCountLoading: false,
				currentLabelName: "",
				currentEnabledFieldKey: "",
				dialogdocumentStatus: false,
				sendRemainderDialog: false,

				showDocumentUsersLog: false,
				showCompleteEmployeeDocument: false,
			};
		},
		computed: {
			...mapGetters("company", ["getCompanyProfileData"]),
			...mapGetters("auth", ["getAuthenticatedUser"]),
			...mapGetters("configurableDocuments", ["getConfigurableDocumentDataById"]),
			...mapGetters("employeeData", ["getESignData"]),

			...mapGetters("templatesData", ["getTemplatesData"]),
			...mapGetters("workflowData", [
				"getWorkflowDataAssetDataAddStatus",
				"getUserWorkflowData",
			]),
			...mapGetters("companyDocuments", ["getCompanyDocumentDataById"]),
			...mapGetters("employeeDocuments", [
				"getEmployeeDocumentDataById",
				"getEmployeeDocumentUpdateStatus",
				"getDocumentSignatureStatus",
				"getDocumentSignatureError",
			]),
			...mapGetters("entitiesData", ["getAllEntitiesDataByIds"]),

			getLogoUrl() {
				return appConfig.S3_BUCKET_URL + "/" + this.getCompanyProfileData.logo;
			},
			isCurrentUserDocumentOwner() {
				return (
					this.getAuthenticatedUser &&
					this.getAuthenticatedUser._id == this.employeeId
				);
			},
			isUserIsDocumentOwner() {
				return (user) => {
					return user._id == this.employeeId;
				};
			},
			currentAuthUserId() {
				return this.getAuthenticatedUser && this.getAuthenticatedUser._id
					? this.getAuthenticatedUser._id
					: null;
			},
		},
		async mounted() {
			this.currentFormBuilderWidth = this.$refs.formBuilder.clientWidth;

			this.fetchCompanyDetails();
			this.setData();
			await this.fetchDocumentData();
			await this.fetchWorkflowData();

			if (this.employeeDocumentId) {
				await this.fetchEmployeDocumentData();
			}
			if (this.templatesUsed.length) {
				await this.fetchUserTemplateData();
			}

			await this.setEmployeDataToForm();

			if (this.entityIdsToSelectEntity.length) {
				await this.fetchEntitiesData();
				this.showSelectEntityDataDialog = true;
			}
		},
		methods: {
			calculateRelativeLeft(x) {
				if (
					this.storedFormBuilderWidth &&
					this.currentFormBuilderWidth &&
					this.currentFormBuilderWidth != this.storedFormBuilderWidth
				) {
					return Math.round(
						(this.currentFormBuilderWidth / this.storedFormBuilderWidth) * x
					);
				}
				return x;
			},

			calculateRelativeTop(y) {
				if (
					this.storedFormBuilderWidth &&
					this.currentFormBuilderWidth &&
					this.currentFormBuilderWidth != this.storedFormBuilderWidth
				) {
					return Math.round(
						(this.currentFormBuilderWidth / this.storedFormBuilderWidth) * y
					);
				}
				return y;
			},
			calculateRelativeWidth(width) {
				if (
					this.storedFormBuilderWidth &&
					this.currentFormBuilderWidth &&
					this.currentFormBuilderWidth != this.storedFormBuilderWidth
				) {
					return Math.round(
						(this.currentFormBuilderWidth / this.storedFormBuilderWidth) * width
					);
				}
				return width;
			},
			setData() {
				this.employeeId = this.$route.params.employee_id;
				this.configurableDocumentId = this.$route.params.configurable_document_id;
				this.documentId = this.$route.params.document_id;
				this.employeeDocumentId = this.$route.query.employee_document_id;
				this.workflowStepId = this.$route.query.workflow_step_id;
			},

			closeCompleteDoc(data) {
				this.showCompleteEmployeeDocument = false;
				if (data) {
					this.updateEmployeeDocument();
				}
			},

			setEmployeDataToForm() {
				this.documentDataLoading = true;
				for (let index = 0; index < this.elements.length; index++) {
					let field = this.elements[index];
					if (field.field_type == "TEMPLATE_FIELD") {
						const fieldData = this.getFieldDataFromTemplate(
							field.template_id,
							field.template_field_id
						);
						if (fieldData) {
							this.$set(this.elements[index], "label", fieldData.label);
							this.$set(this.elements[index], "filled_by", fieldData.filled_by);
							this.$set(this.elements[index], "options", fieldData.options);
							this.$set(
								this.elements[index],
								"placeholder",
								fieldData.placeholder
							);
							this.$set(this.elements[index], "source", fieldData.source);
							this.$set(this.elements[index], "source", fieldData.source);
							this.$set(this.elements[index], "required", fieldData.required);
						}

						if (this.workflowDataId) {
							const value = this.getValueFromTempaleFieldFromEmployeeData(field);

							const documentValue = this.getValueFromSavedDocument(field.key);

							if (documentValue) {
								this.$set(this.elements[index], "value", documentValue);
								if (field.type == "SIGNATURE") {
									this.$set(this.elements[index], "source", documentValue);
								}
							} else if (value) {
								this.$set(this.elements[index], "value", value);
							}
						}
					} else {
						const documentValue = this.getValueFromSavedDocument(field.key);
						if (!documentValue && field.field_type == "ENTITY_FIELD") {
							if (this.entityIdsToSelectEntity.indexOf(field.entity_id) < 0) {
								this.entityIdsToSelectEntity.push(field.entity_id);
							}
						}

						if (documentValue && field.field_type == "ENTITY_FIELD") {
							if (this.entityIdsToSelectEntity.indexOf(field.entity_id) > -1) {
								this.entityIdsToSelectEntity.splice(
									this.entityIdsToSelectEntity.indexOf(field.entity_id),
									1
								);
							}
						}
						this.$set(this.elements[index], "value", documentValue);
						if (field.type == "SIGNATURE") {
							this.$set(this.elements[index], "source", documentValue);
						}
					}
				}

				if (this.employeeDocumentId && this.getEmployeeDocumentDataById) {
					this.userDocumentFormData.e_signature_required =
						this.getEmployeeDocumentDataById.e_signature_required;
					this.userDocumentFormData.e_signature_status =
						this.getEmployeeDocumentDataById.e_signature_status;
					this.userDocumentFormData.expires_at =
						this.getEmployeeDocumentDataById.expires_at;
					this.userDocumentFormData.valid_from =
						this.getEmployeeDocumentDataById.valid_from;

					this.userDocumentFormData.enforce_signature_order =
						this.getEmployeeDocumentDataById.enforce_signature_order;
					this.enforceSignatureOrder =
						this.getEmployeeDocumentDataById.enforce_signature_order;

					this.documentUsers =
						this.getEmployeeDocumentDataById.document_users.sort((a, b) => {
							return a.e_signature_order - b.e_signature_order > 0 ? 1 : -1;
						});
					this.userDocumentFormData.e_signature_value = this
						.getEmployeeDocumentDataById.e_signature_required
						? "YES"
						: "NO";
				}

				this.documentDataLoading = false;
			},

			async fetchWorkflowData() {
				this.workflowDataId = this.$route.params.workflow_data_id;
				if (this.workflowDataId) {
					this.userId =
						this.getUserWorkflowData && this.getUserWorkflowData.user_id
							? this.getUserWorkflowData.user_id
							: null;

					this.templateDataIdsData = this.getUserWorkflowData.template_data_ids
						? this.getUserWorkflowData.template_data_ids
						: [];

					let steps = this.getUserWorkflowData.workflow_steps_data || [];

					this.documentSentStatus = steps.find(
						(x) => x.step_id == this.workflowStepId && x.sent_status
					)
						? true
						: false;
				}
			},
			showDocumentUsers() {
				this.showDocumentUsersLog = true;
			},
			async fetchEntitiesData() {
				let params = {
					entity_ids: this.entitiesUsed,
				};
				await this.$store.dispatch("entitiesData/getEntitiesDataByIds", params);
				if (this.getAllEntitiesDataByIds) {
					for (let index = 0; index < this.entitiesUsed.length; index++) {
						this.selectedEntityData.push({
							entity_id: this.entitiesUsed[index],
							key: "",
						});
					}
				}
			},

			getLabelNameForSelectEntity(item) {
				let label = "";
				for (
					let index = 0;
					index < this.getAllEntitiesDataByIds.data.length;
					index++
				) {
					const element = this.getAllEntitiesDataByIds.data[index];
					if (element.entity_id._id == item) {
						label = element.entity_id.name;
						break;
					}
				}
				return label;
			},

			onSelectEntity(event, index) {
				let key = this.getLabelNameForSelectEntityField(event.entity_data_id);
				this.selectedEntityData[index] = { ...event, ...{ key } };
			},

			checkDisableSelectEntityButton() {
				let count = 0;
				for (let index = 0; index < this.selectedEntityData.length; index++) {
					const element = this.selectedEntityData[index];
					if (element && element.entity_data_id) {
						count += 1;
					}
				}
				return count != this.selectedEntityData.length;
			},

			getLabelNameForSelectEntityField(item) {
				let label = "";
				let field = {};
				for (
					let index = 0;
					index < this.getAllEntitiesDataByIds.data.length;
					index++
				) {
					const element = this.getAllEntitiesDataByIds.data[index];
					if (element._id == item) {
						for (
							let indexOfItem = 0;
							indexOfItem < element.entity_id.primary_fields.length;
							indexOfItem++
						) {
							field = { ...element.entity_id.primary_fields[indexOfItem] };
							if (
								field &&
								field.key &&
								element.entity_data &&
								element.entity_data[field.key]
							) {
								label =
									label +
									(element.entity_data && element.entity_data[field.key]
										? element.entity_data[field.key]
										: "") +
									" ";
							}
						}
						break;
					}
				}
				return label == "" ? "NA" : label;
			},

			onSubmitSelectEntity() {
				this.documentDataLoading = true;
				for (let index = 0; index < this.elements.length; index++) {
					let field = this.elements[index];
					if (field && field.field_type == "ENTITY_FIELD") {
						const documentValue = this.getValueFromEntityDataFieldOnSubmit(field);
						this.$set(this.elements[index], "value", documentValue);
					}
				}

				this.documentDataLoading = false;
				this.showSelectEntityDataDialog = false;
			},

			getValueFromEntityDataFieldOnSubmit(field) {
				let key =
					field && field.key
						? field.key.substring(field.key.indexOf("#") + 1)
						: "";
				if (this.selectedEntityData && this.selectedEntityData.length) {
					let entity = this.selectedEntityData.find(
						(x) => x.entity_id == field.entity_id
					);
					if (entity && entity.entity_data) {
						return entity.entity_data[key] ? entity.entity_data[key] : "";
					}
					return "";
				}
				return "";
			},

			getEntityMappedFields(item) {
				let pages = this.fieldsData.pages;
				let fields = "";
				for (let page of pages) {
					for (let section of page.sections) {
						for (let field of section.fields) {
							if (
								field.entity_id &&
								field.entity_field_id &&
								field.entity_id == item
							) {
								let value = this.getValueFromEntityDataFieldOnSubmit(field);
								if (
									!this.standardDocumentFormData[section.key][field.key] &&
									value
								) {
									fields += field.key + ", ";
								}
							}
						}
					}
				}
				return fields;
			},

			getEntityDataOptions(item) {
				let options = [];
				for (
					let index = 0;
					index < this.getAllEntitiesDataByIds.data.length;
					index++
				) {
					const element = this.getAllEntitiesDataByIds.data[index];
					if (element.entity_id._id == item) {
						options.push({
							entity_data_id: element._id,
							entity_id: element.entity_id._id,
							entity_data: element.entity_data,
						});
					}
				}
				return options;
			},

			async fetchUserTemplateData() {
				let usedTemplateIds = this.templatesUsed.map((e) => e._id);

				let dataTemplates = this.templateDataIdsData.filter(
					(e) => usedTemplateIds.indexOf(e.template_id) > -1
				);

				let templateDataIds = dataTemplates.map(
					(value) => value.template_data_id
				);

				let params = {
					template_data_ids: templateDataIds,
				};
				await this.$store.dispatch("templatesData/fetchUserTemplateData", params);
			},
			getFieldDataFromTemplate(templateId, templateFieldId) {
				let template = this.templatesUsed.find((x) => x._id == templateId);
				if (template) {
					let field = template.sections[0].fields.find(
						(x) => x._id == templateFieldId
					);
					if (field) {
						return field;
					}
					return {};
				}
				return {};
			},

			onSignDocument() {
				if (this.enforceSignatureOrder) {
					let prevUnsignedUsers = this.getPrevUnsignedUsers();
					if (prevUnsignedUsers.length) {
						let errorString =
							"<strong>Your not allowed to Sign on document</strong> <br>";
						errorString += "<ul>";
						prevUnsignedUsers.forEach((user) => {
							errorString +=
								"<li><span>" +
								user.name +
								"</span> - " +
								"<b>" +
								user.e_signature_status
									? "Signed"
									: "Not Signed" + "</b>" + "</li>";
						});
						errorString += "</ul>";

						this.$alert(errorString, "Alert", {
							dangerouslyUseHTMLString: true,
						});
						return;
					}
				}
				this.eSignatureDialogVisible = true;
			},
			getPrevUnsignedUsers() {
				let currentUser = this.documentUsers.find((user) => {
					return user.user_id._id == this.currentAuthUserId;
				});

				let preUsers = this.documentUsers.filter(
					(x) =>
						x.e_signature_required &&
						x.e_signature_order < currentUser.e_signature_order
				);

				let prevUnsignedUsers = preUsers.filter((x) => !x.e_signature_status);

				return prevUnsignedUsers;
			},
			getValueFromTempaleFieldFromEmployeeData(field) {
				let key = field && field.key ? field.key.split("#")[1] : "";

				if (this.getTemplatesData && this.getTemplatesData.length) {
					let template = this.getTemplatesData.find(
						(x) => x.template_id == field.template_id
					);
					if (template) {
						return template.template_data[key] ? template.template_data[key] : "";
					}
					return "";
				}
				return "";
			},

			getValueFromSavedDocument(key) {
				if (
					this.getEmployeeDocumentDataById &&
					this.getEmployeeDocumentDataById.document_data
				) {
					return this.getEmployeeDocumentDataById.document_data[key];
				}
				return null;
			},
			async fetchCompanyDetails() {
				await this.$store.dispatch("company/getCompany");
			},
			async fetchEmployeeData() {
				this.empDataLoading = true;
				await this.$store.dispatch("employee/fetchEmployeeSingleEmployee", {
					id: this.employeeId,
				});
				this.empDataLoading = false;
			},

			async fetchEmployeDocumentData() {
				await this.$store.dispatch(
					"employeeDocuments/fetchEmployeeDocumentById",
					{
						id: this.employeeDocumentId,
					}
				);
			},

			async fetchDocumentData() {
				this.documentDataLoading = true;
				await this.$store.dispatch(
					"configurableDocuments/fetchConfigurableDocumentById",
					{
						id: this.configurableDocumentId,
						include_template_details: true,
					}
				);
				await this.$store.dispatch("companyDocuments/fetchCompanyDocumentById", {
					id: this.documentId,
				});

				this.userDocumentFormData.valid_from =
					this.getCompanyDocumentDataById.valid_from;
				this.userDocumentFormData.expires_at =
					this.getCompanyDocumentDataById.valid_to;
				this.userDocumentFormData.e_signature_required =
					this.getCompanyDocumentDataById.e_signature_required;

				this.documentFormData = JSON.parse(
					JSON.stringify(this.getConfigurableDocumentDataById)
				);

				this.backgroundImage = this.documentFormData.pages[0].background_image;
				this.backgroundImages = this.documentFormData.pages[0].background_images;

				this.storedFormBuilderWidth =
					this.documentFormData.pages[0].page_base_width;
				this.templatesUsed = this.documentFormData.pages[0].templates_used;
				this.entitiesUsed = this.documentFormData.pages[0].entities_used;

				this.loadingElements = true;
				setTimeout(() => {
					this.elements = this.documentFormData.pages[0].fields;
					this.elements = this.setSizeAndPositionForElements();
					this.loadingElements = false;
				}, this.backgroundImages * 500);

				this.documentDataLoading = false;
			},

			setSizeAndPositionForElements() {
				return this.elements.map((field) => {
					field.x = this.calculateRelativeLeft(field.x);
					field.y = this.calculateRelativeTop(field.y);
					field.width = this.calculateRelativeWidth(field.width);
					field.height = this.calculateRelativeWidth(field.height);
					return field;
				});
			},
			getFormStyle(page) {
				if (page.background) {
					let style = page.style + `background:url('${page.background_image}');`;
					return style;
				} else {
					return page.style || "";
				}
			},
			getStyle(item, background = true) {
				if (item.type == "SIGNATURE") {
					return (
						`position:absolute;left:${item.x}px;top:${item.y}px;cursor:pointer;` +
						(item.source ? `height:${item.height}px;` : ``) +
						(background ? "background:#c2d8de;" : "")
					);
				} else if (item.type == "my-signature") {
					return `position:absolute;left:${item.x}px;top:${item.y}px;height:50px;cursor:pointer;`;
				} else if (item.type == "MULTI_LINE_TEXT") {
					return `position:absolute;left:${item.x}px;top:${item.y}px;height:auto;width:${item.width}px;`;
				}
				return `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;`;
			},
			checkFieldAllow(item) {
				return item.filled_by === "RECEIVER";
			},
			goToEmployesPage() {
				this.$router.push({ path: "/employee-list" });
			},

			getData() {
				let data = {};

				for (let index = 0; index < this.elements.length; index++) {
					let field = this.elements[index];
					let key = field.key;
					data[key] = this.elements[index].value || this.elements[index].source;
					if (field.type == "SIGNATURE") {
						this.signatureFields.push(field);
					}
				}
				return data;
			},

			async updateDocumentSignature(eSignData) {
				this.eSignatureDialogVisible = false;
				this.eSignId = eSignData.e_signature_id;
				try {
					let params = {
						id: this.employeeDocumentId,
						e_signature: this.eSignId,
						e_signature_status: true,
						document_data: this.prepareDocumentData(),
					};

					await this.$store.dispatch(
						"employeeDocuments/updateDocumentSignature",
						params
					);

					if (this.getDocumentSignatureStatus) {
						this.$notify.success({
							title: "Success",
							message: "Document signature updated successfully",
						});
						// window.location.reload();
					} else {
						this.$notify.error({
							title: "Error",
							message:
								this.getDocumentSignatureError || "Erorr at updating Signature",
						});
					}
				} catch (err) {
					console.log(err);
				}
			},

			goToDocuments() {
				this.$router.go(-1);
			},
			setColor(selectedSignatureItem, color) {
				this.$set(selectedSignatureItem, "color", color);
			},
			captureSignature(item, index, pageIndex) {
				if (this.checkFieldAllow(item)) {
					return;
				}
				this.selectedSignatureItem = item;
				this.selectedSignatureItemIndex = index;
				this.pageIndex = pageIndex;
				this.captureSignatureDialog = true;
			},
			saveCapturedSignature() {
				const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
				if (!isEmpty) {
					this.selectedSignatureItem.source = data;
					this.elements[this.selectedSignatureItemIndex] =
						this.selectedSignatureItem;
				}
				this.$refs.signaturePad.clearSignature();
				this.selectedSignatureItem = {};
				this.selectedSignatureItemIndex = -1;
				this.captureSignatureDialog = false;
			},
			undo() {
				this.$refs.signaturePad.undoSignature();
			},
			backToEmployees() {
				this.$router.push({
					path: "employee-list",
				});
			},

			saveDocumentData() {
				try {
					if (this.employeeDocumentId) {
						this.updateEmployeeDocument();
					} else {
						this.addEmployeeDocument();
					}
				} catch (err) {
					console.log(err);
				}
			},

			async updateEmployeeDocument() {
				try {
					let documentData = this.prepareDocumentData();
					let params = {
						id: this.employeeDocumentId,
						document_data: documentData,
						valid_from: this.userDocumentFormData.valid_from,
						expires_at: this.userDocumentFormData.expires_at,
						e_signature_required:
							this.userDocumentFormData.e_signature_value == "YES" ? true : false,
					};

					await this.$store.dispatch(
						"employeeDocuments/updateEmployeeDocument",
						params
					);

					if (this.getEmployeeDocumentUpdateStatus) {
						this.$notify.success({
							title: "Success",
							message: "Document Data Saved Successfully",
						});
						this.goToDocuments();
					} else {
						this.$notify.error({
							title: "Error",
							message: "Erorr at updating document",
						});
					}
				} catch (err) {
					console.log(err);
				}
			},

			async addEmployeeDocument() {
				try {
					let documentData = this.prepareDocumentData();
					let companayDocumentData = {
						configurable_document_id: this.configurableDocumentId,
						company_document_id: this.documentId,
						document_data: documentData,
						document_type: "CUSTOM",
						valid_from: this.userDocumentFormData.valid_from,
						expires_at: this.userDocumentFormData.expires_at,
						e_signature_required:
							this.userDocumentFormData.e_signature_value == "YES" ? true : false,
					};
					let params = {
						workflow_data_id: this.workflowDataId,
						workflow_step_id: this.workflowStepId,

						asset: "COMPANY_DOCUMENTS",
						company_document_data: companayDocumentData,
					};
					await this.$store.dispatch(
						"workflowData/addAssetDataToWorkFlowData",
						params
					);
					if (this.getWorkflowDataAssetDataAddStatus) {
						this.$notify.success({
							title: "Success",
							message: "Document Data Saved Successfully",
						});
						this.goToDocuments();
					} else {
						this.$notify.error({
							title: "Error",
							message: "Erorr at updating document",
						});
					}
				} catch (err) {
					this.$notify.error({
						title: "Error",
						message: "Erorr at updating document",
					});
				}
			},

			prepareDocumentData() {
				let data = {};
				this.elements.forEach((element) => {
					if (element.value) {
						data[element.key] = element.value;
					} else if (element.source) {
						data[element.key] = element.source;
					} else {
						data[element.key] = "";
					}
				});
				return data;
			},

			async onESignView(eSignatureId) {
				try {
					this.showSavedSignatureData = true;
					this.esignLoading = true;
					let params = {
						id: eSignatureId || this.getEmployeeDocumentDataById.e_signature,
					};
					await this.$store.dispatch("employeeData/fetchESignData", params);
					this.esignLoading = false;
					this.esignData = this.getESignData;
				} catch (err) {
					console.log(err);
				}
			},
			updateEsignValue(value) {
				this.userDocumentFormData.e_signature_required = value === "YES";
			},
			getCompletedFieldsCount() {
				this.completedFieldsCountLoading = true;
				let value = 0;
				if (this.elements && this.elements.length) {
					this.elements.forEach((element) => {
						if (
							element &&
							element.type != "logo" &&
							!this.checkFieldAllow(element) &&
							element.value
						) {
							value = value + 1;
						}
					});
					this.completedFieldsCountLoading = false;
					return value;
				} else {
					this.completedFieldsCountLoading = false;
					return 0;
				}
			},
			getRemainingFieldsCount() {
				let value = 0;
				if (this.elements && this.elements.length) {
					this.elements.forEach((element) => {
						if (
							element &&
							element.type != "logo" &&
							!this.checkFieldAllow(element) &&
							!element.value
						) {
							value = value + 1;
						}
					});
					return value;
				} else {
					return 0;
				}
			},
			removeHashesFromKey(str) {
				return str ? str.replace(/#/g, "") : "";
			},
			getIndexOfPrevField() {
				let index = this.currentFieldIndex > -1 ? this.currentFieldIndex - 1 : -1;
				if (index < 0) {
					return -1;
				} else {
					let field = this.elements[index];
					this.currentFieldIndex = this.currentFieldIndex - 1;
					if (this.checkFieldAllow(field)) {
						return this.getIndexOfPrevField();
					} else {
						return index;
					}
				}
			},

			goToPrevField() {
				let index = this.getIndexOfPrevField();
				if (index == -1) {
					this.disableNextButton = false;
					this.disablePrevButton = true;
				} else {
					if (
						this.elements &&
						this.elements.length &&
						this.elements[index] &&
						this.elements[index]["key"]
					) {
						this.disableNextButton = false;
						let key = this.removeHashesFromKey(this.elements[index].key);
						this.currentEnabledFieldKey = this.elements[index].key;
						if (this.$refs[key] && this.$refs[key].length) {
							this.$refs[key][0].focus();
						} else {
							this.goToPrevField();
						}
					} else {
						this.goToPrevField();
					}
				}
			},

			getIndexOfField() {
				let index = this.currentFieldIndex + 1;
				if (index > this.elements.length) {
					return -1;
				} else {
					let field = this.elements[index];
					this.currentFieldIndex = this.currentFieldIndex + 1;
					if (this.checkFieldAllow(field)) {
						return this.getIndexOfField();
					} else {
						return index;
					}
				}
			},

			goToNextField() {
				let index = this.getIndexOfField();
				if (index == -1) {
					this.disableNextButton = true;
					this.disablePrevButton = false;
				} else {
					if (
						this.elements &&
						this.elements.length &&
						this.elements[index] &&
						this.elements[index]["key"]
					) {
						this.disablePrevButton = false;
						let key = this.removeHashesFromKey(this.elements[index].key);
						this.currentEnabledFieldKey = this.elements[index].key;
						if (this.$refs[key] && this.$refs[key].length) {
							this.$refs[key][0].focus();
						} else {
							this.goToNextField();
						}
					} else {
						this.disablePrevButton = false;
						this.goToNextField();
					}
				}
			},
		},
		beforeDestroy() {
			this.$store.commit("company/setCompanyProfileData",null,{root:true})
			//this.$store.commit("configurableDocuments/setConfigurableDocumentDataById",null,{root:true})
			this.$store.commit("employeeData/setESignData",null,{root:true})
			this.$store.commit("workflowData/setWorkflowDataAssetDataAddStatus",null,{root:true})
			this.$store.commit("workflowData/setUserWorkflowData",null,{root:true})
			this.$store.commit("companyDocuments/setCompanyDocumentDataById",null,{root:true})
			this.$store.commit("employeeDocuments/setEmployeeDocumentUpdateStatus",null,{root:true})
			this.$store.commit("employeeDocuments/setEmployeeDocumentDataById",null,{root:true})
			this.$store.commit("employeeDocuments/setDocumentSignatureStatus",null,{root:true})
			this.$store.commit("employeeDocuments/setDocumentSignatureError",null,{root:true})
			this.$store.commit("entitiesData/setAllEntitiesDataByIds",null,{root:true})
		
		}
	};
</script>

<style lang="scss" scoped>
	.form-builder {
		max-height: calc(100vh - 145px);
		position: relative;
		// width: 25.5cm;
		width: 950px;
		overflow-y: auto;
		background-color: #ffffff;
		border: 1px solid #efefef;
		-webkit-box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
		box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
		border-radius: 10px;
		.form-image-holder {
			width: 100%;
			img {
				width: 100%;
			}
		}

		.form-fields-holder {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			.each-element img {
				width: 100%;
			}
		}
	}
	.document-root {
		position: relative;
		height: calc(100vh - 80px);
		.form-field-options {
			position: sticky;
			bottom: 10px;
			margin: 10px auto 0;
		}
		.each-stat {
			margin-right: 1em;
		}
		.field-stats-block {
			p {
				line-height: 40px;
			}
			.count {
				margin-right: 0.5em;
				background-color: #ffffff;
				height: 40px;
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
			}
		}
	}
	#drag-template-background {
		.img-fluid {
			height: auto !important;
		}
		.inner-navbar {
			margin-bottom: 1.5em;
			.left-block {
				.el-input__inner {
					min-width: 250px;
					max-width: 300px;
					background: #fff;
					height: 47px;
				}
			}
		}
		.input-options {
			td {
				padding-top: 0;
				vertical-align: top;
				&:nth-child(1) {
					width: 20%;
				}
				&:nth-child(2) {
					width: 80%;
				}
			}
			.inputs-list {
				background: #fff;
				padding: 15px;
				box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.06),
					0 1px 0px rgba(0, 0, 0, 0.08);
				border-radius: 5px;
				.el-button {
					border: none;
					background: rgba(236, 245, 255, 0.7);
					&:hover {
						background: rgba(236, 245, 255, 1);
					}
				}
				li {
					&:not(:last-child) {
						margin-bottom: 15px;
					}
				}
			}
		}
		.form-image {
			max-width: 770px;
			min-height: 1000px;
			background-color: #e6dcdc;
		}
		.el-input__inner {
			display: inline !important;
		}

		.form-holder {
			width: 100%;
			position: relative;
			.el-input__inner,
			.el-textarea__inner {
				height: 100% !important;
				min-height: 30px !important;
				line-height: inherit !important;
			}
			input[type="checkbox"] {
				margin: 0 !important;
			}

			// .draggable.resizable {
			//   position: absolute;
			//   z-index: 100;
			// }
		}

		.sample-form1 {
			position: relative;
			width: 100%;
			max-height: 91vh;
			overflow: scroll;
			border: 1px solid #ccc;
			margin-left: 20px;
			margin-bottom: 45px;

			///
			.each-page {
				position: relative;
				width: 900px;
				display: block;
				height: 800px;
				overflow-y: auto;
				.form-image {
					height: 800px;
					position: absolute;
					width: 850px;
					top: 0;
					left: 0;
				}
				.form-fields {
					height: 800px;
					position: absolute;
					width: 850px;
					top: 0;
					left: 0;
					z-index: 100;
				}
				.page-image {
					width: 850px;
					margin: 0 auto;
				}
			}

			// .draggable.resizable {
			//   position: absolute;
			//   z-index: 100;
			// }
		}

		.color-btn {
			height: 20px;
			width: 20px;
			border-radius: 50%;
			margin: 10px;
			&.red {
				background-color: red;
			}
			&.black {
				background-color: black;
			}

			&.green {
				background-color: green;
			}
		}

		// Fields
		.form-fields-set {
			max-height: calc(100vh - 250px);
			overflow: scroll;
			margin-left: 1em;
			background-color: #ffffff;
			padding: 1.25em 1em;
			border-radius: 2px;
			-webkit-box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
			box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
			.el-form-item {
				margin-bottom: 0.5em;
			}
		}
	}
</style>

<style lang="scss">
	.configure-company-document {
		background-color: #f8f9fa;

		.vue-data-table-default tbody tr td:last-child,
		.vue-data-table-default tbody tr:hover td:last-child {
			.status-block-buttons {
				.el-button--success {
					color: #fff;
					background-color: #67c23a !important;
					border-color: #67c23a !important;
					&:hover {
						background: #85ce61 !important;
						border-color: #85ce61 !important;
					}
				}
				.el-button--danger {
					color: #fff;
					background-color: #f754a2 !important;
					border-color: #f754a2 !important;
					&:hover {
						background: #f78989 !important;
						border-color: #f78989 !important;
					}
				}
			}
		}
		.form-fields-holder {
			.el-select {
				width: auto !important;
			}
			.el-date-editor.el-input {
				width: auto !important;
			}
		}
		.form-title {
			.el-input {
				position: relative;
				input {
					padding: 0;
					line-height: 1;
					background-color: rgba(255, 255, 255, 0.35);
					border: none;
					font-size: 2.1em;
					height: 60px !important;

					font-weight: 500;
					letter-spacing: -0.5px;
					margin-bottom: 0;
					color: #222222;
					box-shadow: none;
				}
				&::before {
					position: absolute;
					content: "";
					top: 0;
					height: 20px;
					width: 10px;
					//background-image: url(/img/hover-dots.ecfb2fa2.svg);
					background-size: 10px 20px;
					background-repeat: no-repeat;
					background-position: center;
					left: 0px;
					opacity: 0;
					z-index: 10;
					transition: 0.3s all ease-in-out;
					-webkit-transition: 0.3s all ease-in-out;
				}
			}
		}
		.right-block {
			// .el-button {
			//   padding: 1em 1.5em;
			//   border-radius: 5px;
			//   color: #fff;
			//   border: none;
			//   letter-spacing: 1px;
			//   font-size: 14px;
			//   font-weight: 600;
			// }
		}
	}
</style>