

import axios from "@/config/axios";

const initialState = () => {
    return {
        entitiesDataForDataTable: [],
        createDocumentForEntityData: null
    };
};

const state = initialState();

const getters = {
    getEntitiesDataForDataTable: (state) => state.entitiesDataForDataTable,
    getCreateDocumentForEntityData: (state) => state.createDocumentForEntityData
}

const mutations = {

    resetData: state => {
        const initial = initialState();
        Object.keys(initial).forEach((key) => {
            state[key] = initial[key];
        });
    },
    setEntitiesDataForDataTable: (state, data) => {
        state.entitiesDataForDataTable = data;
    },
    setCreateDocumentForEntityData: (state, data) => {
        state.createDocumentForEntityData = data
    }
}



const actions = {
    reset: function (context) {
        context.commit("entities/resetData", null, {
            root: true,
        });
    },
    createDocumentFromEntityData: async ({commit}, params) => {
        try {
            commit("setCreateDocumentForEntityData", null);
            let response = await axios.post("entities-data/document-printing", params);
            commit("setCreateDocumentForEntityData", response.data);

        } catch (err) {
            commit("setCreateDocumentForEntityData", null);
        }
    },
    fetchEntitiesDataForDataTable: async ({ commit }, params) => {
        try {
            commit("setEntitiesDataForDataTable", []);
            let response = await axios.post("entities-data/data-table/row-fields", params);

            commit("setEntitiesDataForDataTable", response.data.data);

        } catch (err) {
            commit("setEntitiesDataForDataTable", []);
        }
    }
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
