<template>
	<section
		class="configure-company-document"
		id="drag-template-background"
		v-loading="documentDataLoading"
	>
		<div class="inner-navbar" v-if="documentFormData">
			<el-row type="flex" align="middle" justify="center">
				<el-col :span="7">
					<el-button round icon="el-icon-back" @click="goToDocuments()"
						>Back to Documents</el-button
					>
				</el-col>
				<el-col :span="10">
					<h2 class="font-weight-normal blue-2">{{ documentFormData.name }}</h2>
				</el-col>
				<el-col :span="6">
					<div class="float-right">
						<el-button class="btn" type="success" @click="saveDocumentData()">
							<i class="el-icon-s-claim"></i> SAVE &amp; UPDATE
						</el-button>
					</div>
				</el-col>
			</el-row>
		</div>

		<div class="input-options">
			<el-row type="flex" justify="center">
				<el-col :span="17">
					<div v-loading="documentDataLoading">
						<div class="form-builder" ref="formBuilder">
							<div class="form-holder">
								<div class="form-image-holder">
									<img
										v-for="(image, index) of backgroundImages"
										:key="index"
										:src="image"
										class="page-image"
									/>
								</div>
								<div class="form-fields-holder" v-loading="loadingElements">
									<div v-for="(item, index) of this.elements" :key="index">
										<img
											v-if="item.type == 'logo'"
											:src="getLogoUrl"
											:style="getStyle(item)"
										/>

										<img
											v-if="item.type == 'my-signature'"
											:src="item.source"
											:style="getStyle(item)"
										/>

										<el-input
											:style="getStyle(item)"
											type="text"
											:placeholder="item.placeholder"
											v-if="item.type == 'SINGLE_LINE_TEXT'"
											v-model="item.value"
											:disabled="checkFieldAllow(item)"
										></el-input>

										<el-input
											:style="getStyle(item)"
											type="text"
											:placeholder="item.placeholder"
											v-if="item.type == 'NUMBER'"
											v-model="item.value"
											:disabled="checkFieldAllow(item)"
										></el-input>

										<el-date-picker
											:height="item.height"
											:width="item.width"
											:style="getStyle(item)"
											type="date"
											:placeholder="item.placeholder"
											v-if="item.type == 'DATE'"
											v-model="item.value"
											:disabled="checkFieldAllow(item)"
										></el-date-picker>

										<el-select
											:height="item.height"
											:width="item.width"
											:style="getStyle(item)"
											v-if="item.type == 'SELECT'"
											v-model="item.value"
											:placeholder="item.placeholder"
											:disabled="checkFieldAllow(item)"
										>
											<el-option
												v-for="(option, index) of item.options"
												:key="index"
												:value="option"
												:label="option"
											></el-option>
										</el-select>

										<el-select
											:height="item.height"
											:width="item.width"
											:style="getStyle(item)"
											v-if="item.type == 'MULTI_SELECT'"
											v-model="item.value"
											:placeholder="item.placeholder"
											:disabled="checkFieldAllow(item)"
											multiple
										>
											<el-option
												v-for="(option, index) of item.options"
												:key="index"
												:value="option"
												:label="option"
											></el-option>
										</el-select>

										<input
											type="checkbox"
											:height="item.height"
											:width="item.width"
											:style="getStyle(item)"
											v-if="item.type == 'CHECKBOX'"
											v-model="item.value"
											:disabled="checkFieldAllow(item)"
										/>
										<el-input
											type="textarea"
											:rows="item.rows"
											:style="getStyle(item)"
											v-if="item.type == 'MULTI_LINE_TEXT'"
											v-model="item.value"
											:placeholder="item.placeholder"
											:disabled="checkFieldAllow(item)"
										/>

										<span
											:style="getStyle(item)"
											v-if="item.type == 'MULTI_LINE_TEXT'"
											>{{ item.value }}</span
										>

										<a
											v-if="item.type == 'SIGNATURE' && !item.source"
											:style="getStyle(item)"
											@click="captureSignature(item, index)"
											:disabled="checkFieldAllow(item)"
										>
											{{ item.label }}
											<i class="el-icon-edit"></i>
										</a>
										<img
											v-if="item.type == 'SIGNATURE' && item.source"
											:src="item.source"
											:style="getStyle(item)"
											@click="captureSignature(item, index)"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="form-fields-set">
						<el-row>
							<h3 class="font-weight-normal text-center blue mb-05">
								Document Details
							</h3>
							<el-form v-model="userDocumentFormData">
								<el-form-item label="Document Valid From">
									<el-date-picker
										type="date"
										v-model="userDocumentFormData.valid_from"
									></el-date-picker>
								</el-form-item>

								<el-form-item label="Document Expires">
									<el-date-picker
										type="date"
										v-model="userDocumentFormData.expires_at"
									></el-date-picker>
								</el-form-item>

								<el-divider class="mb-05"></el-divider>

								<el-form-item label="E-signature Required ?" class="mb-0">
									<el-radio-group
										v-model="userDocumentFormData.e_signature_value"
										@change="updateEsignValue"
									>
										<el-radio :label="'YES'">Yes</el-radio>
										<el-radio :label="'NO'">No</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-form>

							<div
								class="signature-box mb-0"
								v-if="
									documentSentStatus &&
									userDocumentFormData.e_signature_required
								"
							>
								<el-card shadow="never" class="mt-1 bg-light">
									<div
										class="float-right"
										v-if="userDocumentFormData.e_signature_status"
									>
										<div class="success">
											<i class="el-icon-finished"></i> Completed
										</div>
									</div>
									<div
										class="float-right"
										v-if="!userDocumentFormData.e_signature_status"
									>
										<div class="mb-05 error">Pending</div>
									</div>

									<h4 class="font-weight-normal">E-Signature</h4>
									<el-divider class="mt-05 mb-05"></el-divider>
									<div
										class="text-center"
										v-if="userDocumentFormData.e_signature_status"
									>
										<el-button
											class="btn-link p-0"
											type="text"
											@click="onESignView"
											>View Signature</el-button
										>
									</div>
								</el-card>
							</div>
						</el-row>
					</div>
				</el-col>
			</el-row>
		</div>

		<el-dialog :visible.sync="showSavedSignatureData" title="eSignature">
			<div v-loading="esignLoading">
				<el-row>
					<el-col :span="24" v-if="esignData">
						<ESignatureDataView :esignData="esignData"></ESignatureDataView>
					</el-col>
				</el-row>
			</div>
		</el-dialog>
	</section>
</template>

<script>
	import appConfig from "@/config/app";
	import { mapGetters } from "vuex";
	import ESignatureDataView from "@/components/signature/ESignatureDataView";

	export default {
		name:"previewCompanyDocuments-CustomDocumentPreview",
		components: {
			ESignatureDataView,
		},

		created() {},
		data() {
			return {
				elements: [],
				width: 0,
				height: 0,
				x: 0,
				y: 0,
				addItemDataDialogVisible: false,
				selectedItemIndex: null,
				selectedItem: {},
				refreshForm: false,
				selectedSignatureItem: {},
				selectedSignatureItemIndex: -1,
				captureSignatureDialog: false,
				hasBackground: false,
				documentId: null,
				configurableDocumentId: null,
				documentDataLoading: false,
				documentFormData: null,
				documentName: "",
				loading: false,
				backgroundImage: "",
				previewVisible: false,
				activatedItem: null,
				activatedItemIndex: -1,

				currentFormBuilderWidth: null,
				storedFormBuilderWidth: null,
				checkExistingData: false,
				backgroundImages: [],
				loadingElements: false,
				selectedTemplateFields: [],
				templatesUsed: [],
				showErrorElementDialog: false,
				errorElements: [],
				fieldErrorMessage: "",
				workflowDataId: "",
				userId: "",

				userDocumentFormData: {
					valid_from: "",
					expires_at: "",
					e_signature_required: "",
					e_signature_value: "",
				},
				employeeDocumentId: "",
				templateDataIdsData: [],
				workflowStepId: "",

				showSavedSignatureData: false,
				esignLoading: false,
				esignData: {},
				documentSentStatus: false,
			};
		},
		computed: {
			...mapGetters("company", ["getCompanyProfileData"]),
			...mapGetters("employee", ["getSingleEmployeeData"]),
			...mapGetters("configurableDocuments", ["getConfigurableDocumentDataById"]),
			...mapGetters("employeeData", ["getUserTemplatesData", "getESignData"]),

			...mapGetters("templatesData", ["getTemplatesData"]),
			...mapGetters("workflowData", [
				"getWorkflowDataAssetDataAddStatus",
				"getUserWorkflowData",
			]),
			...mapGetters("companyDocuments", ["getCompanyDocumentDataById"]),
			...mapGetters("employeeDocuments", [
				"getEmployeeDocumentDataById",
				"getEmployeeDocumentUpdateStatus",
			]),

			getLogoUrl() {
				return appConfig.S3_BUCKET_URL + "/" + this.getCompanyProfileData.logo;
			},
		},
		async mounted() {
			this.currentFormBuilderWidth = this.$refs.formBuilder.clientWidth;

			this.fetchCompanyDetails();
			this.setData();
			await this.fetchDocumentData();
			await this.fetchWorkflowData();

			if (this.employeeDocumentId) {
				await this.fetchEmployeDocumentData();
			}
			if (this.templatesUsed.length) {
				await this.fetchUserTemplateData();
			}
			this.setEmployeDataToForm();
		},
		methods: {
			calculateRelativeLeft(x) {
				if (
					this.storedFormBuilderWidth &&
					this.currentFormBuilderWidth &&
					this.currentFormBuilderWidth != this.storedFormBuilderWidth
				) {
					return Math.round(
						(this.currentFormBuilderWidth / this.storedFormBuilderWidth) * x
					);
				}
				return x;
			},

			calculateRelativeTop(y) {
				if (
					this.storedFormBuilderWidth &&
					this.currentFormBuilderWidth &&
					this.currentFormBuilderWidth != this.storedFormBuilderWidth
				) {
					return Math.round(
						(this.currentFormBuilderWidth / this.storedFormBuilderWidth) * y
					);
				}
				return y;
			},
			calculateRelativeWidth(width) {
				if (
					this.storedFormBuilderWidth &&
					this.currentFormBuilderWidth &&
					this.currentFormBuilderWidth != this.storedFormBuilderWidth
				) {
					return Math.round(
						(this.currentFormBuilderWidth / this.storedFormBuilderWidth) * width
					);
				}
				return width;
			},
			setData() {
				this.employeeId = this.$route.params.employee_id;
				this.configurableDocumentId = this.$route.params.configurable_document_id;
				this.documentId = this.$route.params.document_id;
				this.employeeDocumentId = this.$route.query.employee_document_id;
				this.workflowStepId = this.$route.query.workflow_step_id;
			},

			setEmployeDataToForm() {
				this.documentDataLoading = true;

				for (let index = 0; index < this.elements.length; index++) {
					let field = this.elements[index];
					if (field.field_type == "TEMPLATE_FIELD") {
						const fieldData = this.getFieldDataFromTemplate(
							field.template_id,
							field.template_field_id
						);
						if (fieldData) {
							this.$set(this.elements[index], "label", fieldData.label);
							this.$set(this.elements[index], "filled_by", fieldData.filled_by);
							this.$set(this.elements[index], "options", fieldData.options);
							this.$set(
								this.elements[index],
								"placeholder",
								fieldData.placeholder
							);
							this.$set(this.elements[index], "source", fieldData.source);
							this.$set(this.elements[index], "source", fieldData.source);
							this.$set(this.elements[index], "required", fieldData.required);
						}

						if (this.workflowDataId) {
							const value = this.getValueFromTempaleFieldFromEmployeeData(field);

							const documentValue = this.getValueFromSavedDocument(field.key);

							if (documentValue) {
								this.$set(this.elements[index], "value", documentValue);
							} else if (value) {
								this.$set(this.elements[index], "value", value);
							}
						}
					} else {
						const documentValue = this.getValueFromSavedDocument(field.key);
						this.$set(this.elements[index], "value", documentValue);
					}
				}

				if (this.employeeDocumentId && this.getEmployeeDocumentDataById) {
					this.userDocumentFormData.e_signature_required = this.getEmployeeDocumentDataById.e_signature_required;
					this.userDocumentFormData.e_signature_status = this.getEmployeeDocumentDataById.e_signature_status;
					this.userDocumentFormData.expires_at = this.getEmployeeDocumentDataById.expires_at;
					this.userDocumentFormData.valid_from = this.getEmployeeDocumentDataById.valid_from;
					this.userDocumentFormData.e_signature_value = this
						.getEmployeeDocumentDataById.e_signature_required
						? "YES"
						: "NO";
				}

				this.documentDataLoading = false;
			},

			async fetchWorkflowData() {
				this.workflowDataId = this.$route.params.workflow_data_id;
				if (this.workflowDataId) {
					this.userId =
						this.getUserWorkflowData && this.getUserWorkflowData.user_id
							? this.getUserWorkflowData.user_id
							: null;

					this.templateDataIdsData = this.getUserWorkflowData.template_data_ids;

					let steps = this.getUserWorkflowData.workflow_steps_data || [];

					this.documentSentStatus = steps.find(
						(x) => x.step_id == this.workflowStepId && x.sent_status
					)
						? true
						: false;
				}
			},

			async fetchUserTemplateData() {
				let usedTemplateIds = this.templatesUsed.map((e) => e._id);

				let dataTemplates = this.templateDataIdsData.filter(
					(e) => usedTemplateIds.indexOf(e.template_id) > -1
				);

				let templateDataIds = dataTemplates.map(
					(value) => value.template_data_id
				);

				let params = {
					template_data_ids: templateDataIds,
				};
				await this.$store.dispatch("templatesData/fetchUserTemplateData", params);
			},
			getFieldDataFromTemplate(templateId, templateFieldId) {
				let template = this.templatesUsed.find((x) => x._id == templateId);
				if (template) {
					let field = template.sections[0].fields.find(
						(x) => x._id == templateFieldId
					);
					if (field) {
						return field;
					}
					return {};
				}
				return {};
			},
			getValueFromTempaleFieldFromEmployeeData(field) {
				let key = field.key.split("#")[1];

				if (this.getTemplatesData && this.getTemplatesData.length) {
					let template = this.getTemplatesData.find(
						(x) => x.template_id == field.template_id
					);
					if (template) {
						return template.template_data[key] ? template.template_data[key] : "";
					}
					return "";
				}
				return "";
			},

			getValueFromSavedDocument(key) {
				if (
					this.getEmployeeDocumentDataById &&
					this.getEmployeeDocumentDataById.document_data
				) {
					return this.getEmployeeDocumentDataById.document_data[key];
				}
				return null;
			},
			async fetchCompanyDetails() {
				await this.$store.dispatch("company/getCompany");
			},
			async fetchEmployeeData() {
				this.empDataLoading = true;
				await this.$store.dispatch("employee/fetchEmployeeSingleEmployee", {
					id: this.employeeId,
				});
				this.empDataLoading = false;
			},

			async fetchEmployeDocumentData() {
				await this.$store.dispatch(
					"employeeDocuments/fetchEmployeeDocumentById",
					{
						id: this.employeeDocumentId,
					}
				);
			},

			async fetchDocumentData() {
				this.documentDataLoading = true;
				await this.$store.dispatch(
					"configurableDocuments/fetchConfigurableDocumentById",
					{
						id: this.configurableDocumentId,
						include_template_details: true,
					}
				);
				await this.$store.dispatch("companyDocuments/fetchCompanyDocumentById", {
					id: this.documentId,
				});

				this.userDocumentFormData.valid_from = this.getCompanyDocumentDataById.valid_from;
				this.userDocumentFormData.expires_at = this.getCompanyDocumentDataById.valid_to;
				this.userDocumentFormData.e_signature_required = this.getCompanyDocumentDataById.e_signature_required;

				this.documentFormData = JSON.parse(
					JSON.stringify(this.getConfigurableDocumentDataById)
				);

				this.backgroundImage = this.documentFormData.pages[0].background_image;
				this.backgroundImages = this.documentFormData.pages[0].background_images;

				this.storedFormBuilderWidth = this.documentFormData.pages[0].page_base_width;
				this.templatesUsed = this.documentFormData.pages[0].templates_used;

				this.loadingElements = true;
				setTimeout(() => {
					this.elements = this.documentFormData.pages[0].fields;
					this.elements = this.setSizeAndPositionForElements();
					this.loadingElements = false;
				}, this.backgroundImages * 500);

				this.documentDataLoading = false;
			},

			setSizeAndPositionForElements() {
				return this.elements.map((field) => {
					field.x = this.calculateRelativeLeft(field.x);
					field.y = this.calculateRelativeTop(field.y);
					field.width = this.calculateRelativeWidth(field.width);
					field.height = this.calculateRelativeWidth(field.height);
					return field;
				});
			},
			getFormStyle(page) {
				if (page.background) {
					let style = page.style + `background:url('${page.background_image}');`;
					return style;
				} else {
					return page.style || "";
				}
			},
			getStyle(item) {
				if (item.type == "SIGNATURE") {
					return (
						`position:absolute;left:${item.x}px;top:${item.y}px;cursor:pointer;background:#c2d8de;` +
						(item.source ? `height:50px;` : ``)
					);
				} else if (item.type == "my-signature") {
					return `position:absolute;left:${item.x}px;top:${item.y}px;height:50px;cursor:pointer;`;
				} else if (item.type == "MULTI_LINE_TEXT") {
					return `position:absolute;left:${item.x}px;top:${item.y}px;height:auto;width:${item.width}px;`;
				}
				return `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;`;
			},
			checkFieldAllow(item) {
				return item.filled_by === "RECEIVER";
			},
			goToEmployesPage() {
				this.$router.push({ path: "/employee-list" });
			},

			getData() {
				let data = {};

				for (let index = 0; index < this.elements.length; index++) {
					let field = this.elements[index];
					let key = field.key;
					data[key] = this.elements[index].value || this.elements[index].source;
					if (field.type == "SIGNATURE") {
						this.signatureFields.push(field);
					}
				}
				return data;
			},

			goToDocuments() {
				this.$router.go(-1);
			},
			setColor(selectedSignatureItem, color) {
				this.$set(selectedSignatureItem, "color", color);
			},
			captureSignature(item, index, pageIndex) {
				if (this.checkFieldAllow(item)) {
					return;
				}
				this.selectedSignatureItem = item;
				this.selectedSignatureItemIndex = index;
				this.pageIndex = pageIndex;
				this.captureSignatureDialog = true;
			},
			saveCapturedSignature() {
				const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
				if (!isEmpty) {
					this.selectedSignatureItem.source = data;
					this.elements[
						this.selectedSignatureItemIndex
					] = this.selectedSignatureItem;
				}
				this.$refs.signaturePad.clearSignature();
				this.selectedSignatureItem = {};
				this.selectedSignatureItemIndex = -1;
				this.captureSignatureDialog = false;
			},
			undo() {
				this.$refs.signaturePad.undoSignature();
			},
			backToEmployees() {
				this.$router.push({
					path: "employee-list",
				});
			},

			saveDocumentData() {
				try {
					if (this.employeeDocumentId) {
						this.updateEmployeeDocument();
					} else {
						this.addEmployeeDocument();
					}
				} catch (err) {
					console.log(err);
				}
			},

			async updateEmployeeDocument() {
				try {
					let documentData = this.prepareDocumentData();
					let params = {
						id: this.employeeDocumentId,
						document_data: documentData,
						valid_from: this.userDocumentFormData.valid_from,
						expires_at: this.userDocumentFormData.expires_at,
						e_signature_required:
							this.userDocumentFormData.e_signature_value == "YES" ? true : false,
					};

					await this.$store.dispatch(
						"employeeDocuments/updateEmployeeDocument",
						params
					);

					if (this.getEmployeeDocumentUpdateStatus) {
						this.$notify.success({
							title: "Success",
							message: "Document Data Saved Successfully",
						});
						this.goToDocuments();
					} else {
						this.$notify.error({
							title: "Error",
							message: "Erorr at updating document",
						});
					}
				} catch (err) {
					console.log(err);
				}
			},

			async addEmployeeDocument() {
				try {
					let documentData = this.prepareDocumentData();
					let companayDocumentData = {
						configurable_document_id: this.configurableDocumentId,
						company_document_id: this.documentId,
						document_data: documentData,
						document_type: "CUSTOM",
						valid_from: this.userDocumentFormData.valid_from,
						expires_at: this.userDocumentFormData.expires_at,
						e_signature_required:
							this.userDocumentFormData.e_signature_value == "YES" ? true : false,
					};
					let params = {
						workflow_data_id: this.workflowDataId,
						workflow_step_id: this.workflowStepId,

						asset: "COMPANY_DOCUMENTS",
						company_document_data: companayDocumentData,
					};
					await this.$store.dispatch(
						"workflowData/addAssetDataToWorkFlowData",
						params
					);
					if (this.getWorkflowDataAssetDataAddStatus) {
						this.$notify.success({
							title: "Success",
							message: "Document Data Saved Successfully",
						});
						this.goToDocuments();
					} else {
						this.$notify.error({
							title: "Error",
							message: "Erorr at updating document",
						});
					}
				} catch (err) {
					this.$notify.error({
						title: "Error",
						message: "Erorr at updating document",
					});
				}
			},

			prepareDocumentData() {
				let data = {};
				this.elements.forEach((element) => {
					if (element.value) {
						data[element.key] = element.value;
					} else if (element.source) {
						data[element.key] = element.source;
					} else {
						data[element.key] = "";
					}
				});
				return data;
			},

			async onESignView() {
				try {
					this.showSavedSignatureData = true;
					this.esignLoading = true;
					let params = {
						id: this.getEmployeeDocumentDataById.e_signature,
					};
					await this.$store.dispatch("employeeData/fetchESignData", params);
					this.esignLoading = false;
					this.esignData = this.getESignData;
				} catch (err) {
					console.log(err);
				}
			},
			updateEsignValue(value) {
				this.userDocumentFormData.e_signature_required = value === "YES";
			},
		},
		beforeDestroy() {
			this.$store.commit("company/setCompanyProfileData",null,{root:true})
			this.$store.commit("employee/setSingleEmployeeData",null,{root:true})
			this.$store.commit("employeeData/setESignData",null,{root:true})
			this.$store.commit("workflowData/setUserWorkflowData",null,{root:true})
			this.$store.commit("workflowData/setWorkflowDataAssetDataAddStatus",null,{root:true})
			this.$store.commit("companyDocuments/setCompanyDocumentDataById",null,{root:true})
			this.$store.commit("employeeDocuments/setEmployeeDocumentUpdateStatus",null,{root:true})
			this.$store.commit("employeeDocuments/setEmployeeDocumentDataById",null,{root:true})
		}
	};
</script>

<style lang="scss" scoped>
	.form-builder {
		width: 950px;
		max-height: 900px;
		overflow-y: auto;
		background-color: #ffffff;
		border: 1px solid #efefef;
		-webkit-box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
		box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
		border-radius: 10px;
		.form-image-holder {
			width: 100%;
			img {
				width: 100%;
			}
		}

		.form-fields-holder {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			.each-element img {
				width: 100%;
			}
		}
	}
	#drag-template-background {
		.img-fluid {
			height: auto !important;
		}
		.inner-navbar {
			margin-bottom: 1.5em;
			.left-block {
				.el-input__inner {
					min-width: 250px;
					max-width: 300px;
					background: #fff;
					height: 47px;
				}
			}
		}
		.input-options {
			td {
				padding-top: 0;
				vertical-align: top;
				&:nth-child(1) {
					width: 20%;
				}
				&:nth-child(2) {
					width: 80%;
				}
			}
			.inputs-list {
				background: #fff;
				padding: 15px;
				box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.06),
					0 1px 0px rgba(0, 0, 0, 0.08);
				border-radius: 5px;
				.el-button {
					border: none;
					background: rgba(236, 245, 255, 0.7);
					&:hover {
						background: rgba(236, 245, 255, 1);
					}
				}
				li {
					&:not(:last-child) {
						margin-bottom: 15px;
					}
				}
			}
		}
		.form-image {
			max-width: 770px;
			min-height: 1000px;
			background-color: #e6dcdc;
		}
		.el-input__inner {
			display: inline !important;
		}

		.form-holder {
			width: 100%;
			position: relative;
			.el-input__inner,
			.el-textarea__inner {
				height: 100% !important;
				min-height: 30px !important;
				line-height: inherit !important;
			}
			input[type="checkbox"] {
				margin: 0 !important;
			}

			// .draggable.resizable {
			//   position: absolute;
			//   z-index: 100;
			// }
		}

		.sample-form1 {
			position: relative;
			width: 100%;
			max-height: 91vh;
			overflow: scroll;
			border: 1px solid #ccc;
			margin-left: 20px;
			margin-bottom: 45px;

			///
			.each-page {
				position: relative;
				width: 900px;
				display: block;
				height: 800px;
				overflow-y: auto;
				.form-image {
					height: 800px;
					position: absolute;
					width: 850px;
					top: 0;
					left: 0;
				}
				.form-fields {
					height: 800px;
					position: absolute;
					width: 850px;
					top: 0;
					left: 0;
					z-index: 100;
				}
				.page-image {
					width: 850px;
					margin: 0 auto;
				}
			}

			// .draggable.resizable {
			//   position: absolute;
			//   z-index: 100;
			// }
		}

		.color-btn {
			height: 20px;
			width: 20px;
			border-radius: 50%;
			margin: 10px;
			&.red {
				background-color: red;
			}
			&.black {
				background-color: black;
			}

			&.green {
				background-color: green;
			}
		}

		// Fields
		.form-fields-set {
			background-color: #ffffff;
			padding: 1.25em 1em;
			border-radius: 2px;
			-webkit-box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
			box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
			.el-form-item {
				margin-bottom: 0.5em;
			}
		}
	}
</style>
